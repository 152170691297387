import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setKeywords, setLis } from "./actions";
import { LanguageContext } from "./LanguageContext";
import LanguageSwitcher from "./LanguageSwitcher";
import LanguageSwitcher2 from "./LanguageSwitcher2";
import { IoIosArrowBack } from "react-icons/io";

const PsychologyStudy = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { translations, language } = useContext(LanguageContext);

  const handleNextClick2 = (id, name) => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${apiUrl}/psychology/managerFilter?tagid=${id}&limit=1000&page=1`
        );
        const fetchedLis = await response.json();
        if (response.ok) {
          console.log(
            `${apiUrl}/psychology/managerFilter?tagid=${id}&limit=1&page=1`
          ); // Store fetched data in state
          console.log(fetchedLis.data); // Store fetched data in state
          dispatch(setKeywords(name));
          dispatch(setLis(fetchedLis.data));
          navigate("/psychologystudylist");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  };

  const handleGoBack = () => {
    navigate("/study");
  };
  const handleSubmit = (keyword) => {
    navigate("/psychologystudylist", {
      state: {
        keyword,
      },
    });
  };

  const [selectedGenders, setSelectedGenders] = useState([]);
  const [data, setData] = useState([]);
  const [topics, setTopics] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiUrl}/tree-nodes/3`);
        const fetchedData = await response.json();
        setData(fetchedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [apiUrl]);

  useEffect(() => {
    if (data && data.children) {
      const topics = data.children.flatMap((child) => {
        const names = child.translations
          ? child.translations
              .filter((translation) => translation.language === language)
              .map((translation) => ({
                id: child.id,
                name: translation.name,
              }))
          : [];
        return names;
      });

      setTopics(topics);
    }
  }, [data]);

  const handleSelect = (id) => {
    setSelectedGenders((prevSelectedGenders) => {
      if (prevSelectedGenders.includes(id)) {
        return prevSelectedGenders.filter((item) => item !== id);
      } else {
        return [...prevSelectedGenders, id];
      }
    });
  };

  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("theme") === "dark"
  );

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add("dark");
      localStorage.setItem("theme", "dark");
    } else {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("theme", "light");
    }
  }, [darkMode]);

  return (
    <div className="flex min-h-screen justify-center w-full bg-[#faf8f6] dark:bg-[#121418]">
      <div className="relative w-full max-w-[100%] bg-[#faf8f6] dark:bg-[#121418]">
        <header className="flex items-center justify-between py-8 px-4">
          <div className="flex items-center">
            {/* <img
              width="8"
              height="16"
              src="back.png"
              alt="Back"
              onClick={handleGoBack}
            /> */}
            <IoIosArrowBack
              color={darkMode ? "white" : "black"}
              size={30}
              onClick={handleGoBack}
            />
          </div>
          <h1 className="absolute left-1/2 transform -translate-x-1/2 text-[16px] font-sans font-bold text-[#000] dark:text-[#fff] text-center">
            Psychology
          </h1>
          {darkMode ? (
            <LanguageSwitcher2></LanguageSwitcher2>
          ) : (
            <LanguageSwitcher></LanguageSwitcher>
          )}
        </header>

        <main className="flex flex-col items-center gap-4 px-4 mt-6">
          <section className="w-full">
            <div className="flex flex-col items-start gap-[12px]">
              {topics.map((topic) => (
                <div
                  key={topic.id}
                  className={`self-stretch h-[54px] flex items-center justify-center py-[10px] px-[20px] bg-[#fff] dark:bg-[#22232a] shadow-lg rounded-[12px] cursor-pointer ${
                    selectedGenders.includes(topic.id)
                      ? "border-[#3677f6] text-[#3677f6]"
                      : "border-[#e9ecef] text-[#000]"
                  }`}
                  onClick={() => {
                    handleSelect(topic.id);
                    handleNextClick2(topic.id, topic.name);
                    console.log(topic.id);
                    console.log(topic.name);
                  }}
                >
                  <div className="text-[14px] leading-[100%] font-sans font-medium text-[#000] dark:text-[#fff] whitespace-nowrap">
                    {topic.name}
                  </div>
                </div>
              ))}
            </div>
          </section>
        </main>
      </div>
    </div>
  );
};

export default PsychologyStudy;
