import React, { useState, useEffect } from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import Navigation from '../../components/admin/Navigation';
import API_BASE_URL from '../../config';
import axios from 'axios';

ChartJS.register(ArcElement, Tooltip, Legend);

const ExpressionOverview = () => {
  const [expressionData, setExpressionData] = useState(null);
  const [selectKorEng, setSelectKorEng] = useState(null);

  useEffect(() => {
    // select_kor_eng.json 로드
    fetch('/select_kor_eng.json')
      .then(response => response.json())
      .then(data => setSelectKorEng(data))
      .catch(error => console.error('select_kor_eng.json을 불러오는 중 오류 발생:', error));

    // API 데이터 로드
    fetchExpressionData();
  }, []);


  const fetchExpressionData = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/expressions/counts`);
      setExpressionData(response.data);
    } catch (error) {
      console.error('표현하기 데이터를 불러오는 중 오류 발생:', error);
    }
  };

  // 데이터 변환 함수
  const transformData = (apiData) => {
    if (!apiData || !selectKorEng) return {};
    
    const expressionMapping = selectKorEng.expression;
    const transformedData = {};

    // apiData가 빈 객체인 경우 처리
    if (Object.keys(apiData).length === 0) {
      console.log("API 데이터가 비어 있습니다.");
      return {};
    }
    
    apiData.forEach(item => {
      const koreanKey = Object.keys(expressionMapping).find(key => expressionMapping[key] === item.category);
      if (koreanKey) {
        transformedData[koreanKey] = parseInt(item.count, 10);
      }
    });
    
    // 매핑되지 않은 카테고리 처리
    const unmappedCount = apiData.reduce((sum, item) => {
      if (!Object.values(expressionMapping).includes(item.category)) {
        return sum + parseInt(item.count, 10);
      }
      return sum;
    }, 0);
    
    if (unmappedCount > 0) {
      transformedData['기타'] = unmappedCount;
    }
    
    return transformedData;
  };

  const pieChartData = {
    labels: Object.keys(transformData(expressionData || {})),
    datasets: [{
      data: Object.values(transformData(expressionData || {})),
      backgroundColor: [
        '#3677F6', '#61E3EB', '#383C4B', '#B7FFC3', '#FF99BE',
        '#FFEE93', '#F67239', '#7DECC8', '#9747FF', '#FFA629'
      ]
    }]
  };

  if (!expressionData || !selectKorEng) return <div>로딩 중...</div>;

  const transformedData = transformData(expressionData);

  return (
    <div className='min-h-screen' style={{ backgroundColor: '#FAF8F6' }}>
      <Navigation />
      <div style={{ padding: '26px 30px' }}>
        <h1 className="font-bold uppercase" style={{ width: '140px', height: '28px', fontFamily: 'Pretendard', fontSize: '24px', fontWeight: 700, textAlign: 'left' }}>
          OVERVIEW
        </h1>

        <div className="mt-6">
          <div className="flex gap-4 h-[calc(100vh-150px)]">
            {/* 파이 차트 섹션 */}
            <div className="w-3/4 bg-white p-5 rounded-lg shadow-md flex flex-col">
              <h2 className="text-lg font-bold mb-4">표현하기 표현언어 작업 현황</h2>
              <div className="flex-grow flex items-center justify-center">
                <div style={{ width: '80%', height: '80%' }}>
                  <Pie 
                    data={pieChartData} 
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      plugins: {
                        legend: {
                          display: false
                        }
                      }
                    }}
                  />
                </div>
              </div>
            </div>

            {/* 표현언어 작업 현황 건수 섹션 */}
            <div className="w-1/4 bg-white p-5 rounded-lg shadow-md flex flex-col">
              <h2 className="text-lg font-bold mb-4">표현하기 표현언어 작업 현황</h2>
              <div className="flex-grow overflow-y-auto">
                <div className="grid grid-cols-1 gap-2">
                  {Object.entries(transformedData).map(([key, value], index) => (
                    <div key={key} className="flex items-center justify-between bg-white border border-[#E2E8EF] rounded-lg p-2">
                      <div className="flex items-center">
                        <div className="w-3 h-3 rounded-[4px] mr-2" style={{ backgroundColor: pieChartData.datasets[0].backgroundColor[index] }}></div>
                        <span className="font-['Pretendard_Variable'] text-[14px] font-bold">{key}</span>
                      </div>
                      <div>
                        <span className="font-['Pretendard'] text-[18px] font-bold">{value.toLocaleString()}</span>
                        <span className="font-['Pretendard'] text-[14px] font-medium ml-1">건</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpressionOverview;