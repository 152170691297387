import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import GnbMyPage from "./components/GnbMyPage";
import MyPageNotice from "./MyPageNotice";
import { LanguageContext } from "./LanguageContext";
import LanguageSwitcher from "./LanguageSwitcher";
import LanguageSwitcher2 from "./LanguageSwitcher2";
import UseSwipe from "./UseSwipe"; // Adjust the path as necessary
import Modal2 from "./Modal2";
import { IoIosArrowForward } from "react-icons/io";
import { useSelector } from "react-redux";

const MyPage = () => {
  const userInfo = useSelector((state) => state.userInfo);
  const { translations } = useContext(LanguageContext);
  const navigate = useNavigate();
  // Handler functions for navigation
  const [notification, setNotification] = useState(false);
  const goAccountInfo = () => {
    navigate("/accountinfo");
  };
  const goPurchase = () => {
    navigate("/purchase");
  };
  const goNotice = () => {
    navigate("/mypagenotice");
  };
  const goFaq = () => {
    navigate("/mypagefaq");
  };
  const goAppInfo = () => {
    navigate("/appinfo");
  };

  const handleSwipeLeft = () => {};

  const handleSwipeRight = () => {
    navigate("/ai");
  };

  UseSwipe(handleSwipeLeft, handleSwipeRight);

  const [isModalVisible2, setisModalVisible2] = useState(false);

  const handleOpenModal = () => {
    setisModalVisible2(true);
  };

  const handleCloseModal = () => {
    setisModalVisible2(false);
  };

  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("theme") === "dark"
  );

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add("dark");
      localStorage.setItem("theme", "dark");
    } else {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("theme", "light");
    }
  }, [darkMode]);

  return (
    <div
      id="swipe-container"
      className="relative w-full h-[100vh] bg-[#faf8f6] dark:bg-[#121418] overflow-y-auto flex flex-col"
    >
      {/* Navigation Bar */}
      <GnbMyPage></GnbMyPage>

      {/* Main Content */}
      <div className="mt-[3vh] pb-[80px] px-[24px] flex-grow">
        {/* Header */}

        {/* Service Settings */}
        <div className="top-[2vh]"></div>
        <div className="ml-[92%] flex flex-row items-center">
          {darkMode ? (
            <LanguageSwitcher2></LanguageSwitcher2>
          ) : (
            <LanguageSwitcher></LanguageSwitcher>
          )}
        </div>
        <div>
          <div
            className="text-[18px] leading-[100%] font-sans font-bold text-[#000] dark:text-[#fff] whitespace-nowrap mt-[4vh]"
            onClick={goAccountInfo}
          >
            {userInfo.nickname}
            <div className="text-[14px] leading-[100%] font-sans font-medium text-[#000] dark:text-[#fff] whitespace-nowrap inline-block">
              {translations.mp0}
            </div>
            <IoIosArrowForward
              size={30}
              className="ml-auto align-middle -mt-[18px]"
              color={darkMode ? "white" : ""}
            ></IoIosArrowForward>
          </div>
          {/* membership */}
          {false ? (
            <div className="w-[100%] flex flex-col items-start justify-center gap-[8px] py-[12px] px-[20px] bg-[linear-gradient(64.95deg,#61e3eb_0%,#3677f6_100%)] rounded-[12px] mt-[2vh]">
              <div className="text-[14px] leading-[100%] font-sans font-bold text-[#fff] whitespace-nowrap">
                Membership
              </div>
              <div className="text-[12px] leading-[100%] font-sans font-medium text-[#fff] whitespace-nowrap">
                2024.05.24 - 2025.05.23
              </div>
            </div>
          ) : (
            <div
              className="w-[100%] h-[56px] flex flex-col items-start justify-center gap-[8px] py-[12px] px-[20px] bg-[#fff] dark:bg-[#383c4b] rounded-[12px] mt-[2vh] shadow-lg"
              onClick={goPurchase}
            >
              <div className="flex justify-between w-full items-center">
                <div className="text-[14px] leading-[100%] font-sans font-bold text-[#25272f] dark:text-[#fff] whitespace-nowrap">
                  {translations.mp1}
                </div>
                <IoIosArrowForward
                  className=""
                  size={30}
                  color={darkMode ? "white" : ""}
                ></IoIosArrowForward>
              </div>
            </div>
          )}
        </div>
        <div className="text-[16px] leading-[100%] font-sans font-bold text-[#25272f] dark:text-[#fff] whitespace-nowrap mt-[3vh]">
          {translations.mp2}
        </div>
        <div className="mt-[6px]">
          <div className="flex flex-row items-center justify-between py-[20px] border-b border-[#d5dce4] dark:border-[#666b81]">
            <div className="text-[14px] leading-[100%] font-sans font-medium text-[#666b81] whitespace-nowrap">
              {translations.mp3}
            </div>
            <div
              className="flex flex-row items-center"
              onClick={() => setDarkMode(!darkMode)}
            >
              <div className="text-[14px] leading-[100%] font-sans font-medium text-[#666b81] dark:text-[#3677f6] whitespace-nowrap">
                {translations.dark}
              </div>
              <div className="w-[4px] h-[4px] bg-[#666b81] rounded-full mx-[6px]"></div>
              <div className="text-[14px] leading-[100%] font-sans font-medium text-[#3677f6] dark:text-[#666b81] whitespace-nowrap">
                {translations.light}
              </div>
            </div>
          </div>
          <div
            className="flex flex-row items-center justify-between py-[20px] border-b border-[#d5dce4] dark:border-[#666b81]"
            // onClick={handleOpenModal}
          >
            <div className="text-[14px] leading-[100%] font-sans font-medium text-[#666b81] whitespace-nowrap">
              {translations.mp4}
            </div>
            <div className="flex flex-row items-center">
              <div
                className={`text-[14px] leading-[100%] font-sans font-medium whitespace-nowrap ${
                  notification ? "text-[#3677f6]" : "text-[#666b81]"
                }`}
                onClick={() => {
                  setNotification(true);
                }}
              >
                ON
              </div>
              <div className="w-[4px] h-[4px] bg-[#666b81] rounded-full mx-[6px]"></div>
              <div
                className={`text-[14px] leading-[100%] font-sans font-medium  whitespace-nowrap ${
                  notification ? "text-[#666b81]" : "text-[#3677f6]"
                }`}
                onClick={() => {
                  setNotification(false);
                }}
              >
                OFF
              </div>
            </div>
          </div>
          <div
            className="flex flex-row items-center justify-between py-[20px] border-b border-[#d5dce4] dark:border-[#666b81]"
            onClick={handleOpenModal}
          >
            <div className="text-[14px] leading-[100%] font-sans font-medium text-[#666b81] whitespace-nowrap">
              {translations.mp5}
            </div>
            <IoIosArrowForward
              className=""
              size={24}
              color={darkMode ? "white" : ""}
            ></IoIosArrowForward>
          </div>
        </div>

        {/* Customer Service */}
        <div className="mt-[20px]">
          <div className="text-[16px] leading-[100%] font-sans font-bold text-[#25272f] dark:text-[#fff] whitespace-nowrap">
            {translations.mp6}
          </div>
          <div className="mt-[6px]">
            <div
              className="flex flex-row items-center justify-start py-[20px] border-b border-[#d5dce4] dark:border-[#666b81]"
              onClick={goNotice}
            >
              <div className="text-[14px] leading-[100%] font-sans font-medium text-[#666b81] whitespace-nowrap">
                {translations.mp7}
              </div>
            </div>
            <div
              className="flex flex-row items-center justify-start py-[20px] border-b border-[#d5dce4] dark:border-[#666b81]"
              onClick={goFaq}
            >
              <div className="text-[14px] leading-[100%] font-sans font-medium text-[#666b81] whitespace-nowrap">
                FAQ
              </div>
            </div>
            <div
              className="flex flex-row items-center justify-start py-[20px] border-b border-[#d5dce4] dark:border-[#666b81]"
              onClick={goAppInfo}
            >
              <div className="text-[14px] leading-[100%] font-sans font-medium text-[#666b81] whitespace-nowrap">
                {translations.mp8}
              </div>
            </div>
            <div
              className="flex flex-row items-center justify-start py-[20px] border-b border-[#d5dce4] dark:border-[#666b81]"
              onClick={() => navigate("/error-action")}
            >
              <div className="text-[14px] leading-[100%] font-sans font-medium text-[#666b81] whitespace-nowrap">
                {translations.mp9}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Floating Action Button */}
      <div
        className="fixed bottom-[80px] right-[30px] w-[75px] h-[75px] flex items-center justify-center"
        onClick={handleOpenModal}
      >
        <div className="relative w-full h-full rounded-full flex items-center justify-center">
          <div className="absolute inset-0 bg-[linear-gradient(64.95deg,#61e3eb_0%,#3677f6_100%)] rounded-full"></div>
          <imgz
            className="relative w-[44px] h-[44px]"
            src="ChatTeardropDots22_205.png"
            alt="Chat"
          />
        </div>
      </div>

      <Modal2 isVisible={isModalVisible2} onClose={handleCloseModal} />
    </div>
  );
};

export default MyPage;
