import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { setUserInfo } from "./actions";
import { IoIosArrowBack } from "react-icons/io";

const PersonaSetting = () => {
  const dispatch = useDispatch();
  const accessToken = sessionStorage.getItem("accessToken");
  const apiUrl = process.env.REACT_APP_API_URL;
  const userInfo = useSelector((state) => state.userInfo);
  const [selected, setSelected] = useState({
    gender: null,
    age: null,
    occupation: null,
    relationshipStatus: null,
  });

  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("theme") === "dark"
  );

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add("dark");
      localStorage.setItem("theme", "dark");
    } else {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("theme", "light");
    }
  }, [darkMode]);

  // Pre-select options based on userInfo.mbti
  useEffect(() => {
    if (userInfo.mbti) {
      setSelected({
        gender: userInfo.sex,
        age: userInfo.age,
        occupation: userInfo.job,
        relationshipStatus: userInfo.love,
      });
    }
  }, [userInfo]);

  const navigate = useNavigate();

  const handleSelect = (category, value) => {
    setSelected((prev) => ({
      ...prev,
      [category]: value,
    }));
  };

  const fetchInfo = async (accessToken) => {
    try {
      console.log("보내는 토큰!! ", accessToken);
      const response = await fetch(`${apiUrl}/auth/user-info`, {
        method: "GET", // Use DELETE method
        headers: {
          Authorization: `Bearer ${accessToken}`, // Include the access token
          "Content-Type": "application/json",
        },
        // You can include a body if required by the API, but often DELETE requests don't have a body
        // body: JSON.stringify({ someData: "value" }),
      });

      if (response.ok) {
        // navigate("/welcome");
        const result = await response.json();
        dispatch(setUserInfo(result));
        // console.log("Account deleted successfully:", result);
        // Handle successful deletion, e.g., redirect or show a message
      } else {
        console.error("Failed to delete account:", response.statusText);
        // Handle error response
      }
    } catch (error) {
      console.error("Error during the request:", error);
      // Handle request error
    }
  };

  const updateProfile = async () => {
    const data = {
      userid: userInfo.userid,
      nickname: userInfo.nickname,
      nativeLanguage: userInfo.nativeLanguage,
      targetLanguage: userInfo.targetLanguage,
      mbti: userInfo.mbti,
      sex:
        selected.gender === "남성"
          ? "MALE"
          : selected.gender === "여성"
          ? "FEMALE"
          : "NOT_SPECIFIED",
      age:
        selected.age === "10대"
          ? "TEENS"
          : selected.age === "20대"
          ? "20S"
          : selected.age === "30대"
          ? "30S"
          : selected.age === "40대"
          ? "40S"
          : selected.age === "50대 이상"
          ? "50_AND_ABOVE"
          : "NOT_SPECIFIED",
      job:
        selected.occupation === "학생"
          ? "STUDENT"
          : selected.occupation === "직장인"
          ? "EMPLOYEE"
          : selected.occupation === "프리랜서"
          ? "FREELANCER"
          : selected.occupation === "기타"
          ? "OTHER"
          : "NOT_SPECIFIED",
      love:
        selected.relationshipStatus === "싱글"
          ? "SINGLE"
          : selected.relationshipStatus === "썸"
          ? "DATING"
          : selected.relationshipStatus === "연애"
          ? "IN_A_RELATIONSHIP"
          : selected.relationshipStatus === "결혼"
          ? "MARRIED"
          : selected.relationshipStatus === "기타"
          ? "OTHER"
          : "NOT_SPECIFIED",
    };

    try {
      const response = await fetch(`${apiUrl}/auth/complete-onboarding`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          // Add any other headers you need, such as authentication tokens
        },
        body: JSON.stringify(data), // Convert the data to JSON
      });

      if (response.ok) {
        const result = await response.json();
        console.log("Onboarding updated successfully:", result);
        fetchInfo(accessToken);
        if (sessionStorage.getItem("wherepersona") === "study") {
          sessionStorage.removeItem("wherepersona");
          navigate("/personastudy");
        } else {
          navigate("/accountinfo");
        }
      } else {
        console.error("Failed to update profile:", response.statusText);
        // alert("Failed to update profile.");
      }
    } catch (error) {
      console.error("Error during the request:", error);
      alert("An error occurred while updating the profile.");
    }
  };

  const handleStart = () => {
    const allSelected = Object.values(selected).every(
      (value) => value !== null
    );
    if (allSelected) {
      navigate("/accountinfo", { state: { selected } });
    } else {
      toast.error("모든 옵션을 선택해 주세요.");
    }
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className="relative min-h-screen flex flex-col bg-[#fff] dark:bg-[#121418] overflow-hidden">
      <div className="w-[100%] h-[2vh] bg-[#faf8f6] dark:bg-[#121418]"></div>
      {/* Header */}
      <div className="flex items-center justify-between h-[50px] bg-[#faf8f6] dark:bg-[#121418]">
        <div
          className="w-[36px] h-[36px] flex items-center justify-center"
          onClick={handleGoBack}
        >
          <IoIosArrowBack color={darkMode ? "white" : "black"} size={30} />
        </div>
        <div className=" flex items-center justify-center p-[10px] font-sans font-bold dark:text-[#fff]">
          페르소나 정보
        </div>
        <div className="w-[36px] h-[36px] flex items-center justify-center p-[10px] opacity-0">
          <img width="10" height="16" src="back.png" alt="icon" />
        </div>
      </div>

      {/* Content */}
      <div className="flex-1 px-[16px] py-[20px] pb-[20px] flex flex-col gap-[14px]">
        {/* Title and Description */}

        {/* Gender */}
        <div className="flex flex-col gap-[14px]">
          <div className="text-[14px] leading-[100%] font-sans font-bold text-[#000] dark:text-[#fff]">
            성별
          </div>
          <div className="flex gap-[6px]">
            {["여성", "남성", "무관"].map((gender) => (
              <div
                key={gender}
                className={`flex items-center justify-center py-[8px] px-[14px] border-[1px] border-solid rounded-[59px] ${
                  selected.gender === gender
                    ? darkMode
                      ? "bg-[#fff] border-[#fff] text-[#000]"
                      : "bg-[#e3edff] border-[#3677f6] text-[#3677f6]"
                    : darkMode
                    ? " border-[#e9ecef] text-[#fff]"
                    : " border-[#e9ecef] text-[#000]"
                }`}
                onClick={() => handleSelect("gender", gender)}
              >
                <div className="text-[14px] leading-[100%] font-sans font-medium whitespace-nowrap">
                  {gender}
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Age Group */}
        <div className="flex flex-col gap-[14px]">
          <div className="text-[14px] leading-[100%] font-sans font-bold text-[#000] dark:text-[#fff]">
            연령대
          </div>
          <div className="flex flex-wrap gap-[6px]">
            {["10대", "20대", "30대", "40대", "50대 이상", "무관"].map(
              (age) => (
                <div
                  key={age}
                  className={`flex items-center justify-center py-[8px] px-[14px] border-[1px] border-solid rounded-[59px] ${
                    selected.age === age
                      ? darkMode
                        ? "bg-[#fff] border-[#fff] text-[#000]"
                        : "bg-[#e3edff] border-[#3677f6] text-[#3677f6]"
                      : darkMode
                      ? " border-[#e9ecef] text-[#fff]"
                      : " border-[#e9ecef] text-[#000]"
                  }`}
                  onClick={() => handleSelect("age", age)}
                >
                  <div className="text-[14px] leading-[100%] font-sans font-medium whitespace-nowrap">
                    {age}
                  </div>
                </div>
              )
            )}
          </div>
        </div>

        {/* Occupation */}
        <div className="flex flex-col gap-[14px]">
          <div className="text-[14px] leading-[100%] font-sans font-bold text-[#000] dark:text-[#fff]">
            직업
          </div>
          <div className="flex flex-wrap gap-[6px]">
            {["학생", "직장인", "프리랜서", "기타", "무관"].map((job) => (
              <div
                key={job}
                className={`flex items-center justify-center py-[8px] px-[14px] border-[1px] border-solid rounded-[59px] ${
                  selected.occupation === job
                    ? darkMode
                      ? "bg-[#fff] border-[#fff] text-[#000]"
                      : "bg-[#e3edff] border-[#3677f6] text-[#3677f6]"
                    : darkMode
                    ? " border-[#e9ecef] text-[#fff]"
                    : " border-[#e9ecef] text-[#000]"
                }`}
                onClick={() => handleSelect("occupation", job)}
              >
                <div className="text-[14px] leading-[100%] font-sans font-medium whitespace-nowrap">
                  {job}
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Relationship Status */}
        <div className="flex flex-col gap-[14px]">
          <div className="text-[14px] leading-[100%] font-sans font-bold text-[#000] dark:text-[#fff]">
            연애상태
          </div>
          <div className="flex flex-wrap gap-[6px]">
            {["싱글", "썸", "연애", "결혼", "무관"].map((status) => (
              <div
                key={status}
                className={`flex items-center justify-center py-[8px] px-[14px] border-[1px] border-solid rounded-[59px] ${
                  selected.relationshipStatus === status
                    ? darkMode
                      ? "bg-[#fff] border-[#fff] text-[#000]"
                      : "bg-[#e3edff] border-[#3677f6] text-[#3677f6]"
                    : darkMode
                    ? " border-[#e9ecef] text-[#fff]"
                    : " border-[#e9ecef] text-[#000]"
                }`}
                onClick={() => handleSelect("relationshipStatus", status)}
              >
                <div className="text-[14px] leading-[100%] font-sans font-medium whitespace-nowrap">
                  {status}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Footer */}
      <div className="flex-none pb-[20px] px-[16px]">
        <button
          className="w-full h-[54px] flex items-center justify-center py-[10px] px-[20px] bg-[linear-gradient(64.95deg,#61e3eb_0%,#3677f6_100%)] rounded-[12px] text-[#fff] text-[14px] leading-[100%] font-sans font-bold"
          onClick={updateProfile}
        >
          저장하기
        </button>
      </div>

      {/* Toast Container */}
      <ToastContainer />
    </div>
  );
};

export default PersonaSetting;
