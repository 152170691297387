import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setManageID, setType, setImageUrl, setWhere } from "./actions";
import { IoIosArrowBack } from "react-icons/io";

const Note = () => {
  const dispatch = useDispatch();
  const [selectedGender, setSelectedGender] = useState(null);
  const [isOverlayVisible, setOverlayVisible] = useState(false);
  const overlayRef = useRef(null); // Ref for the overlay
  const typeList = ["MBTI", "Persona", "Situation", "Psychology", "Expression"];
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;
  const accessToken = sessionStorage.getItem("accessToken");
  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("theme") === "dark"
  );

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add("dark");
      localStorage.setItem("theme", "dark");
    } else {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("theme", "light");
    }
  }, [darkMode]);
  const [sortConfig, setSortConfig] = useState({
    key: "latest",
    direction: "ascending",
  });
  const [visibleItems, setVisibleItems] = useState(10); // Start by showing 10 items
  const loadMoreItems = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 10); // Load 10 more items on each click
  };
  const handleGoBack = () => {
    navigate(-1);
  };
  const toggleOverlay = () => {
    setOverlayVisible(!isOverlayVisible);
  };

  const handleGoStudy = async (item) => {
    try {
      // Fetch the first data (Korean)
      const response = await fetch(`${apiUrl}/customize_quiz/${item.quizId}`);
      if (!response.ok) {
        throw new Error(`Failed to fetch data: ${response.status}`);
      }
      const data = await response.json();

      // Fetch the second data (English)
      const response2 = await fetch(
        `${apiUrl}/customize_quiz/${item.quizId + 6}`
      );
      if (!response2.ok) {
        throw new Error(`Failed to fetch data: ${response2.status}`);
      }
      const data2 = await response2.json();

      // Combine the two data objects into one
      const combinedData = {
        kor: data,
        eng: data2,
      };

      // Navigate to '/notequiz' and pass the combined object as state
      if (data.type === "ox") {
        navigate("/notequiz", { state: { combinedData } });
      } else {
        navigate("/notequiz2", { state: { combinedData } });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSelect = (category, value) => {
    if (category === "gender") {
      setSelectedGender((prevSelectedGender) =>
        prevSelectedGender === value ? null : value
      );
    }
  };

  const handleComplete = () => {
    toggleOverlay(); // Optionally close the overlay
  };

  const handleClickOutside = (event) => {
    if (overlayRef.current && !overlayRef.current.contains(event.target)) {
      setOverlayVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        `${apiUrl}/wrong-answers?page=1&limit=1000`,
        {
          method: "GET", // or 'POST' depending on your API
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json", // Include other headers if needed
          },
        }
      );

      if (response.ok) {
        const result = await response.json();
        setData(result.data.reverse());
      } else {
        console.error("Failed to fetch data", response.status);
        // Handle the error as needed
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures this runs only once when the component mounts

  return (
    <div className="flex min-h-screen justify-center w-full bg-[#faf8f6] dark:bg-[#121418]">
      {isOverlayVisible && (
        <div className="absolute w-full h-full bg-black opacity-50 z-10"></div>
      )}
      <div className="relative min-x-[360px] w-full bg-[#faf8f6] dark:bg-[#121418]">
        {/* Updated Item List Section */}
        <div className="relative left-0 top-0 w-full flex flex-col items-start justify-start px-[16px]">
          <div className="relative self-stretch h-[50px] shrink-0">
            <div className="absolute left-0 top-[7px] w-full flex flex-row items-center justify-between">
              <div
                className="w-[36px] h-[36px] shrink-0 flex flex-row items-center justify-start "
                onClick={handleGoBack}
              >
                {/* <img width="8" height="16" src="back.png"></img> */}
                <IoIosArrowBack
                  color={darkMode ? "white" : "black"}
                  size={30}
                />
              </div>
              <div className="text-[16px] leading-[100%] font-sans font-bold text-[#000] dark:text-[#fff] text-center whitespace-nowrap">
                오답노트
              </div>
              <div className="w-[36px] h-[36px] shrink-0 flex flex-row items-center justify-center">
                <div className="relative w-[30px] h-[30px] shrink-0"></div>
              </div>
            </div>
          </div>
          <div className="w-full h-[30px]"></div>
        </div>
        <div className="self-stretch flex flex-col items-end justify-center gap-[4px]">
          <div className="flex flex-col items-start justify-start py-[10px] px-[16px]">
            <div className="self-stretch flex flex-row items-center justify-start gap-[5px]">
              <div className="text-[10px] leading-[100%] font-sans font-medium text-[#666b81] text-right whitespace-nowrap">
                최신순
              </div>
              <div className="w-[2px] h-[2px] shrink-0 bg-[#666b81] rounded-full"></div>
              <div className="text-[10px] leading-[100%] font-sans font-medium text-[#666b81] whitespace-nowrap">
                알파벳순
              </div>
              <div className="w-[2px] h-[2px] shrink-0 bg-[#666b81] rounded-full"></div>
              <div className="text-[10px] leading-[100%] font-sans font-medium text-[#3677f6] whitespace-nowrap">
                별점순
              </div>
            </div>
          </div>
          {/* item1 */}
          <div className="w-full flex flex-col items-start bg-[#fff] overflow-y-auto">
            {/* 카드 */}
            {data.slice(0, visibleItems).map((item, index) => {
              // Check if `customizes` is defined and has at least 2 elements
              return (
                <div
                  key={index}
                  className="w-full flex flex-row items-start justify-between py-[14px] px-[16px] bg-[#fff] dark:bg-[#31333e]
 border border-[#e9ecef] dark:border-[#000] border-b-0"
                  onClick={() => handleGoStudy(item)}
                >
                  {/* Titles */}
                  <div className="flex flex-col gap-[17px]">
                    <div className="flex flex-col gap-[12px]">
                      <div className="flex items-center">
                        {/* English title */}
                        <div className="text-[14px] leading-[100%] font-sans font-bold text-[#333] dark:text-[#fff] break-all">
                          {item.nativeTitle} {/* English title */}
                        </div>
                      </div>
                      {/* Korean title */}
                      <div className="text-[14px] leading-[100%] font-sans font-medium text-[#333] dark:text-[#fff] break-all">
                        {item.targetTitle} {/* Korean title */}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}

            {/* Load More Button */}
            {visibleItems < data.length && (
              <div className="flex justify-center text-center py-[14px] bg-[#fff] dark:bg-[#31333e] border border-[#e9ecef] dark:border-[#000] w-[100%]">
                <button
                  onClick={loadMoreItems}
                  className="px-4 py-2 bg-[#007bff] text-white rounded"
                >
                  더보기
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Note;
