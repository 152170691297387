import bgImage1 from "./default1.webp";
import bgImage2 from "./default2.webp";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect, useContext, useRef } from "react";
import { LanguageContext } from "./LanguageContext";
import LanguageSwitcher2 from "./LanguageSwitcher2";
import StudyQuitModal from "./StudyQuitModal";
import { useDispatch, useSelector } from "react-redux";
import { useDifficulty } from "./DifficultyContext";
import BookmarkModal from "./BookmarkModal";
import UseSwipe from "./UseSwipe"; // Adjust the path as necessary

const Conversation = () => {
  const location = useLocation();
  const { script, id } = location.state || {};
  console.log(script);
  const { translations, language } = useContext(LanguageContext);
  const navigate = useNavigate();
  const type = useSelector((state) => state.type);
  const imageUrl = useSelector((state) => state.imageUrl);
  const where = useSelector((state) => state.where);
  const handleSwipeLeft = () => {
    handleGoNext();
  };

  const handleSwipeRight = () => {
    handleGoBack();
  };

  UseSwipe(handleSwipeLeft, handleSwipeRight);
  let imageUrl2;
  if (!imageUrl.startsWith("/")) {
    imageUrl2 = "/" + imageUrl;
  } else {
    imageUrl2 = imageUrl;
  }
  const { difficulty, setDifficulty } = useDifficulty();
  const accessToken = sessionStorage.getItem("accessToken");
  const manageId = useSelector((state) => state.manageId);
  const [bookmarkId, setBookmarkId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [addMargin, setAddMargin] = useState(false);
  const oppositeLanguage = language === "kor" ? "eng" : "kor";
  const contentRef = useRef(null);

  useEffect(() => {
    const checkHeight = () => {
      if (contentRef.current) {
        const contentHeight = contentRef.current.offsetHeight;
        const viewportHeight = window.innerHeight * 0.1;

        if (contentHeight > viewportHeight) {
          setAddMargin(true);
        } else {
          setAddMargin(false);
        }
      }
    };

    checkHeight();
    window.addEventListener("resize", checkHeight);

    return () => window.removeEventListener("resize", checkHeight);
  }, []);

  const handleLink = () => {
    window.location.href =
      "https://www.google.com/search?q=%EC%9D%B4%EC%A7%80%EB%B8%8C%EB%A0%88%EC%9D%B8%EC%86%8C%ED%94%84%ED%8A%B8";
  };

  const handleGoStudy = () => {
    if (where === "study") {
      navigate(`/${type}studylist`);
    } else {
      navigate(`/bookmark`);
    }
  };

  const ProgressBar = ({ filledSections }) => {
    const totalSections = 6;
    const gapWidth = 4; // space between sections

    return (
      <div className="w-full h-1 flex z-40">
        {Array.from({ length: totalSections }).map((_, index) => (
          <div
            key={index}
            className={`h-full ${
              index < filledSections ? "bg-blue-500" : "bg-gray-300"
            }`}
            style={{
              flex: `1 0 calc(100% / ${totalSections})`,
              marginRight: index < totalSections - 1 ? `${gapWidth}px` : "0",
            }}
          />
        ))}
      </div>
    );
  };

  const [isOverlayVisible, setOverlayVisible] = useState(false);
  const overlayRef = useRef(null); // Ref for the overlay

  const toggleOverlay = () => {
    if (isOverlayVisible === false) {
      setOverlayVisible(!isOverlayVisible);
    } else {
      setOverlayVisible(!isOverlayVisible);
    }
  };

  const handleClickOutside = (event) => {
    if (overlayRef.current && !overlayRef.current.contains(event.target)) {
      setOverlayVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [isModalVisible, setModalVisible] = useState(false);

  const toggleModal = () => {
    setModalVisible(!isModalVisible);
  };

  const handleModalAction1 = () => {
    // Define the action for the first button here
    setModalVisible(false); // Hide the modal after action
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // second overlay
  const [isOverlayVisible2, setOverlayVisible2] = useState(false);
  const [selectedDifficulty, setSelectedDifficulty] = useState("E"); // Default to 'E'
  const overlayRef2 = useRef(null); // Ref for the overlay

  const toggleOverlay2 = () => {
    if (isOverlayVisible2 === false) {
      setOverlayVisible2(!isOverlayVisible2);
    } else {
      setOverlayVisible2(!isOverlayVisible2);
    }
  };

  const handleDifficultyChange = (difficulty) => {
    if (difficulty === "E") {
      setDifficulty("easyId");
    } else if (difficulty === "N") {
      setDifficulty("normalId");
    } else {
      setDifficulty("difficultId");
    }
    setSelectedDifficulty(difficulty);
    setOverlayVisible2(false); // Optionally close the overlay after selecting
  };

  const getImageSrc = () => {
    switch (difficulty) {
      case "easyId":
        return "e.png";
      case "normalId":
        return "n.png";
      case "difficultId":
        return "d.png";
      default:
        return "e"; // Default image
    }
  };

  const handleClickOutside2 = (event) => {
    if (overlayRef2.current && !overlayRef2.current.contains(event.target)) {
      setOverlayVisible2(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside2);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside2);
    };
  }, []);

  const [isModalVisible2, setisModalVisible2] = useState(false);

  const handleGoClick = () => {
    setisModalVisible2(true);
  };

  const handleCloseModal = () => {
    setisModalVisible2(false);
  };

  useEffect(() => {
    if (isModalVisible2) {
      const timer = setTimeout(() => {
        setisModalVisible2(false);
      }, 2000); // Auto-close after 3 seconds

      return () => clearTimeout(timer); // Clean up timer on unmount
    }
  }, [isModalVisible2]);

  // bookmark
  const [selectedMark, setSelectedMark] = useState(false); // Default to 'E'

  useEffect(() => {
    console.log("매니지 id ", manageId);
    console.log("타입! ", type);
    let type2;
    if (type === "mbti" || type === "persona") {
      type2 = "customize";
    } else {
      type2 = type;
    }
    const fetchData = async () => {
      const response = await fetch("https://pastellapp.com/api/bookmarks/", {
        method: "GET", // or 'POST' depending on your API
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json", // Include other headers if needed
        },
      });

      if (response.ok) {
        const data = await response.json();

        // Check if itemId 5 is in the results array
        const itemFound = data.results.some(
          (item) => item.itemId === manageId && item.itemType === type2
        );

        // Update the selectedMark state based on whether the item is found
        setSelectedMark(itemFound);
      } else {
        console.error("Failed to fetch data", response.status);
        // Handle the error as needed
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures this runs only once when the component mounts

  const updateBookmark = async () => {
    let type2;
    if (type === "mbti" || type === "persona") {
      type2 = "customize";
    } else {
      type2 = type;
    }
    const postData = {
      itemId: parseInt(manageId, 10),
      itemType: type2,
    };

    const response = await fetch("https://pastellapp.com/api/bookmarks/", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json", // Ensure the server knows you're sending JSON
      },
      body: JSON.stringify(postData), // Convert the data to JSON string
    });

    if (response.ok) {
      const data = await response.json();
      console.log("Response data:", data);
    } else {
      console.error("Failed to post data", response.status);
      // Handle the error as needed
    }
  };

  const findId = async () => {
    let type2;
    if (type === "mbti" || type === "persona") {
      type2 = "customize";
    } else {
      type2 = type;
    }

    const response = await fetch("https://pastellapp.com/api/bookmarks/", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      const data = await response.json();

      const itemFound = data.results.find(
        (item) =>
          item.itemId === parseInt(manageId, 10) && item.itemType === type2
      );

      if (itemFound) {
        console.log("found id ", itemFound.id);
        return new Promise((resolve) => {
          setBookmarkId(itemFound.id);
          resolve(itemFound.id); // Resolve with the found id
        });
      } else {
        console.error("Item not found");
        return Promise.reject("Item not found"); // Reject the promise if not found
      }
    } else {
      console.error("Failed to fetch data", response.status);
      return Promise.reject("Failed to fetch data"); // Reject the promise if fetch fails
    }
  };

  const deleteBookmark = async (id) => {
    const response = await fetch(`https://pastellapp.com/api/bookmarks/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json", // Ensure the server knows you're sending JSON
      },
    });

    if (response.ok) {
      const data = await response.json();
      console.log("Response data2:", data);
    } else {
      console.error("Failed to post data", response.status);
      // Handle the error as needed
    }
  };

  const handleMarkAction = async () => {
    if (!selectedMark) {
      await updateBookmark();
      setSelectedMark(true);
      setShowModal(true);
    } else {
      try {
        const id = await findId(); // Wait for findId to resolve with the bookmarkId
        await deleteBookmark(id); // Pass the bookmarkId to deleteBookmark
        setSelectedMark(false);
      } catch (error) {
        console.error("An error occurred:", error);
      }
    }
  };

  const getMarkImage = () => {
    switch (selectedMark) {
      case false:
        return "bookmark.png";
      case true:
        return "bookmark2.png";
      default:
        return "bookmark.png"; // Default image
    }
  };

  const handleGoBack = () => {
    navigate(-1);
  };
  const handleGoNext = () => {
    navigate("/studypoint", { state: { script: script, id: id } });
  };

  // Step 1: Clean up the script by removing "\n" and splitting based on speakers.
  console.log("여기가 스크립트야~~ ", script);
  const cleanedScript = script[language][difficulty].script
    .replace(/\n/g, "")
    .split(/=(\/[A-Z]\/)/g);

  // Step 2: Create a helper function to format conversation.
  const formatConversation = (lines) => {
    const elements = [];
    let currentSpeaker = null;

    lines.forEach((line, index) => {
      if (line.startsWith("/")) {
        currentSpeaker = line; // Store the current speaker (either /A/ or /B/)
      } else if (line.trim() !== "") {
        // Determine speaker label based on the current speaker
        const speakerLabel =
          currentSpeaker === "/A/"
            ? script[language][difficulty].humanA + ": "
            : script[language][difficulty].humanB + ": ";

        // Set alignment and color based on the current speaker
        const alignment =
          currentSpeaker === "/A/"
            ? "self-start justify-start" // Left-aligned bubble for humanA
            : "self-end justify-end"; // Right-aligned bubble for humanB

        const bubbleColor =
          currentSpeaker === "/A/"
            ? "bg-[#fff] rounded-tl-[18px] rounded-tr-[18px] rounded-br-[18px] rounded-bl-0" // Bubble style for humanA
            : "bg-[#ffee93] rounded-tl-[18px] rounded-tr-[18px] rounded-bl-[18px] rounded-br-0"; // Bubble style for humanB

        elements.push(
          <div
            key={index}
            className={`flex ${alignment} w-full py-[10px] px-[16px]`} // w-full ensures the bubble takes the full available width for alignment
          >
            <div
              className={`text-[14px] leading-[160%] font-sans text-[#000] break-words ${bubbleColor}`}
              style={{
                padding: "8px",
                maxWidth: "75%", // Bubble can grow up to 75% of the container
                wordWrap: "break-word",
                display: "inline-block", // Prevents the bubble from expanding unnecessarily
              }}
            >
              <strong>{speakerLabel}</strong>
              {line.trim()} {/* Add speaker label and conversation text */}
            </div>
          </div>
        );
      }
    });
    return elements;
  };

  const [backgroundImageUrl, setBackgroundImageUrl] = useState(""); // State to store the background image URL

  // Function to check if the image exists
  const checkImageExists = (url, callback) => {
    const img = new Image();
    img.onload = () => callback(true);
    img.onerror = () => callback(false);
    img.src = url;
  };

  useEffect(() => {
    const url = `https://pastellapp.com/api/upload${imageUrl2}`;
    const fallbackUrl = "https://pastellapp.com/api/upload/img/coolbg.jpg"; // Your fallback image URL

    checkImageExists(url, (exists) => {
      if (exists) {
        setBackgroundImageUrl(url);
      } else {
        const randomBgImage = Math.random() < 0.5 ? bgImage1 : bgImage2;
        setBackgroundImageUrl(randomBgImage);
      }
    });
  }, [imageUrl2]); // Only re-run the effect if `imageUrl` changes

  const [text, setText] = useState("");
  const [audioUrl, setAudioUrl] = useState("");

  const CLIENT_ID = "your-client-id"; // Replace with your actual Client ID
  const CLIENT_SECRET = "your-client-secret"; // Replace with your actual Client Secret

  const handleTextChange = (event) => {
    setText(event.target.value);
  };

  const handleSubmit = async () => {
    const apiUrl = "https://naveropenapi.apigw.ntruss.com/voice/v1/tts";
    const formData = new URLSearchParams();
    formData.append("speaker", "mijin"); // or 'jinho' for male voice
    formData.append("speed", "0");
    formData.append("text", text);

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "X-NCP-APIGW-API-KEY-ID": CLIENT_ID,
          "X-NCP-APIGW-API-KEY": CLIENT_SECRET,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formData,
      });

      if (response.ok) {
        const blob = await response.blob();
        const audioUrl = URL.createObjectURL(blob);
        setAudioUrl(audioUrl); // This creates a URL for the audio file
      } else {
        console.error("Error with TTS request:", response.statusText);
      }
    } catch (error) {
      console.error("Error with TTS request:", error);
    }
  };

  return (
    <div
      id="swipe-container"
      className="relative flex flex-col bg-[#fff] "
      style={{
        backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0) 15%, rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 1) 100%), url('${backgroundImageUrl}')`,
        backgroundSize: "cover",
        backgroundPosition: "bottom",
        backgroundRepeat: "no-repeat",
        minHeight: "100vh", // Ensure full viewport height
        width: "100%", // Ensure full viewport width
        overflow: "hidden auto", // Prevent scrolling
        scrollBehavior: "smooth", // Add smooth scroll behavior
      }}
    >
      {isOverlayVisible && (
        <div className="absolute w-full h-full bg-black opacity-50 z-50"></div>
      )}
      <div className="absolute w-full h-full bg-black opacity-0 z-10"></div>
      {/* Header */}
      <div className="relative flex items-center px-[16px] h-[50px]">
        <div className="absolute left-0 top-1/2 transform -translate-y-1/2 w-[60px] h-[60px] p-[10px] z-40">
          <img
            width="60"
            height="60"
            src="x.png"
            alt="icon"
            onClick={toggleModal}
          />
        </div>
        <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 p-[10px] z-40 text-white font-sans text-center">
          {translations.so1}
        </div>
        <div
          className="absolute top-1/2 transform -translate-y-1/2 w-[45px] h-[45px] p-[10px] z-40"
          style={{ left: "78%" }}
        >
          <img
            width="40"
            height="40"
            src="link.png"
            alt="icon"
            onClick={handleLink}
          />
        </div>
        <div
          className="absolute top-1/2 transform -translate-y-1/2 w-[45px] h-[45px] p-[10px] z-40"
          style={{ left: "88%" }}
        >
          <img
            width="45"
            height="45"
            src="ddd.png"
            alt="icon"
            onClick={toggleOverlay}
          />
        </div>
      </div>
      {/* Progress Bar */}
      <ProgressBar filledSections={1} />
      {/* Content */}
      <div className="flex justify-end mr-[10px] mt-[10px] z-50">
        <LanguageSwitcher2></LanguageSwitcher2>
      </div>
      <div
        ref={contentRef}
        className="relative w-full flex flex-1 flex-col justify-start min-h-[80%] z-40" // No unnecessary height or flex-grow
      >
        {/* Content area */}
        <div className="w-full flex flex-grow flex-col items-start justify-start">
          {formatConversation(cleanedScript)}
          <div className="h-[20px]"></div>{" "}
          {/* Extra spacing after the conversation */}
        </div>

        {/* Footer */}
        <div className="w-full z-50 ml-[5px] ">
          <div className="flex flex-col items-center justify-center">
            <div className="flex flex-row items-center justify-center gap-[24px]">
              <div
                className="relative w-[30px] h-[30px] shrink-0"
                onClick={handleGoBack}
              >
                <img className="relative" width="18" height="18" src="fb.png" />
              </div>
              <div
                className="relative w-[50px] h-[70px] shrink-0 flex"
                onClick={toggleOverlay2}
              >
                <div className="flex flex-col items-center gap-[8px]">
                  <div className="w-[50px] h-[50px]">
                    <div className="absolute w-[50px] h-[50px] bg-[#fff] rounded-full"></div>
                    <img
                      className="absolute top-[25%] left-[40%]"
                      width="10"
                      height="17"
                      src={getImageSrc()}
                    />
                  </div>
                  <div className="text-[12px] text-[#fff] text-center">
                    {translations.DIFFICULTY}
                  </div>
                </div>
              </div>
              <div
                className="w-[100px] h-[100px] shrink-0 flex"
                onClick={handleGoClick}
              >
                <img
                  className="relative"
                  width="100"
                  height="100"
                  src="play.png"
                />
              </div>
              <div
                className="relative w-[50px] h-[70px] shrink-0 flex"
                onClick={handleMarkAction}
              >
                <div className="flex flex-col items-center gap-[8px]">
                  <div className="w-[50px] h-[50px]">
                    <div className="absolute w-[50px] h-[50px] bg-[#fff] rounded-full"></div>
                    <img
                      className="absolute -translate-x-1/2 -translate-y-1/2 left-1/2 top-[calc(50%+-9px)]"
                      width="13"
                      height="24"
                      src={getMarkImage()}
                    />
                  </div>
                  <div className="text-[12px] text-[#fff] text-center">
                    {translations.BOOKMARK}
                  </div>
                </div>
              </div>
              <div
                className="relative w-[30px] h-[30px] shrink-0"
                onClick={handleGoNext}
              >
                <img className="relative" width="18" height="18" src="ff.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Overlay */}
      <div
        ref={overlayRef}
        className={`fixed bottom-0 left-0 w-full bg-white transition-transform duration-300 ease-in-out ${
          isOverlayVisible
            ? "transform translate-y-0"
            : "transform translate-y-full"
        }`}
        style={{
          height: "20%",
          zIndex: 100,
          borderTopLeftRadius: "30px", // Adjust the radius value as needed
          borderTopRightRadius: "30px", // Adjust the radius value as needed
        }}
      >
        {/* Content of the overlay */}
        <div className="flex flex-col h-full">
          {/* Text Container 1 */}
          <div
            className="flex flex-wrap justify-center items-center flex-grow font-sans font-bold"
            onClick={() => navigate("/langsetting")}
          >
            언어 설정
          </div>

          {/* Divider Line */}
          <div className="w-full border-t border-[#e9ecef]"></div>

          {/* Text Container 2 */}
          <div
            className="flex flex-wrap justify-center items-center flex-grow font-sans font-bold"
            onClick={() => navigate("/error-action")}
          >
            오류 신고
          </div>
        </div>
      </div>
      {/* Overlay2 */}
      <div
        ref={overlayRef2}
        className={`fixed bottom-0 left-0 w-full bg-white transition-transform duration-300 ease-in-out ${
          isOverlayVisible2
            ? "transform translate-y-0"
            : "transform translate-y-full"
        }`}
        style={{
          height: "20%",
          zIndex: 100,
          borderTopLeftRadius: "30px", // Adjust the radius value as needed
          borderTopRightRadius: "30px", // Adjust the radius value as needed
        }}
      >
        {/* Content of the overlay2 */}
        <div className="flex flex-col h-full">
          {/* Text Container 1 */}
          <div className="flex flex-wrap justify-center items-center flex-grow font-sans font-bold">
            난이도
          </div>

          {/* Divider Line */}
          <div className="w-full border-t border-[#e9ecef]"></div>

          <div
            className="flex flex-wrap justify-center items-center flex-grow font-sans text-[14px]"
            onClick={() => handleDifficultyChange("E")}
          >
            EASY
          </div>

          {/* Divider Line */}
          <div className="w-full border-t border-[#e9ecef]"></div>

          <div
            className="flex flex-wrap justify-center items-center flex-grow font-sans text-[14px]"
            onClick={() => handleDifficultyChange("N")}
          >
            NORMAL
          </div>

          {/* Divider Line */}
          <div className="w-full border-t border-[#e9ecef]"></div>

          <div
            className="flex flex-wrap justify-center items-center flex-grow font-sans text-[14px]"
            onClick={() => handleDifficultyChange("D")}
          >
            DIFFICULT
          </div>
        </div>
      </div>
      <StudyQuitModal
        isVisible={isModalVisible}
        onClose={() => setModalVisible(false)}
        onAction1={handleModalAction1}
        onAction2={handleGoStudy}
      />
      <BookmarkModal show={showModal} onClose={() => setShowModal(false)} />
    </div>
  );
};

export default Conversation;
