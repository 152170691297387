import axios from "axios";

const api = axios.create({
  baseURL: "https://pastellapp.com/api",
  timeout: 10000, // Optional: Set a timeout to avoid hanging requests
});

let isRefreshing = false; // Flag to prevent multiple refresh requests
let refreshSubscribers = []; // Queue to hold pending requests during refresh

// Function to add subscribers (requests) while refreshing token
const subscribeTokenRefresh = (cb) => {
  refreshSubscribers.push(cb);
  console.log("Subscribed request waiting for token refresh...");
};

// Notify all subscribers about the new token
const onRefreshed = (newToken) => {
  console.log("Token refreshed, notifying subscribers...");
  refreshSubscribers.forEach((cb) => cb(newToken));
  refreshSubscribers = [];
};

// Interceptor to check if token is expired
api.interceptors.request.use(
  async (config) => {
    console.log("Interceptor triggered for request:", config.url); // Ensure the interceptor is triggered

    let accessToken = sessionStorage.getItem("accessToken");
    console.log("Access token from sessionStorage:", accessToken);

    if (!accessToken) {
      console.error("No access token found, proceeding without auth.");
      return config; // Allow the request to proceed without the token
    }

    let tokenExpiry = getExpiryFromAccessToken(accessToken); // Extract the expiry time from token
    console.log("Token expiry time:", tokenExpiry);

    const currentTime = Math.floor(Date.now() / 1000);
    console.log("Current time:", currentTime);

    // Check if token is expired
    if (isTokenExpired(tokenExpiry)) {
      console.log("Token is expired. Attempting to refresh...");

      if (!isRefreshing) {
        console.log("Starting token refresh...");
        isRefreshing = true;

        try {
          const data = await refreshAccessToken(); // Try to refresh the token
          if (data) {
            console.log("New token data received:", data);
            sessionStorage.setItem("accessToken", data.accessToken);
            sessionStorage.setItem("refreshToken", data.refreshToken);
            accessToken = data.accessToken;
            console.log(
              "Token refresh complete. New access token:",
              accessToken
            );

            // Notify all subscribers about the new token
            onRefreshed(accessToken);
          } else {
            console.error("Failed to refresh token. No data returned.");
            return Promise.reject(new Error("Failed to refresh token"));
          }
        } catch (error) {
          console.error("Error during token refresh:", error);
          return Promise.reject(error); // Propagate the error
        } finally {
          isRefreshing = false; // Reset the flag after refresh attempt
          console.log("Token refresh flag reset");
        }
      } else {
        console.log(
          "Token refresh is already in progress, queuing the request."
        );
      }

      // Queue pending requests until the token refresh is done
      return new Promise((resolve) => {
        subscribeTokenRefresh((newToken) => {
          config.headers.Authorization = `Bearer ${newToken}`;
          resolve(config);
        });
      });
    } else {
      console.log("Token is still valid.");
      config.headers.Authorization = `Bearer ${accessToken}`;
      return config;
    }
  },
  (error) => {
    console.error("Error in request interceptor:", error);
    return Promise.reject(error);
  }
);

// Function to check if the token is expired
function isTokenExpired(exp) {
  const currentTime = Math.floor(Date.now() / 1000);
  const bufferTime = 0; // 5 minutes buffer before expiration
  console.log(
    "Buffer time check. Expiration with buffer:",
    exp,
    " Current + Buffer:",
    currentTime + bufferTime
  );
  return exp <= currentTime + bufferTime; // Trigger refresh 5 minutes before expiration
}

// Function to extract the expiry time from the access token (JWT)
function getExpiryFromAccessToken(token) {
  if (!token) return null;
  try {
    const payload = JSON.parse(atob(token.split(".")[1])); // Decode JWT to get the payload
    console.log("Token payload:", payload);
    return payload.exp;
  } catch (error) {
    console.error("Invalid token format:", error);
    return null;
  }
}

// Function to refresh the access token using the refresh token
async function refreshAccessToken() {
  try {
    console.log("Sending refresh token request...");
    const response = await api.post(
      "/auth/refresh-token",
      {
        userid: "12@a.com",
        refreshToken: sessionStorage.getItem("refreshToken"),
      },
      {
        headers: {
          "Content-Type": "application/json", // Ensure the content-type is correct
        },
      }
    );
    console.log("Refresh token response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error refreshing access token:", error);
    throw error;
  }
}

export default api;
