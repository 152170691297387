import axios from "axios";
import API_BASE_URL from "../../config";

// customize
export const uploadFile = async (file) => {
  const formData = new FormData();
  formData.append("file", file);

  try {
    const response = await axios.post(`${API_BASE_URL}/upload`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data.fileUrl; // 업로드된 파일의 URL 반환
  } catch (error) {
    console.error("Error uploading file:", error);
    throw error;
  }
};

export const clearFileUrl = () => {
  // 파일을 삭제하는 대신 빈 값을 반환하여 URL을 비웁니다.
  return "";
};

export const getFileUrl = (fileUrl) => `${API_BASE_URL}/upload${fileUrl}`;
