import React from "react";

const NoteQuizQuitModal = ({ isVisible, onClose, onAction1, onAction2 }) => {
  if (!isVisible) return null;

  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
      onClick={onClose}
    >
      <div
        className="w-[60%] h-[120px] flex flex-col rounded-[10px] overflow-hidden bg-[#faf8f6]"
        onClick={(e) => e.stopPropagation()} // Prevent click from closing the modal
      >
        {/* Modal Content */}
        <div className="flex-1 flex items-center justify-center pt-[20px] pb-[20px] px-0">
          <div className="text-[14px] leading-[155%] font-['Pretendard_Variable'] font-medium text-[#000] text-center">
            오답퀴즈를 종료하시나요?
          </div>
        </div>
        {/* Button Row */}
        <div className="h-[46px] flex border-t border-[#c5d0dd]">
          <div className="flex-1 h-full flex items-center justify-center border-r border-[#c5d0dd]">
            <button
              className="h-full w-full flex items-center justify-center text-[14px] font-sans font-bold text-[#666b81] bg-[#faf8f6] hover:bg-[#e0e0e0] transition-colors duration-300"
              onClick={onAction1}
            >
              취소
            </button>
          </div>
          <div className="flex-1 h-full flex items-center justify-center">
            <button
              className="h-full w-full flex items-center justify-center text-[14px] font-sans font-bold text-[#3677f6] bg-[#faf8f6] hover:bg-[#e0e0e0] transition-colors duration-300"
              onClick={onAction2}
            >
              확인
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoteQuizQuitModal;
