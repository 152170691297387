import { useMemo } from "react";
import Btn from "./Btn";
import PropTypes from "prop-types";
import styles from "./BottomButtons.module.css";
import { useNavigate, useLocation } from "react-router-dom";
const BottomButtons = ({
  className = "",
  prop,
  divMinWidth,
  prop1,
  to,
  to1,
  onClick,
  email,
  darkMode,
}) => {
  const div2Style = useMemo(() => {
    return {
      minWidth: divMinWidth,
    };
  }, [divMinWidth]);

  console.log("email", email);

  const navigate = useNavigate();

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    if (to === "/lost-pw1") {
      navigate(`/lost-pw1`, { state: { userId: email } });
    } else {
      navigate(to);
    }
  };

  return (
    <section className={[styles.bottomButtons, className].join(" ")}>
      <div className={styles.findIdButton}>
        <div className={styles.div} style={div2Style}>
          <button
            onClick={handleClick}
            style={{
              textDecoration: "none",
              color: `${darkMode ? "white" : "black"}`,
            }}
          >
            {prop}
          </button>
        </div>
      </div>
      <div className={styles.loginButton}>
        <Btn
          btnAlignSelf="unset"
          btnBorder="unset"
          btnFlex="1"
          btnBackground="linear-gradient(64.95deg, #61e3eb, #3677f6)"
          prop={prop1}
          divWidth="unset"
          divFontWeight="bold"
          divColor="#fff"
          divMinWidth="28px"
          to={to1}
          onClick={onClick}
        />
      </div>
    </section>
  );
};

BottomButtons.propTypes = {
  className: PropTypes.string,
  prop: PropTypes.string,
  prop1: PropTypes.string,

  /** Style props */
  divMinWidth: PropTypes.any,
};

export default BottomButtons;
