import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Navigation from '../../components/admin/Navigation';
import { getCashById } from '../../api/admin/cash';

const PaymentDetail = () => {
  const navigate = useNavigate();
  const { cashId } = useParams();
  const [paymentData, setPaymentData] = useState(null);

  useEffect(() => {
    const fetchPaymentData = async () => {
      try {
        const data = await getCashById(cashId);
        setPaymentData(data);
      } catch (error) {
        console.error('Error fetching payment data:', error);
      }
    };

    fetchPaymentData();
  }, [cashId]);

  if (!paymentData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="min-h-screen bg-[#FAF8F6] p-6">
      <Navigation />
      
      {/* 상단 버튼 */}
      <div className="flex justify-between items-center mb-5">
        <h1 className="text-2xl font-bold text-left" style={{fontFamily: 'Pretendard', fontSize: '24px', fontWeight: 700}}>
          결제상세
        </h1>
        <button
          onClick={() => navigate('/cash')}
          className="w-[100px] h-[48px] bg-[#666B81] text-white"
          style={{fontFamily: 'Pretendard', fontSize: '14px', fontWeight: 700, borderRadius: '18px 14px 18px 14px'}}
        >
          목록가기
        </button>
      </div>

      {/* 상품정보 */}
      <div className="bg-white rounded-lg p-6 shadow-md mb-10">
        <h2 className="text-xl mb-4" style={{fontFamily: 'Pretendard', fontSize: '18px', fontWeight: 700, color: '#0E111F'}}>
          상품정보
        </h2>
        <div className="grid grid-cols-3 gap-8">
          <InfoField label="결제수단" value={paymentData.payMethod} />
          <InfoField label="주문번호" value={paymentData.tid} />
          <InfoField label="상품구분" value={paymentData.goodsName} />
          <InfoField label="상품명" value={paymentData.goodsName} />
        </div>
      </div>

      {/* 구매자 정보 */}
      <div className="bg-white rounded-lg p-6 shadow-md mb-10">
        <h2 className="text-xl mb-4" style={{fontFamily: 'Pretendard', fontSize: '18px', fontWeight: 700, color: '#0E111F'}}>
          구매자 정보
        </h2>
        <div className="grid grid-cols-3 gap-8">
          <InfoField label="ID" value={paymentData.user.id} />
          <InfoField label="이름" value={paymentData.user.username} />
          <InfoField label="휴대폰 번호" value={paymentData.user.phoneNumber} />
        </div>
      </div>

      {/* 결제 정보 */}
      <div className="bg-white rounded-lg p-6 shadow-md">
        <h2 className="text-xl mb-4" style={{fontFamily: 'Pretendard', fontSize: '18px', fontWeight: 700, color: '#0E111F'}}>
          결제 정보
        </h2>
        <div className="grid grid-cols-3 gap-8">
          <InfoField label="주문금액" value={paymentData.amount} />
          <InfoField label="결제수단" value={paymentData.payMethod} />
          <InfoField label="결제일시" value={new Date(paymentData.paidAt).toLocaleString()} />
          <InfoField label="결제상태" value={paymentData.status} />
        </div>
      </div>
    </div>
  );
};

const InfoField = ({ label, value }) => (
  <div>
    <label className="block text-sm font-bold mb-2" style={{fontFamily: 'Pretendard', fontSize: '14px', fontWeight: 700, color: '#0E111F'}}>
      {label}
    </label>
    <div className="bg-[#E2E8EF] flex items-center" style={{height: '48px', padding: '18px 14px', borderRadius: '12px', fontFamily: 'Pretendard', fontSize: '14px', fontWeight: 500, color: '#000000'}}>
      {value}
    </div>
  </div>
);

export default PaymentDetail;
