import React, { useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import Title from "../logincomponents/Title";
import TextField from "../logincomponents/PhoneVerification";
import BBtn from "../logincomponents/BBtn";
import OptionalTextField from "../logincomponents/OptionalTextField";
import ReactiveOuter from "../logincomponents/ReactiveOuter";
import FormTop from "../logincomponents/FormTop";
import FormForm from "../logincomponents/FormForm";
import BottomButtons from "../logincomponents/BottomButtons";

const PW = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const foundUserId = location.state?.userId || "";
  const [phoneNumber, setPhoneNumber] = useState('');

  const handlePhoneNumberChange = (newPhoneNumber) => {
    // +82로 시작하는 번호를 010으로 변경
    let formattedNumber = newPhoneNumber;
    if (formattedNumber.startsWith('+82')) {
      formattedNumber = '0' + formattedNumber.slice(3);
    }
    
    // 숫자만 추출
    formattedNumber = formattedNumber.replace(/\D/g, '');
    
    // 11자리로 제한
    formattedNumber = formattedNumber.slice(0, 11);
    
    // 형식 적용 (010-1234-5678)
    if (formattedNumber.length > 3) {
      formattedNumber = formattedNumber.replace(/(\d{3})(\d{0,4})(\d{0,4})/, '$1-$2-$3');
    }
    
    setPhoneNumber(formattedNumber);
    console.log("전화번호 변경:", formattedNumber);
  };

  const handlePasswordReset = () => {
    navigate(`/lost-pw2/${encodeURIComponent(foundUserId)}`);
  };

  return (
    <ReactiveOuter>
      <FormTop>
        <Title
          titleGap="78px"
          frameDivWidth="unset"
          prop="비밀번호 재설정"
          bAlignSelf="unset"
          bHeight="unset"
          bOpacity="unset"
          bMinWidth="101px"
          bDisplay="inline-block"
        />
        <FormForm>
          <OptionalTextField 
            prop="아이디"
            value={foundUserId}
            readOnly={true}
            onChange={() => {}}
            style={{ backgroundColor: "#E2E8EF" }}
            disabled={true}
          />
          <TextField 
            phoneNumber={phoneNumber}
            onPhoneNumberChange={handlePhoneNumberChange} 
          />
        </FormForm>
      </FormTop>
      <div className="fixed bottom-[5vh] w-full">
        <BottomButtons prop1="다음" to1={`/lost-pw2/${encodeURIComponent(foundUserId)}`} onClick={handlePasswordReset} />
      </div>
    </ReactiveOuter>
  );
};

export default PW;
