import React, { useState, useEffect } from 'react';
import { Pie, Bar } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title } from 'chart.js';
import Navigation from '../../components/admin/Navigation';
import API_BASE_URL from '../../config';
import axios from 'axios';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title);

const LearnOverview = () => {
  const [customizeData, setCustomizeData] = useState(null);
  const [situationData, setSituationData] = useState(null);
  const [psychologyData, setPsychologyData] = useState(null);
  const [selectKorEng, setSelectKorEng] = useState(null);
  const [totalItems, setTotalItems] = useState({
    customize: 0,
    situation: 0,
    psychology: 0
  });

  useEffect(() => {

    // select_kor_eng.json 로드
    fetch('/select_kor_eng.json')
      .then(response => response.json())
      .then(data => setSelectKorEng(data))
      .catch(error => console.error('select_kor_eng.json을 불러오는 중 오류 발생:', error));

    // API 데이터 로드
    fetchApiData('customize', setCustomizeData);
    fetchApiData('situation', setSituationData);
    fetchApiData('psychology', setPsychologyData);

    // totalItems 데이터 로드
    fetchTotalItems();
  }, []);

  const fetchApiData = (endpoint, setData) => {
    fetch(`${API_BASE_URL}/${endpoint}/counts`)
      .then(response => response.json())
      .then(data => setData(data))
      .catch(error => console.error(`${endpoint} 데이터를 불러오는 중 오류 발생:`, error));
  };

  const fetchTotalItems = async () => {
    try {
      const customizeResponse = await axios.get(`${API_BASE_URL}/customize/managerFilter`, { params: { page: 1, limit: 1 } });
      const situationResponse = await axios.get(`${API_BASE_URL}/situation/managerFilter`, { params: { page: 1, limit: 1 } });
      const psychologyResponse = await axios.get(`${API_BASE_URL}/psychology/managerFilter`, { params: { page: 1, limit: 1 } });

      setTotalItems({
        customize: customizeResponse.data.total,
        situation: situationResponse.data.total,
        psychology: psychologyResponse.data.total
      });
    } catch (error) {
      console.error('totalItems를 불러오는 중 오류 발생:', error);
    }
  };

  // 데이터 변환 함수
  const transformData = (apiData, category) => {
    if (!apiData || !selectKorEng) return [];
    
    const categoryData = apiData[`${category}Count`];
    const categoryMapping = selectKorEng[category];
    
    return Object.keys(categoryMapping).map(key => {
      const item = categoryData.find(item => item[category] === categoryMapping[key]) || { count: "0" };
      return {
        label: key,
        value: parseInt(item.count)
      };
    });
  };

  // 파이 차트 데이터
  const pieChartData = {
    labels: ['CUSTOMIZE', 'SITUATION', 'PSYCHOLOGY'],
    datasets: [{
      data: [
        totalItems.customize,
        totalItems.situation,
        totalItems.psychology
      ],
      backgroundColor: ['#7DECC8', '#F67239', '#383C4B']
    }]
  };

  // 막대 차트 데이터 생성 함수
  const createBarChartData = (category, data) => {
    if (!data) return null;
    const transformedData = transformData(data, category.toLowerCase());
    return {
      labels: transformedData.map(item => item.label),
      datasets: [{
        data: transformedData.map(item => item.value),
        backgroundColor: getColorForCategory(category)
      }]
    };
  };

  const getColorForCategory = (category) => {
    const colors = {
      'MBTI': '#3677F6',
      'AGE': '#61E3EB',
      'SEX': '#FFEE93',
      'JOB': '#B7FFC3',
      'LOVE': '#FF99BE'
    };
    return colors[category] || '#000000';
  };

  if (!selectKorEng || !customizeData || !situationData || !psychologyData) return <div>로딩 중...</div>;

  return (
    <div className='min-h-screen' style={{ backgroundColor: '#FAF8F6' }}>
      <Navigation />
      <div style={{ padding: '26px 30px' }}>
        <h1 className="font-bold uppercase" style={{ width: '140px', height: '28px', fontFamily: 'Pretendard', fontSize: '24px', fontWeight: 700, textAlign: 'left' }}>
          OVERVIEW
        </h1>

        <div className="mt-6">
          <div className="flex gap-4">
            {/* 파이 차트 섹션 */}
            <div className="w-1/4 bg-white p-5 rounded-lg shadow-md">
              <h2 className="text-lg font-bold mb-4">학습하기 스크립트 작업 현황</h2>
              <div className="flex flex-col gap-2">
                {['customize', 'situation', 'psychology'].map((key) => (
                  <div key={key} className="flex items-center justify-between bg-white border border-[#E2E8EF] rounded-lg p-2">
                    <div className="flex items-center">
                      <div className="w-3 h-3 rounded-[4px] mr-2" style={{ backgroundColor: key === 'customize' ? '#7DECC8' : key === 'situation' ? '#F67239' : '#383C4B' }}></div>
                      <span className="uppercase font-['Pretendard_Variable'] text-[14px] font-bold">{key}</span>
                    </div>
                    <div>
                      <span className="font-['Pretendard'] text-[18px] font-bold">{totalItems[key].toLocaleString()}</span>
                      <span className="font-['Pretendard'] text-[14px] font-medium ml-1">건</span>
                    </div>
                  </div>
                ))}
              </div>
              <div className="flex justify-center mb-4 mt-[50px]">
                <Pie 
                  data={pieChartData} 
                  options={{
                    plugins: {
                      legend: {
                        display: false
                      }
                    }
                  }}
                />
              </div>
            </div>

            {/* Customize, Situation, Psychology 섹션 */}
            <div className="w-3/4">
              <div className="grid grid-cols-1 gap-4">
                {['CUSTOMIZE', 'SITUATION', 'PSYCHOLOGY'].map((section) => (
                  <div className="bg-white p-5 rounded-lg shadow-md" key={section}>
                    <h2 className="text-lg font-bold mb-4">{section}</h2>
                    <div className="grid" style={{
                      gridTemplateColumns: "40% 23% 8% 15% 14%"
                    }}>
                      {['MBTI', 'AGE', 'SEX', 'JOB', 'LOVE'].map((category, index) => (
                        <div className="h-40 w-full relative p-1" key={category}>
                          <Bar 
                            data={createBarChartData(category, section.toLowerCase() === 'customize' ? customizeData : section.toLowerCase() === 'situation' ? situationData : psychologyData)} 
                            options={{
                              responsive: true,
                              maintainAspectRatio: false,
                              plugins: {
                                legend: {
                                  display: false
                                },
                                tooltip: {
                                  enabled: false
                                }
                              },
                              scales: {
                                y: {
                                  display: false,
                                  beginAtZero: true,
                                  grid: {
                                    display: false
                                  }
                                },
                                x: {
                                  grid: {
                                    display: false
                                  },
                                  ticks: {
                                    font: {
                                      family: 'Pretendard',
                                      size: 10,
                                      weight: 700
                                    },
                                    color: '#000'
                                  }
                                }
                              },
                              datasets: {
                                bar: {
                                  barThickness: 20,
                                }
                              },
                              layout: {
                                padding: {
                                  top: 20
                                }
                              },
                              elements: {
                                bar: {
                                  borderRadius: 10
                                }
                              }
                            }}
                            plugins={[{
                              id: 'customDataLabels',
                              afterDatasetsDraw: (chart, args, options) => {
                                const ctx = chart.ctx;
                                chart.data.datasets.forEach((dataset, datasetIndex) => {
                                  const meta = chart.getDatasetMeta(datasetIndex);
                                  if (!meta.hidden) {
                                    meta.data.forEach((element, index) => {
                                      const value = dataset.data[index];
                                      const x = element.x;
                                      const y = element.y;
                                      ctx.fillStyle = '#000';
                                      ctx.font = '700 10px Pretendard';
                                      ctx.textAlign = 'center';
                                      ctx.textBaseline = 'bottom';
                                      ctx.fillText(value.toString(), x, y - 10);
                                    });
                                  }
                                });
                              }
                            }]}
                          />
                          {index < 4 && (
                            <div className="absolute top-0 right-0 bottom-0 w-px bg-[#E2E8EF]"></div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LearnOverview;