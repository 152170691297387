import React from "react";
import { useState, useEffect, useContext, useCallback } from "react";
import { LanguageContext } from "./LanguageContext";
import LanguageSwitcher from "./LanguageSwitcher";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setManageID, setType, setImageUrl } from "./actions";

const StoryBoardCreate = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const dispatch = useDispatch();
  const keywords = useSelector((state) => state.keywords);
  const lis = useSelector((state) => state.lis);
  const { translations, language } = useContext(LanguageContext);
  const [data, setData] = useState({});
  const [language2, setLanguage2] = useState("");
  const [toggleFilter, setToggleFilter] = useState(true);
  const [visibleItems, setVisibleItems] = useState(10);
  const [sortConfig, setSortConfig] = useState({
    key: "latest",
    direction: "ascending",
  });
  const [sortedLis, setSortedLis] = useState([]);

  // Shuffle function
  const shuffleArray = (array) => {
    const newArr = [...array]; // Create a copy to avoid mutating the original state
    for (let i = newArr.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [newArr[i], newArr[j]] = [newArr[j], newArr[i]]; // Swap elements
    }
    return newArr;
  };

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate("/storyboard");
  };

  const handleGoStudy = (item) => {
    dispatch(setManageID(item.id));
    dispatch(setImageUrl(item.imageurl));
    const fetchData = async () => {
      try {
        const languages = ["kor", "eng"];
        const difficulties = ["easyId", "normalId", "difficultId"];
        const fetchedLis = {};

        for (const language of languages) {
          fetchedLis[language] = {};
          const languageItem = item.customizes.find(
            (cust) => cust.language === language
          );

          if (languageItem) {
            for (const difficulty of difficulties) {
              const id = languageItem[difficulty];

              if (id) {
                const response = await fetch(
                  `${apiUrl}/customize_script/${id}`
                );
                const data = await response.json();

                if (response.ok) {
                  console.log(
                    `Fetched data for ${language} - ${difficulty}:`,
                    data
                  );
                  fetchedLis[language][difficulty] = data;
                }
              }
            }
          }
        }

        dispatch(setType("mbti"));
        navigate("/studyonboard", {
          state: { script: fetchedLis, item: item },
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  };

  const sortData = useCallback(
    (dataToSort) => {
      console.log("Sorting data:", dataToSort);
      const sortedData = [...dataToSort];
      if (sortConfig.key === "latest") {
        sortedData.sort((a, b) => {
          return sortConfig.direction === "ascending"
            ? b.id - a.id
            : a.id - b.id;
        });
      } else if (sortConfig.key === "alphabetical") {
        sortedData.sort((a, b) => {
          const aTitle =
            a.customizes.find((cust) => cust.language === language)?.title ||
            "";
          const bTitle =
            b.customizes.find((cust) => cust.language === language)?.title ||
            "";
          return sortConfig.direction === "ascending"
            ? aTitle.localeCompare(bTitle)
            : bTitle.localeCompare(aTitle);
        });
      } else if (sortConfig.key === "rating") {
        sortedData.sort((a, b) => {
          return sortConfig.direction === "ascending"
            ? a.averageRating - b.averageRating
            : b.averageRating - a.averageRating;
        });
      }
      console.log("Sorted data:", sortedData);
      return sortedData;
    },
    [sortConfig, language]
  );

  const handleSort = (key) => {
    console.log("Sorting by:", key);
    setSortConfig((prevConfig) => ({
      key,
      direction:
        prevConfig.key === key && prevConfig.direction === "ascending"
          ? "descending"
          : "ascending",
    }));
  };

  useEffect(() => {
    console.log("Current lis:", lis);
    const sortedData = sortData(lis);
    console.log("New sorted lis:", sortedData);
    const shuffled = shuffleArray(sortedData);
    setSortedLis(shuffled);
  }, [lis, sortConfig, sortData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiUrl}/tree-nodes/1`);
        const fetchedData = await response.json();
        setData(fetchedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [apiUrl]);

  const findItemById = (id, node) => {
    if (!node) return null;

    if (node.translations) {
      const foundTranslation = node.translations.find(
        (translation) => translation.id === id
      );
      if (foundTranslation) return foundTranslation;
    }

    if (node.children) {
      for (const child of node.children) {
        const result = findItemById(id, child);
        if (result) return result;
      }
    }

    return null;
  };

  const loadMoreItems = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 10);
  };

  return (
    <div className="flex min-h-screen justify-center w-full bg-[#faf8f6]">
      <div className="relative w-full max-w-[100%] bg-[#faf8f6]">
        <header className="flex items-center justify-between py-4 px-4">
          <div className="flex items-center">
            <img
              width="8"
              height="16"
              src="back.png"
              alt="Back"
              onClick={handleGoBack}
            />
          </div>
          <h1 className="absolute left-1/2 transform -translate-x-1/2 text-[16px] font-sans font-bold text-[#000] text-center">
            스토리보드 생성
          </h1>
          <LanguageSwitcher></LanguageSwitcher>
        </header>

        <div className="w-[95%] mx-auto">
          <div className="flex items-center justify-end gap-[5px] mb-[10px] mt-[10px]">
            <div
              className={`text-[10px] leading-[100%] font-sans font-medium ${
                sortConfig.key === "latest"
                  ? "text-[#3677f6]"
                  : "text-[#666b81]"
              } whitespace-nowrap cursor-pointer`}
              onClick={() => handleSort("latest")}
            >
              {translations.ORDER_DATE}{" "}
              {sortConfig.key === "latest" &&
                (sortConfig.direction === "ascending" ? "↑" : "↓")}
            </div>
            <div className="w-[2px] h-[2px] bg-[#666b81] rounded-full"></div>
            <div
              className={`text-[10px] leading-[100%] font-sans font-medium ${
                sortConfig.key === "alphabetical"
                  ? "text-[#3677f6]"
                  : "text-[#666b81]"
              } whitespace-nowrap cursor-pointer`}
              onClick={() => handleSort("alphabetical")}
            >
              {translations.ORDER_AL}{" "}
              {sortConfig.key === "alphabetical" &&
                (sortConfig.direction === "ascending" ? "↑" : "↓")}
            </div>
            <div className="w-[2px] h-[2px] bg-[#666b81] rounded-full"></div>
            <div
              className={`text-[10px] leading-[100%] font-sans font-medium ${
                sortConfig.key === "rating"
                  ? "text-[#3677f6]"
                  : "text-[#666b81]"
              } whitespace-nowrap cursor-pointer`}
              onClick={() => handleSort("rating")}
            >
              {translations.ORDER_RA}{" "}
              {sortConfig.key === "rating" &&
                (sortConfig.direction === "ascending" ? "↑" : "↓")}
            </div>
          </div>
        </div>

        <div className="w-full flex flex-col items-start bg-[#fff] overflow-y-auto">
          {sortedLis.slice(0, visibleItems).map((item, index) => {
            if (item.customizes && item.customizes.length > 1) {
              return (
                <div
                  key={index}
                  className="w-full flex flex-row items-start justify-between py-[14px] px-[16px] bg-[#fff] border border-[#e9ecef] border-b-0"
                  onClick={() => handleGoStudy(item)}
                >
                  <div className="flex flex-col gap-[17px]">
                    <div className="flex flex-col gap-[12px]">
                      <div className="flex items-center">
                        <div className="text-[14px] leading-[100%] font-sans font-bold text-[#333] break-all">
                          {item.customizes.find(
                            (customize) => customize.language === "eng"
                          )?.title || "No title available"}{" "}
                        </div>
                      </div>
                      <div className="text-[14px] leading-[100%] font-sans font-medium text-[#333] break-all">
                        {item.customizes.find(
                          (customize) => customize.language === "kor"
                        )?.title || "No title available"}{" "}
                      </div>
                    </div>

                    <div className="flex items-center gap-[3px]">
                      {item.tag &&
                        item.tag.map((tag, tagIndex) => (
                          <div
                            key={tagIndex}
                            className="flex items-center gap-[3px]"
                          >
                            <div className="text-[12px] leading-[100%] font-sans font-medium text-[#a9b6ca] whitespace-nowrap">
                              {data
                                ? findItemById(tag.id, data)?.name
                                : "Loading..."}
                            </div>
                            {tagIndex < item.tag.length - 1 && (
                              <div className="w-[2px] h-[2px] bg-[#a9b6ca] rounded-full"></div>
                            )}
                          </div>
                        ))}
                    </div>
                  </div>

                  <div className="flex items-center gap-[4px]">
                    <img
                      width="15"
                      height="15"
                      src={`star.png`}
                      alt="rating star"
                    />
                    <div className="text-[14px] leading-[100%] font-sans font-medium text-[#333] whitespace-nowrap">
                      {item.averageRating.toFixed(1)}{" "}
                    </div>
                  </div>
                </div>
              );
            }
          })}
          {visibleItems < sortedLis.length && (
            <div className="flex justify-center text-center py-[14px] bg-[#fff] border border-[#e9ecef] w-[100%]">
              <button
                onClick={loadMoreItems}
                className="px-4 py-2 bg-[#007bff] text-white rounded"
              >
                더보기
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default StoryBoardCreate;
