import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import parse from "html-react-parser"; // Import the html-react-parser
import { IoIosArrowBack } from "react-icons/io";

const MyPageNoticeContent = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Get the passed state (notice data)

  // Destructure the title and content from location.state
  const { title, content } = location.state || { title: "", content: "" };

  const handleGoBack = () => {
    navigate(-1);
  };

  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("theme") === "dark"
  );

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add("dark");
      localStorage.setItem("theme", "dark");
    } else {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("theme", "light");
    }
  }, [darkMode]);

  return (
    <div className="relative min-h-screen flex flex-col bg-[#fff] dark:bg-[#121418] overflow-hidden">
      <div className="w-[100%] h-[2vh] bg-[#faf8f6] dark:bg-[#121418]"></div>
      {/* Header */}
      <div className="flex items-center justify-between h-[50px] bg-[#faf8f6] dark:bg-[#121418]">
        <div
          className="w-[36px] h-[36px] flex items-center justify-center"
          onClick={handleGoBack}
        >
          <IoIosArrowBack color={darkMode ? "white" : "black"} size={30} />
        </div>
        <div className="flex items-center justify-center p-[10px] font-sans font-bold dark:text-[#fff]">
          공지사항
        </div>
        <div className="w-[36px] h-[36px] flex items-center justify-center p-[10px] opacity-0">
          <img width="10" height="16" src="back.png" alt="icon" />
        </div>
      </div>
      {/* Content */}
      <div className="relative w-[100%] min-h-screen bg-[#faf8f6] dark:bg-[#121418] overflow-hidden">
        <div className="absolute left-0 w-[100%] min-h-screen flex flex-col items-start justify-start gap-[20px] px-[16px] py-[14px] bg-[#fff] dark:bg-[#121418]">
          <div className="self-stretch flex flex-row items-center justify-start pt-0 px-0 pb-[20px] border-[solid] border-#e9ecef dark:border-[#464a5a] border-b border-[0_0_1px]">
            <div className="text-[16px] leading-[100%] font-sans font-bold text-[#000] dark:text-[#fff] text-justify whitespace-nowrap">
              {title || "공지사항 제목이 없습니다"}
            </div>
          </div>
          {/* Render the parsed HTML content with Tailwind's prose class for styling */}
          <div className="prose prose-lg max-w-none w-full dark:text-[#fff]">
            {parse(content || "공지사항 내용이 없습니다")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyPageNoticeContent;
