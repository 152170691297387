import { useState, useEffect } from "react";
import OptionalTextField from "../logincomponents/OptionalTextField";
import BBtn from "../logincomponents/BBtn";
import PhoneVerification from "../logincomponents/PhoneVerification";
import PWChange from "../logincomponents/PWChange";
import PHChange from "../logincomponents/PHChange";
import ReactiveOuter from "../logincomponents/ReactiveOuter";
import FormTop from "../logincomponents/FormTop";
import Title from "../logincomponents/Title";
import FormForm from "../logincomponents/FormForm";
import MustTextField from "../logincomponents/MustTextField";
import { useNavigate } from "react-router-dom";
import BBtn2 from "../logincomponents/BBtn2";
import { useSelector } from "react-redux";
import { IoIosArrowBack } from "react-icons/io";

const RegisterSetting = () => {
  const navigate = useNavigate();
  const accessToken = useSelector((state) => state.userId);
  const apiUrl = process.env.REACT_APP_API_URL;
  const userInfo = useSelector((state) => state.userInfo);
  // Create a state for nickname that can be updated
  const [nickname, setNickname] = useState(userInfo.nickname);
  console.log(userInfo);
  const handleGoBack = () => {
    navigate(-1);
  };
  const handleStart = () => {
    navigate("/accountinfo");
  };

  const deleteAccount = async (accessToken) => {
    try {
      const response = await fetch(`${apiUrl}/auth/delete`, {
        method: "DELETE", // Use DELETE method
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`, // Include the access token
        },
        // You can include a body if required by the API, but often DELETE requests don't have a body
        // body: JSON.stringify({ someData: "value" }),
      });

      if (response.ok) {
        navigate("/welcome");
        // const result = await response.json();
        // console.log("Account deleted successfully:", result);
        // Handle successful deletion, e.g., redirect or show a message
      } else {
        console.error("Failed to delete account:", response.statusText);
        // Handle error response
      }
    } catch (error) {
      console.error("Error during the request:", error);
      // Handle request error
    }
  };

  async function refreshAccessToken() {
    console.log(userInfo);
    try {
      const response = await fetch(`${apiUrl}/auth/refresh-token`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userid: userInfo.userid, // Pass userId or other required params
          refreshToken: localStorage.getItem("refreshToken"),
        }),
      });

      if (response.ok) {
        const tokenData = await response.json();
        return tokenData; // Contains new accessToken and refreshToken
      } else {
        console.error("Failed to refresh token:", response.statusText);
        return null;
      }
    } catch (error) {
      console.error("Error refreshing access token:", error);
      return null;
    }
  }

  const updateProfile = async () => {
    console.log("유저인포야~~ ", userInfo);
    const data = {
      userid: userInfo.userid,
      nickname: nickname,
      nativeLanguage: userInfo.nativeLanguage,
      targetLanguage: userInfo.targetLanguage,
      mbti: userInfo.mbti,
      sex: userInfo.sex,
      age: userInfo.age,
      job: userInfo.job,
      love: userInfo.love,
    };

    try {
      const response = await fetch(`${apiUrl}/auth/complete-onboarding`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          // Add any other headers you need, such as authentication tokens
        },
        body: JSON.stringify(data), // Convert the data to JSON
      });

      if (response.ok) {
        const result = await response.json();
        console.log("Onboarding updated successfully:", result);

        const refreshedTokenData = await refreshAccessToken();
        if (refreshedTokenData) {
          console.log("Token refreshed successfully:", refreshedTokenData);

          // Save the new tokens in sessionStorage
          sessionStorage.setItem("accessToken", refreshedTokenData.accessToken);
          sessionStorage.setItem(
            "refreshToken",
            refreshedTokenData.refreshToken
          );

          // Navigate to home after successful profile update and token refresh
          navigate("/home");
        } else {
          console.error("Failed to refresh token.");
          alert("Failed to refresh token.");
        }
      } else {
        console.error("Failed to update profile:", response.statusText);
        // alert("Failed to update profile.");
      }
    } catch (error) {
      console.error("Error during the request:", error);
      alert("An error occurred while updating the profile.");
    }
  };

  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("theme") === "dark"
  );

  return (
    <div className="flex min-h-screen justify-center w-full bg-[#faf8f6] dark:bg-[#121418]">
      <div className="relative min-x-[360px] w-full bg-[#faf8f6] dark:bg-[#121418]">
        <div className="w-[100%] h-[2vh] bg-[#faf8f6] dark:bg-[#121418]"></div>
        {/* Header */}
        <div className="flex items-center justify-between h-[50px] bg-[#faf8f6] dark:bg-[#121418]">
          <div
            className="w-[36px] h-[36px] flex items-center justify-center"
            onClick={handleGoBack}
          >
            <IoIosArrowBack color={darkMode ? "white" : "black"} size={30} />
          </div>
          <div className=" flex items-center justify-center p-[10px] font-sans font-bold dark:text-[#fff]">
            계정 정보
          </div>
          <div className="w-[36px] h-[36px] flex items-center justify-center p-[10px] opacity-0">
            <img width="10" height="16" src="back.png" alt="icon" />
          </div>
        </div>
        <ReactiveOuter darkMode={darkMode}>
          <div
            className={`h-[2vh] w-full ${darkMode ? "bg-[#121418]" : ""} `}
          ></div>
          <FormTop darkMode={darkMode}>
            <FormForm darkMode={darkMode}>
              <OptionalTextField
                prop="닉네임"
                prop1=""
                value={nickname}
                onChange={(e) => setNickname(e.target.value)}
                darkMode={darkMode}
                disabled={false}
              ></OptionalTextField>
              <OptionalTextField
                prop="아이디"
                prop1=""
                value={userInfo.userid}
                darkMode={darkMode}
                disabled={true}
              ></OptionalTextField>
              {userInfo.loginType === "local" ? (
                <PWChange
                  disabled={true}
                  value="********"
                  darkMode={darkMode}
                ></PWChange>
              ) : null}
              <PHChange
                disabled={true}
                value={userInfo.phoneNumber.replace("+82", "0")}
                darkMode={darkMode}
              ></PHChange>
              <OptionalTextField
                prop="프로모션코드"
                prop1=""
                value={userInfo.recommender}
                darkMode={darkMode}
                disabled={true}
              ></OptionalTextField>
              <OptionalTextField
                prop="연결"
                prop1=""
                value={userInfo.loginType}
                darkMode={darkMode}
                disabled={true}
              ></OptionalTextField>
            </FormForm>
            <div className="bottom-[5vh] w-full mt-[5vh]">
              <BBtn prop={"저장하기"} onClick={updateProfile} />
              <div className="h-[10px]"></div>
              <BBtn2
                prop={"회원탈퇴"}
                onClick={() => {
                  deleteAccount(accessToken);
                }}
              />
            </div>
          </FormTop>
        </ReactiveOuter>
      </div>
    </div>
  );
};

export default RegisterSetting;
