import React, { useEffect, useState } from "react";
import Logo from "../logincomponents/Logo";
import LoginOptions from "../logincomponents/LoginOptions";
import styles from "./Welcome.module.css";

const Welcome = () => {
  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("theme") === "dark"
  );

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add("dark");
      localStorage.setItem("theme", "dark");
    } else {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("theme", "light");
    }
  }, [darkMode]);

  return (
    <div className={`min-h-screen ${darkMode ? "bg-[#121418]" : "bg-white"}`}>
      <div className={`${styles.div} dark:bg-[#121418]`}>
        <Logo />
        <div className="fixed bottom-0 w-full dark:bg-[#121418]">
          <LoginOptions />
        </div>
      </div>
    </div>
  );
};

export default Welcome;
