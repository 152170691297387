import TextField from "../logincomponents/PhoneVerification";
import Title from "../logincomponents/Title";
import BBtn from "../logincomponents/BBtn";
import OptionalTextField from "../logincomponents/OptionalTextField";
import ReactiveOuter from "../logincomponents/ReactiveOuter";
import FormTop from "../logincomponents/FormTop";
import FormForm from "../logincomponents/FormForm";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const ID = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(""); // 초기값을 빈 문자열로 설정
  const [userId, setUserId] = useState(""); // userId 상태 추가
  const [error, setError] = useState(""); // error 상태 추가
  const navigate = useNavigate();

  const handlePhoneNumberChange = (value) => {
    setPhoneNumber(value);
  };

  const handleSubmit = async (e) => {
    if (e) e.preventDefault();
    if (!name || !phoneNumber) {
      setError("이름과 전화번호를 모두 입력해주세요.");
      return;
    }

    // 전화번호에서 하이픈 제거 및 형식 변경
    let formattedPhoneNumber = phoneNumber.replace(/-/g, '');
    if (formattedPhoneNumber.startsWith('010')) {
      formattedPhoneNumber = '+82' + formattedPhoneNumber.slice(1);
    }

    try {
      console.log('API URL:', `${process.env.REACT_APP_API_URL}/auth/find-userid`);
      console.log('요청 데이터:', { name, phoneNumber: formattedPhoneNumber });

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/find-userid`, {
        name,
        phoneNumber: formattedPhoneNumber,
      });

      console.log('응답 데이터:', response.data);
      setUserId(response.data.userid);
      setError("");
      navigate('/lost-id2', { state: { userId: response.data.userid, phoneNumber: formattedPhoneNumber } });
    } catch (err) {
      console.error('API 오류:', err);
      setError("아이디를 찾을 수 없습니다. 다시 시도해주세요.");
      setUserId("");
    }
  };

  return (
    <ReactiveOuter>
      <FormTop>
        <Title
          titleGap="78px"
          frameDivWidth="unset"
          prop="아이디 찾기"
          bAlignSelf="unset"
          bHeight="unset"
          bOpacity="unset"
          bMinWidth="101px"
          bDisplay="inline-block"
        />
        <FormForm onSubmit={handleSubmit}>
          <OptionalTextField
            prop="이름"
            prop1="이름을 입력하세요"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            phoneNumber={phoneNumber} // phoneNumber prop 추가
            onPhoneNumberChange={handlePhoneNumberChange}
          />
          {error && <p style={{ color: "red" }}>{error}</p>}
        </FormForm>
      </FormTop>
      <div className="fixed bottom-[5vh] w-full">
        <BBtn prop={"다음"} onClick={handleSubmit}/>
      </div>
    </ReactiveOuter>
  );
};

export default ID;
