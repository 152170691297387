import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LanguageContext } from "../LanguageContext";

const GnbMyPage = () => {
  const navigate = useNavigate();

  // Handler functions for navigation
  const goToHome = () => navigate("/home");
  const goToStudy = () => navigate("/study");
  const goToExpress = () => navigate("/express");
  const goToAI = () => navigate("/ai");
  const goToMyPage = () => navigate("/mypage");
  const { translations } = useContext(LanguageContext);

  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("theme") === "dark"
  );

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add("dark");
      localStorage.setItem("theme", "dark");
    } else {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("theme", "light");
    }
  }, [darkMode]);

  return (
    <div className="fixed bottom-0 left-0 w-full bg-[#fff] dark:bg-[#31333e] border-none z-50">
      <div
        className="flex flex-row items-start justify-between pt-[12px] px-[20px] pb-[16px]"
        style={{ boxShadow: "0 -2px 6px rgba(0, 0, 0, 0.1)" }}
      >
        <button
          className="flex-1 flex flex-col items-center justify-center gap-[6px] bg-transparent border-none"
          onClick={goToHome}
        >
          <img width="24" height="24" src="homeI12_83;216_3180.png" alt="홈" />
          <div className="text-[10px] leading-[100%] font-sans text-[#666b81] text-center whitespace-nowrap">
            {translations.HOME2}
          </div>
        </button>
        <button
          className="flex-1 flex flex-col items-center justify-center gap-[6px] bg-transparent border-none"
          onClick={goToStudy}
        >
          <img
            width="24"
            height="24"
            src="pencilI12_192;216_3182.png"
            alt="학습하기"
          />
          <div className="text-[10px] leading-[100%] font-sans text-[#666b81] text-center whitespace-nowrap">
            {translations.STUDY}
          </div>
        </button>
        <button
          className="flex-1 flex flex-col items-center justify-center gap-[6px] bg-transparent border-none"
          onClick={goToExpress}
        >
          <img
            width="24"
            height="24"
            src="expressionI12_192;216_3184.png"
            alt="표현하기"
          />
          <div className="text-[10px] leading-[100%] font-sans text-[#666b81] text-center whitespace-nowrap">
            {translations.EXPRESSING}
          </div>
        </button>
        <button
          className="flex-1 flex flex-col items-center justify-center gap-[6px] bg-transparent border-none"
          onClick={goToAI}
        >
          <div className="relative w-[24px] h-[24px] shrink-0">
            <img
              className="absolute left-[1px] top-[2px]"
              width="22"
              height="20"
              src="navi_ai.png"
              alt="AI 맞춤학습"
            />
          </div>
          <div className="text-[10px] leading-[100%] font-sans text-[#666b81] text-center whitespace-nowrap">
            {translations.AI_CUSTOMIZED_LESSON}
          </div>
        </button>
        <button
          className="flex-1 flex flex-col items-center justify-center gap-[6px] bg-transparent border-none"
          onClick={goToMyPage}
        >
          <div className="relative w-[24px] h-[24px] shrink-0">
            <img
              className="absolute left-[1px] top-[1px]"
              width="24"
              height="24"
              src="userI22_167;216_3194.png"
              alt="마이페이지"
            />
          </div>
          <div className="text-[10px] leading-[100%] font-sans text-[#666b81] text-center whitespace-nowrap">
            {translations.MY_PAGE}
          </div>
        </button>
      </div>
    </div>
  );
};

export default GnbMyPage;
