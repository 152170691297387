import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";

const MyPageFaq = () => {
  // State to manage which accordion is open
  const [openIndex, setOpenIndex] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState("전체");

  // Function to handle accordion click
  const handleAccordionClick = (index) => {
    setOpenIndex(openIndex === index ? null : index); // Toggle accordion
  };

  // FAQ content
  const faqContent = `FAQ 내용이 들어가는 부분입니다. FAQ 내용이 들어가는 부분입니다.
    FAQ 내용이 들어가는 부분입니다. FAQ 내용이 들어가는 부분입니다.
    FAQ 내용이 들어가는 부분입니다. <br />
    FAQ 내용이 들어가는 부분입니다. FAQ 내용이 들어가는 부분입니다.
    FAQ 내용이 들어가는 부분입니다. FAQ 내용이 들어가는 부분입니다.
    FAQ 내용이 들어가는 부분입니다.`;

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleFilterClick = (filter) => {
    setSelectedFilter(filter);
  };

  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("theme") === "dark"
  );

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add("dark");
      localStorage.setItem("theme", "dark");
    } else {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("theme", "light");
    }
  }, [darkMode]);
  return (
    <div className="relative min-h-screen flex flex-col bg-[#fff] dark:bg-[#121418] overflow-hidden">
      <div className="w-[100%] h-[2vh] bg-[#faf8f6] dark:bg-[#121418]"></div>
      {/* Header */}
      <div className="flex flex-col items-center justify-between h-auto bg-[#faf8f6] dark:bg-[#121418]">
        <div className="flex items-center justify-between w-full h-[50px]">
          <div
            className="w-[36px] h-[36px] flex items-center justify-center"
            onClick={handleGoBack}
          >
            <IoIosArrowBack color={darkMode ? "white" : "black"} size={30} />
          </div>
          <div className="flex items-center justify-center p-[10px] font-sans font-bold dark:text-[#fff]">
            FAQ
          </div>
          <div className="w-[36px] h-[36px] flex items-center justify-center p-[10px] opacity-0">
            <img width="10" height="16" src="back.png" alt="icon" />
          </div>
        </div>

        {/* New Row Starts Here */}
        <div className="flex flex-wrap items-center justify-center gap-[6px] w-full pb-[16px] px-[16px] ">
          {["전체", "일반", "결제", "컨텐츠", "분류"].map((filter) => (
            <div
              key={filter}
              className={`flex flex-row items-center justify-center py-[8px] px-[14px] rounded-[59px] 
              ${
                selectedFilter === filter
                  ? darkMode
                    ? "bg-[#fff] border-[#fff] text-[#000]"
                    : "bg-[#e3edff] border-[#3677f6] text-[#3677f6]"
                  : darkMode
                  ? " border-[#e9ecef] text-[#fff]"
                  : " border-[#e9ecef] text-[#000]"
              }`}
              onClick={() => handleFilterClick(filter)}
            >
              <div
                className={`text-[14px] leading-[100%] font-['Pretendard'] font-medium  ${
                  selectedFilter === filter
                    ? "text-[#3677f6]"
                    : darkMode
                    ? "text-[#fff]"
                    : "text-[#000]"
                } whitespace-nowrap`}
              >
                {filter}
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* content */}
      <div className="absolute left-0 top-[125px] w-[100%] flex flex-col items-start justify-start gap-[16px]">
        <div className="w-[100%] flex flex-col items-start justify-start bg-[#fff] dark:bg-[#22232a]">
          {/* Repeat this block for each FAQ item */}
          {[1, 2, 3, 4, 5].map((item, index) => (
            <div key={index} className="self-stretch">
              {/* Accordion Header */}
              <div
                className="h-[54px] shrink-0 flex flex-row items-center justify-between py-[10px] px-[20px] cursor-pointer border-[solid] border-[#e9ecef] dark:border-[#464a5a] border-b-[1px]"
                onClick={() => handleAccordionClick(index)}
              >
                <div className="text-[16px] leading-[100%] font-['Pretendard'] font-bold text-[#000] dark:text-[#fff] whitespace-nowrap">
                  FAQ 내용이 들어갑니다
                </div>
                <img
                  width="30"
                  height="30"
                  src={"open.png"}
                  alt="toggle icon"
                  className={`transition-transform duration-300 ${
                    openIndex === index ? "rotate-180" : "rotate-0"
                  } ${darkMode ? "filter invert" : ""}`}
                />
              </div>

              {/* Accordion Content */}
              {openIndex === index && (
                <div className="self-stretch flex flex-row items-center justify-start pt-[10px] px-[20px] pb-[20px] border-[solid] border-[#e9ecef] border-t-0">
                  <div className="flex-1 text-[14px] leading-[22px] font-['Pretendard'] font-medium text-[#383c4b] dark:text-[#a9b6ca]">
                    {faqContent}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MyPageFaq;
