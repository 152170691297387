import bgImage1 from "./default1.webp";
import bgImage2 from "./default2.webp";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect, useContext, useRef } from "react";
import { LanguageContext } from "./LanguageContext";
import LanguageSwitcher2 from "./LanguageSwitcher2";
import StudyQuitModal from "./StudyQuitModal";
import { useDispatch, useSelector } from "react-redux";
import { useDifficulty } from "./DifficultyContext";
import UseSwipe from "./UseSwipe"; // Adjust the path as necessary

// Inside the Conversation component

const StudyOnboard = () => {
  const location = useLocation();
  const { script, id } = location.state || {};
  console.log(script.script);
  const { translations, language } = useContext(LanguageContext);
  const navigate = useNavigate();
  const type = useSelector((state) => state.type);
  const imageUrl = useSelector((state) => state.imageUrl);
  const where = useSelector((state) => state.where);
  const handleSwipeLeft = () => {
    handleNextClick();
  };

  const handleSwipeRight = () => {
    handleGoStudy();
  };

  UseSwipe(handleSwipeLeft, handleSwipeRight);
  let imageUrl2;
  console.log("여기 값입니다 ~~  ", where);
  if (!imageUrl.startsWith("/")) {
    imageUrl2 = "/" + imageUrl;
  } else {
    imageUrl2 = imageUrl;
  }
  const { difficulty, setDifficulty } = useDifficulty();

  const handleLink = () => {
    window.location.href =
      "https://www.google.com/search?q=%EC%9D%B4%EC%A7%80%EB%B8%8C%EB%A0%88%EC%9D%B8%EC%86%8C%ED%94%84%ED%8A%B8";
  };

  const handleNextClick = () => {
    navigate("/conversation", {
      state: { script: script, id: id },
    });
  };
  const handleGoBack = () => {
    navigate(-1);
  };
  const handleGoStudy = () => {
    if (where === "study") {
      navigate(`/${type}studylist`);
    } else {
      navigate(`/bookmark`);
    }
  };

  const ProgressBar = ({ filledSections }) => {
    const totalSections = 6;
    const gapWidth = 4;

    return (
      <div className="w-full h-1 flex z-40">
        {Array.from({ length: totalSections }).map((_, index) => (
          <div
            key={index}
            className={`h-full ${
              index < filledSections ? "bg-blue-500" : "bg-gray-300"
            }`}
            style={{
              flex: `1 0 calc(100% / ${totalSections})`,
              marginRight: index < totalSections - 1 ? `${gapWidth}px` : "0",
            }}
          />
        ))}
      </div>
    );
  };
  const [isOverlayVisible, setOverlayVisible] = useState(false);
  const overlayRef = useRef(null); // Ref for the overlay

  const toggleOverlay = () => {
    if (isOverlayVisible === false) {
      setOverlayVisible(!isOverlayVisible);
    } else {
      setOverlayVisible(!isOverlayVisible);
    }
  };

  const handleClickOutside = (event) => {
    if (overlayRef.current && !overlayRef.current.contains(event.target)) {
      setOverlayVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [isModalVisible, setModalVisible] = useState(false);

  const toggleModal = () => {
    setModalVisible(!isModalVisible);
  };

  const handleModalAction1 = () => {
    // Define the action for the first button here
    setModalVisible(false); // Hide the modal after action
  };

  const [backgroundImageUrl, setBackgroundImageUrl] = useState(""); // State to store the background image URL

  // Function to check if the image exists
  const checkImageExists = (url, callback) => {
    const img = new Image();
    img.onload = () => callback(true);
    img.onerror = () => callback(false);
    img.src = url;
  };

  useEffect(() => {
    const url = `https://pastellapp.com/api/upload${imageUrl2}`;
    console.log("여기  ~~~   ", url);
    const fallbackUrl = "https://pastellapp.com/api/upload/img/coolbg.jpg"; // Your fallback image URL

    checkImageExists(url, (exists) => {
      if (exists) {
        setBackgroundImageUrl(url);
      } else {
        const randomBgImage = Math.random() < 0.5 ? bgImage1 : bgImage2;
        setBackgroundImageUrl(randomBgImage);
      }
    });
  }, [imageUrl2]); // Only re-run the effect if `imageUrl` changes

  return (
    <div
      id="swipe-container"
      className="relative flex flex-col bg-[#fff] overflow-hidden"
      style={{
        backgroundImage: `url(${backgroundImageUrl})`,
        backgroundSize: "cover",
        backgroundPosition: "bottom",
        backgroundRepeat: "no-repeat",
        height: "100vh", // Ensure full viewport height
        width: "100vw", // Ensure full viewport width
        overflow: "hidden", // Prevent scrolling
      }}
    >
      {isOverlayVisible && (
        <div className="absolute w-full h-full bg-black opacity-50 z-50"></div>
      )}
      <div className="absolute w-full h-full bg-black opacity-70 z-10"></div>
      {/* Header */}
      <div className="relative flex items-center px-[16px] h-[50px]">
        <div className="absolute left-0 top-1/2 transform -translate-y-1/2 w-[60px] h-[60px] p-[10px] z-40">
          <img
            width="60"
            height="60"
            src="x.png"
            alt="icon"
            onClick={toggleModal}
          />
        </div>
        <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 p-[10px] z-40 text-white font-sans text-center">
          {translations.so1}
        </div>
        <div
          className="absolute top-1/2 transform -translate-y-1/2 w-[45px] h-[45px] p-[10px] z-40"
          style={{ left: "78%" }}
        >
          <img
            width="40"
            height="40"
            src="link.png"
            alt="icon"
            onClick={handleLink}
          />
        </div>
        <div
          className="absolute top-1/2 transform -translate-y-1/2 w-[45px] h-[45px] p-[10px] z-40"
          style={{ left: "88%" }}
        >
          <img
            width="45"
            height="45"
            src="ddd.png"
            alt="icon"
            onClick={toggleOverlay}
          />
        </div>
      </div>
      {/* Progress Bar */}
      <ProgressBar filledSections={0} />
      {/* Content */}
      <div className="flex justify-end flex-1 mr-[10px] mt-[10px] z-50">
        <LanguageSwitcher2></LanguageSwitcher2>
      </div>
      <div className="flex mb-[50%] justify-center flex-1">
        <div className="text-white font-sans z-40 text-center">
          <div className="mb-[20px] w-[80%] mx-auto break-words">
            {script[language][difficulty].background}
          </div>
          <div>{script[language][difficulty].humanA}</div>
          <div>{script[language][difficulty].humanB}</div>
          {/* <div>아들: 현우</div> */}
          {/* <div>딸: 예진</div> */}
        </div>
      </div>
      {/* Footer */}
      <div className="fixed bottom-0 w-full pb-[20px] px-[16px] z-40">
        <button
          className="w-full h-[54px] flex items-center justify-center py-[10px] px-[20px] bg-[linear-gradient(64.95deg,#61e3eb_0%,#3677f6_100%)] rounded-[12px] text-[#fff] text-[14px] leading-[100%] font-sans font-bold"
          onClick={handleNextClick}
        >
          학습 시작하기
        </button>
      </div>
      {/* Overlay */}
      <div
        ref={overlayRef}
        className={`fixed bottom-0 left-0 w-full bg-white transition-transform duration-300 ease-in-out ${
          isOverlayVisible
            ? "transform translate-y-0"
            : "transform translate-y-full"
        }`}
        style={{
          height: "20%",
          zIndex: 100,
          borderTopLeftRadius: "30px", // Adjust the radius value as needed
          borderTopRightRadius: "30px", // Adjust the radius value as needed
        }}
      >
        <div className="flex flex-col h-full">
          <div
            className="flex flex-wrap justify-center items-center flex-grow font-sans font-bold"
            onClick={() => navigate("/langsetting")}
          >
            언어 설정
          </div>
          <div className="w-full border-t border-[#e9ecef]"></div>
          <div
            className="flex flex-wrap justify-center items-center flex-grow font-sans font-bold"
            onClick={() => navigate("/error-action")}
          >
            오류 신고
          </div>
        </div>
      </div>
      <StudyQuitModal
        isVisible={isModalVisible}
        onClose={() => setModalVisible(false)}
        onAction1={handleModalAction1}
        onAction2={handleGoStudy}
      />
    </div>
  );
};

export default StudyOnboard;
