import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Navigation from '../../components/admin/Navigation';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import calendarIcon from '../../assets/admin/icons/calendar_black.png';
import { ChevronDownIcon } from '@heroicons/react/solid';
import handleSortIcon from '../../assets/admin/icons/handleSortIcon.png';
import leftCursor from '../../assets/admin/icons/leftCursor.png';
import rightCursor from '../../assets/admin/icons/rightCursor.png';
import ReactPaginate from 'react-paginate';
import axios from 'axios';
import API_BASE_URL from '../../config';
import axiosInstance from '../../utils/admin/axiosConfig';

const MemberEditor = () => {
  const { userid } = useParams();
  const navigate = useNavigate();

  const [memberData, setMemberData] = useState({
    id: '',
    username: '',
    userid: '',
    recommender: '',
    marketingConsent: false,
    phoneNumber: '',
    nickname: '',
    nativeLanguage: '',
    targetLanguage: '',
    mbti: '',
    sex: '',
    age: '',
    job: '',
    love: '',
    attendanceCount: 0,
    lastLogin: '',
    deletedAt: null,
    loginType: '',
    userType: '',
    paymentUsed: false
  });

  const [paymentHistory, setPaymentHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectOptions, setSelectOptions] = useState({});

  useEffect(() => {
    fetchMemberData();
    fetchSelectOptions();
  }, [userid]);

  const fetchSelectOptions = async () => {
    try {
      const response = await fetch('/select_kor_eng.json');
      const data = await response.json();
      setSelectOptions(data);
    } catch (error) {
      console.error('선택 옵션 불러오기 실패:', error);
    }
  };

  const fetchMemberData = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${API_BASE_URL}/auth/user/${userid}`);
      const userData = response.data;
      setMemberData(userData);
      setPaymentHistory(userData.payments || []);
      setIsLoading(false);
    } catch (error) {
      console.error('회원 정보 불러오기 실패:', error);
      setError('회원 정보를 불러오는데 실패했습니다.');
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setMemberData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleDateChange = (date, field) => {
    setMemberData(prevData => ({
      ...prevData,
      [field]: date
    }));
  };

  const handleSelectChange = (name, value) => {
    setMemberData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSave = async () => {
    try {
      const dataToUpdate = {
        username: memberData.username,
        nickname: memberData.nickname,
        phoneNumber: memberData.phoneNumber,
        nativeLanguage: memberData.nativeLanguage,
        targetLanguage: memberData.targetLanguage,
        marketingConsent: memberData.marketingConsent,
        recommender: memberData.recommender,
        paymentUsed: memberData.paymentUsed,
        mbti: memberData.mbti,
        sex: memberData.sex,
        age: memberData.age,
        job: memberData.job,
        love: memberData.love
      };

      await axiosInstance.put(`/auth/user/${userid}`, dataToUpdate);
      alert('회원 정보가 성공적으로 업데이트되었습니다.');
    } catch (error) {
      console.error('회원 정보 업데이트 실패:', error);
      alert('회원 정보 업데이트에 실패했습니다.');
    }
  };

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;
  const totalItems = paymentHistory.length;

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  if (isLoading) return <div>로딩 중...</div>;
  if (error) return <div>{error}</div>;
  if (!selectOptions || Object.keys(selectOptions).length === 0) return <div>옵션을 불러오는 중...</div>;

  return (
    <div className="min-h-screen bg-[#FAF8F6] p-6">
      <Navigation />
      
      <div className="flex justify-between items-center mb-5">
        <h1 className="text-2xl font-bold text-left" style={{fontFamily: 'Pretendard', fontSize: '24px', fontWeight: 700}}>
          회원상세
        </h1>
        <div className="flex justify-end space-x-4">
          <button
            onClick={() => navigate('/member')}
            className="w-[100px] h-[48px] bg-[#666B81] text-white"
            style={{fontFamily: 'Pretendard', fontSize: '14px', fontWeight: 700, borderRadius: '12px'}}
          >
            목록가기
          </button>
          <button
            className="w-[100px] h-[48px] bg-[#C5D0DD] text-[#383C4B]"
            style={{fontFamily: 'Pretendard', fontSize: '14px', fontWeight: 700, borderRadius: '12px'}}
          >
            삭제하기
          </button>
          <button
            className="w-[100px] h-[48px] bg-[#3677F6] text-white"
            style={{fontFamily: 'Pretendard', fontSize: '14px', fontWeight: 700, borderRadius: '12px'}}
            onClick={handleSave}
          >
            저장하기
          </button>
        </div>
      </div>

      <div className="bg-white rounded-lg p-6 shadow-md mb-10">
        <div className="grid grid-cols-3 gap-8">
          <div>
            <h2 className="text-xl mb-4" style={{fontFamily: 'Pretendard', fontSize: '18px', fontWeight: 700, color: '#0E111F'}}>
              기본정보
            </h2>
            <div className="grid grid-cols-2 gap-4">
              <ReadOnlyField label="아이디" value={memberData.userid} />
              <ReadOnlyField label="회원 구분" value={memberData.loginType || '일반'} />
            </div>
            <div className="grid grid-cols-2 gap-4">
              <ReadOnlyField label="비밀번호" name="password" value="******" onChange={handleInputChange} type="password" />
              <ReadOnlyField label="비밀번호 변경 확인" name="passwordConfirm" value="******" onChange={handleInputChange} type="password" />
            </div>
            <div className="grid grid-cols-3 gap-4">
              <ReadOnlyField label="닉네임" value={memberData.nickname || '-'} />
              <ReadOnlyField label="휴대폰 번호" value={memberData.phoneNumber || '-'} />
              <SelectField 
                label="마케팅 동의 여부" 
                name="marketingConsent" 
                value={memberData.marketingConsent}
                options={{'동의': true, '미동의': false}}
                onChange={(value) => handleSelectChange('marketingConsent', value)}
              />
            </div>
            <div className="grid grid-cols-3 gap-4">
              <ReadOnlyField label="가입일시" value={new Date(memberData.createdAt).toLocaleString()} />
              <ReadOnlyField label="최근 접속일시" value={new Date(memberData.lastLogin).toLocaleString()} />
              <ReadOnlyField label="출석일수" value={memberData.attendanceCount || '0'} />
            </div>
            <div className="grid grid-cols-3 gap-4">
              <ReadOnlyField label="추천인 코드" value={memberData.recommender || '-'} />
              <SelectField 
                label="추천 혜택수령 여부" 
                name="recommendBenefitReceived" 
                value={memberData.recommendBenefitReceived ?? false}
                options={{'수령': true, '미수령': false}}
                onChange={(value) => handleSelectChange('recommendBenefitReceived', value)}
              />
              <ReadOnlyField 
                label="유료회원 여부" 
                value={memberData.paymentUsed ? '유료회원' : '무료회원'} 
              />
            </div>
          </div>
          
          <div>
            <h2 className="text-xl mb-4" style={{fontFamily: 'Pretendard', fontSize: '18px', fontWeight: 700, color: '#0E111F'}}>
              프로필
            </h2>
            <SelectField 
              label="MBTI" 
              name="mbti" 
              value={memberData.mbti} 
              options={selectOptions.mbti || {}}
              onChange={(value) => handleSelectChange('mbti', value)}
            />
            <SelectField 
              label="성별" 
              name="sex" 
              value={memberData.sex} 
              options={selectOptions.sex || {}}
              onChange={(value) => handleSelectChange('sex', value)}
            />
            <SelectField 
              label="나이" 
              name="age" 
              value={memberData.age} 
              options={selectOptions.age || {}}
              onChange={(value) => handleSelectChange('age', value)}
            />
            <SelectField 
              label="직업" 
              name="job" 
              value={memberData.job} 
              options={selectOptions.job || {}}
              onChange={(value) => handleSelectChange('job', value)}
            />
            <SelectField 
              label="연애상태" 
              name="love" 
              value={memberData.love} 
              options={selectOptions.love || {}}
              onChange={(value) => handleSelectChange('love', value)}
            />
          </div>
          
          <div>
            <h2 className="text-xl mb-4" style={{fontFamily: 'Pretendard', fontSize: '18px', fontWeight: 700, color: '#0E111F'}}>
              언어
            </h2>
            <SelectField 
              label="NATIVE" 
              name="nativeLanguage" 
              value={memberData.nativeLanguage} 
              options={selectOptions.language || {}}
              onChange={(value) => handleSelectChange('nativeLanguage', value)}
            />
            <SelectField 
              label="TARGET" 
              name="targetLanguage" 
              value={memberData.targetLanguage} 
              options={selectOptions.language || {}}
              onChange={(value) => handleSelectChange('targetLanguage', value)}
            />
          </div>
        </div>
      </div>

      <div className="bg-white rounded-lg shadow-md">
        <h2 className="text-xl p-6" style={{fontFamily: 'Pretendard', fontSize: '18px', fontWeight: 700, color: '#0E111F'}}>
          결제내역
        </h2>
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-[#E2E8EF]" style={{ height: '40px', fontFamily: 'Pretendard', fontSize: '14px', fontWeight: 500, color: '#666B81' }}>
            <tr>
              <th className="text-center font-medium text-gray-500 tracking-wider cursor-pointer">
                No.<img src={handleSortIcon} alt="Sort Icon" className="inline-block ml-1" style={{ width: '22px', height: '22px' }} />
              </th>
              <th className="text-center font-medium text-gray-500 tracking-wider cursor-pointer">
                주문번호<img src={handleSortIcon} alt="Sort Icon" className="inline-block ml-1" style={{ width: '22px', height: '22px' }} />
              </th>
              <th className="text-center font-medium text-gray-500 tracking-wider cursor-pointer">
                상품구분<img src={handleSortIcon} alt="Sort Icon" className="inline-block ml-1" style={{ width: '22px', height: '22px' }} />
              </th>
              <th className="text-center font-medium text-gray-500 tracking-wider cursor-pointer">
                상품명<img src={handleSortIcon} alt="Sort Icon" className="inline-block ml-1" style={{ width: '22px', height: '22px' }} />
              </th>
              <th className="text-center font-medium text-gray-500 tracking-wider cursor-pointer">
                ID<img src={handleSortIcon} alt="Sort Icon" className="inline-block ml-1" style={{ width: '22px', height: '22px' }} />
              </th>
              <th className="text-center font-medium text-gray-500 tracking-wider cursor-pointer">
                이름<img src={handleSortIcon} alt="Sort Icon" className="inline-block ml-1" style={{ width: '22px', height: '22px' }} />
              </th>
              <th className="text-center font-medium text-gray-500 tracking-wider cursor-pointer">
                휴대폰 번호<img src={handleSortIcon} alt="Sort Icon" className="inline-block ml-1" style={{ width: '22px', height: '22px' }} />
              </th>
              <th className="text-center font-medium text-gray-500 tracking-wider cursor-pointer">
                주문금액<img src={handleSortIcon} alt="Sort Icon" className="inline-block ml-1" style={{ width: '22px', height: '22px' }} />
              </th>
              <th className="text-center font-medium text-gray-500 tracking-wider cursor-pointer">
                결제수단<img src={handleSortIcon} alt="Sort Icon" className="inline-block ml-1" style={{ width: '22px', height: '22px' }} />
              </th>
              <th className="text-center font-medium text-gray-500 tracking-wider cursor-pointer">
                결제상태<img src={handleSortIcon} alt="Sort Icon" className="inline-block ml-1" style={{ width: '22px', height: '22px' }} />
              </th>
              <th className="text-center font-medium text-gray-500 tracking-wider cursor-pointer">
                결제일시<img src={handleSortIcon} alt="Sort Icon" className="inline-block ml-1" style={{ width: '22px', height: '22px' }} />
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200" style={{fontFamily: 'Pretendard', fontSize: '14px', fontWeight: 500, color: '#000000'}}>
            {paymentHistory.length > 0 ? (
              paymentHistory.map((item, index) => (
                <tr key={index} className="hover:bg-gray-100 transition duration-150 ease-in-out h-[40px]">
                  <td className="whitespace-no-wrap text-center">{index + 1}</td>
                  <td className="whitespace-no-wrap text-center">{item.orderNumber || '-'}</td>
                  <td className="whitespace-no-wrap text-center">{item.productType || '-'}</td>
                  <td className="whitespace-no-wrap text-center">{item.productName || '-'}</td>
                  <td className="whitespace-no-wrap text-center">{item.id || '-'}</td>
                  <td className="whitespace-no-wrap text-center">{item.name || '-'}</td>
                  <td className="whitespace-no-wrap text-center">{item.phoneNumber || '-'}</td>
                  <td className="whitespace-no-wrap text-center">{item.amount || '-'}</td>
                  <td className="whitespace-no-wrap text-center">{item.paymentMethod || '-'}</td>
                  <td className="whitespace-no-wrap text-center">{item.status || '-'}</td>
                  <td className="whitespace-no-wrap text-center">{item.paymentDate || '-'}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="11" className="text-center py-4">결제 내역이 없습니다.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {/* 페이지네이션 컴포넌트 */}
      <div className="flex justify-center mt-4">
        <ReactPaginate
        previousLabel={
            <img src={leftCursor} alt="Left Cursor" className="w-4 h-4" />
        }
        nextLabel={
            <img src={rightCursor} alt="Right Cursor" className="w-4 h-4" />
        }
        breakLabel={'...'} // 페이지 구분자
        pageCount={Math.ceil(totalItems / itemsPerPage)} // 총 페이지 수 계산
        marginPagesDisplayed={2} // 양 끝에 보여줄 페이지 수
        pageRangeDisplayed={5} // 현재 페이지 근처에 보여줄 페이지 수
        onPageChange={handlePageClick} // 페이지 변경 시 호출할 핸들러
        containerClassName={'pagination flex space-x-2 items-center'} // 페이지네이션 컨테이너 스타일
        activeClassName={'text-[#3677F6] font-bold'} // 현재 선택된 페이지 스타일
        breakClassName={'text-[#A9B6CA]'} // 구분자 스타일
        disabledClassName={'text-[#C5D0DD] cursor-not-allowed'} // 비활성화 스타일
        />
      </div>
    </div>
  );
};

const InputField = ({ label, name, value, onChange, type = "text" }) => (
  <div className="mb-4">
    <label className="block text-sm font-bold mb-2" style={{fontFamily: 'Pretendard', fontSize: '14px', fontWeight: 700, color: '#0E111F'}}>
      {label}
    </label>
    <input
      type={type}
      name={name}
      value={value}
      onChange={onChange}
      className="w-full"
      style={{
        height: '48px',
        padding: '4px 14px',
        borderRadius: '12px',
        border: '1px solid #E2E8EF',
        fontFamily: 'Pretendard',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '14px',
        textAlign: 'left',
        color: value ? '#000000' : '#A9B6CA'
      }}
    />
  </div>
);

const SelectField = ({ label, name, value, options, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const getKeyByValue = (object, value) => {
    if (!object || typeof object !== 'object') return '';
    return Object.keys(object).find(key => object[key] === value) || '';
  };

  return (
    <div className="mb-4">
      <label className="block text-sm font-bold mb-2" style={{fontFamily: 'Pretendard', fontSize: '14px', fontWeight: 700, color: '#0E111F'}}>
        {label}
      </label>
      <div className="relative">
        <div
          onClick={() => setIsOpen(!isOpen)}
          className="w-full cursor-pointer"
          style={{
            height: '48px',
            padding: '4px 14px',
            borderRadius: '12px',
            border: '1px solid #E2E8EF',
            fontFamily: 'Pretendard',
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '14px',
            textAlign: 'left',
            color: value !== undefined ? '#000000' : '#A9B6CA',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <span>{getKeyByValue(options, value) || '선택해주세요'}</span>
          <ChevronDownIcon className="h-5 w-5 text-gray-400" />
        </div>
        {isOpen && (
          <div className="absolute z-10 w-full mt-1 bg-white border border-gray-200 rounded-lg shadow-lg">
            {Object.entries(options).map(([key, optionValue]) => (
              <div
                key={optionValue}
                className="p-2 hover:bg-gray-100 cursor-pointer"
                onClick={() => {
                  onChange(optionValue);
                  setIsOpen(false);
                }}
                style={{
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontWeight: 500,
                  color: '#000000'
                }}
              >
                {key}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const DateField = ({ label, value, onChange }) => {
  return (
    <div className="mb-4">
      <label className="block text-sm font-bold mb-2" style={{fontFamily: 'Pretendard', fontSize: '14px', fontWeight: 700, color: '#0E111F'}}>
        {label}
      </label>
      <div 
        className="relative flex items-center"
        style={{
          height: '48px',
          padding: '4px 14px',
          borderRadius: '12px',
          border: '1px solid #E2E8EF',
        }}
      >
        <DatePicker
          selected={value}
          onChange={onChange}
          dateFormat="yyyy-MM-dd HH:mm"
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={15}
          className="w-full"
          style={{
            height: '48px',
            padding: '18px 14px',
            borderRadius: '12px',
            border: '1px solid #E2E8EF',
            fontFamily: 'Pretendard',
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '14px',
            textAlign: 'left',
            color: value ? '#000000' : '#A9B6CA'
          }}
        />
        <img 
          src={calendarIcon} 
          alt="Calendar Icon" 
          className="absolute right-2 top-1/2 transform -translate-y-1/2" 
          style={{ width: '16px', height: '18px' }}
        />
      </div>
    </div>
  );
};

const ReadOnlyField = ({ label, value }) => (
  <div className="mb-4">
    <label className="block text-sm font-bold mb-2" style={{fontFamily: 'Pretendard', fontSize: '14px', fontWeight: 700, color: '#0E111F'}}>
      {label}
    </label>
    <input
      type="text"
      value={value}
      readOnly
      className="w-full bg-gray-100"
      style={{
        height: '48px',
        padding: '4px 14px',
        borderRadius: '12px',
        border: '1px solid #E2E8EF',
        fontFamily: 'Pretendard',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '14px',
        textAlign: 'left',
        color: '#000000'
      }}
    />
  </div>
);

export default MemberEditor;