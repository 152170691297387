import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

const NicePhone = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/register");
  }, []);

  return <div></div>;
};

export default NicePhone;
