import { useState, useContext, useEffect } from "react";
import { LanguageContext } from "./LanguageContext";
import LanguageSwitcher from "./LanguageSwitcher";
import LanguageSwitcher2 from "./LanguageSwitcher2";
import { useNavigate } from "react-router-dom";
import GnbStudy from "./components/GnbStudy";
import UseSwipe from "./UseSwipe"; // Adjust the path as necessary

const Study = () => {
  const navigate = useNavigate();
  const { translations } = useContext(LanguageContext);
  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("theme") === "dark"
  );

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add("dark");
      localStorage.setItem("theme", "dark");
    } else {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("theme", "light");
    }
  }, [darkMode]);

  // Handler functions for navigation
  const goToMbtiStudy = () => navigate("/mbtistudy");
  const goToPersonaStudy = () => navigate("/personastudy");
  const goToBookmark = () => navigate("/bookmark");
  const goToNote = () => navigate("/note");
  const goToSituation = () => navigate("/situationstudy");
  const goToPsychology = () => navigate("/psychologystudy");

  const [isModalVisible2, setisModalVisible2] = useState(false);

  const handleGoClick = () => {
    setisModalVisible2(true);
  };

  const handleCloseModal = () => {
    setisModalVisible2(false);
  };

  const handleSwipeLeft = () => {
    navigate("/express");
  };

  const handleSwipeRight = () => {
    navigate("/home");
  };

  UseSwipe(handleSwipeLeft, handleSwipeRight);

  const [heightStyle, setHeightStyle] = useState("100vh");

  useEffect(() => {
    const updateHeight = () => {
      if (window.innerHeight > 739) {
        setHeightStyle("100vh");
      } else {
        setHeightStyle("calc(100vh + 68px)");
      }
    };

    // Initial check
    updateHeight();

    // Event listener for resizing window
    window.addEventListener("resize", updateHeight);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  return (
    <div
      id="swipe-container"
      className="flex justify-center w-full bg-[#faf8f6] dark:bg-[#121418]"
    >
      <div
        className="relative w-[360px] bg-[#faf8f6] dark:bg-[#121418] overflow-y-auto"
        style={{
          minHeight: heightStyle,
        }}
        // style={{
        //   minHeight: "calc(100vh + 68px)", // Add 50px to height to allow scrolling past the navbar
        // }}
      >
        {/* Navigation Bar */}
        <GnbStudy></GnbStudy>

        {/* Main Content */}
        <div className="relative w-full h-full flex justify-center">
          <div className="relative w-[360px] h-full">
            <div className="absolute left-[16px] top-[10vh] w-[328px] flex flex-col items-start justify-start gap-[24px]">
              <div className="self-stretch flex flex-col items-start justify-start gap-[16px]">
                <div className="w-[100%] flex flex-col items-start justify-start gap-[8px]">
                  <div className="self-stretch text-[16px] leading-[100%] font-sans font-bold text-[#342f2b] dark:text-[#fff]">
                    {translations.STUDY1}
                  </div>
                  <div className="self-stretch text-[12px] leading-[100%] font-sans font-medium text-[#000] dark:text-[#666b81]">
                    {translations.CUSTOMIZED_LESSON_FOR_MY_PERSONALITY}
                  </div>
                </div>
                <div className="self-stretch flex flex-row items-center justify-between">
                  <div
                    className="relative w-[156px] h-[149px] shrink-0 bg-gradient-to-r from-[#8eeedd] to-[#256ef5] rounded-[12px] overflow-hidden"
                    onClick={goToMbtiStudy}
                  >
                    <div className="absolute -translate-x-1/2 left-1/2 top-[119px] text-[12px] leading-[100%] font-sans font-medium text-[#fff] whitespace-nowrap ">
                      {translations.CUSTOMIZED_LESSON_FOR_MY_MBTI}
                    </div>
                    <img
                      className="absolute left-[19px] top-[59px]"
                      width="115"
                      height="29"
                      src="MBTI12_94.png"
                    ></img>
                  </div>
                  <div
                    className="relative w-[156px] h-[149px] shrink-0 bg-[#3677f6] rounded-[12px] overflow-hidden"
                    onClick={goToPersonaStudy}
                  >
                    <div className="absolute -translate-x-1/2 left-1/2 top-[119px] text-[12px] leading-[100%] font-sans font-medium text-[#fff] whitespace-nowrap">
                      {translations.CUSTOMIZED_LESSON_FOR_MY_PERSONA}
                    </div>
                    <div className="absolute left-[-15px] top-[-10px] w-[186px] h-[88px] flex flex-wrap items-center justify-start gap-[5px]">
                      <div className="flex flex-row items-center justify-center py-[7px] px-[12px] bg-[#ffffff2e] rounded-[50px]">
                        <div className="text-[12px] leading-[100%] font-sans font-extrabold text-[#fff] whitespace-nowrap">
                          썸
                        </div>
                      </div>
                      <div className="flex flex-row items-center justify-center py-[7px] px-[12px] bg-[#ffffff2e] rounded-[50px]">
                        <div className="text-[12px] leading-[100%] font-sans font-extrabold text-[#fff] whitespace-nowrap">
                          여성😊
                          {/* <span className="inline-block animate-jump">😊</span> */}
                        </div>
                      </div>
                      <div className="flex flex-row items-center justify-center py-[7px] px-[12px] bg-[#ffffff2e] rounded-[50px]">
                        <div className="text-[12px] leading-[100%] font-sans font-extrabold text-[#fff] whitespace-nowrap">
                          썸
                        </div>
                      </div>
                      <div className="flex flex-row items-center justify-center py-[7px] px-[12px] bg-[#ffffff2e] rounded-[50px]">
                        <div className="text-[12px] leading-[100%] font-sans font-extrabold text-[#fff] whitespace-nowrap">
                          20
                        </div>
                      </div>
                      <div className="flex flex-row items-center justify-center py-[7px] px-[12px] bg-[#ffffff2e] rounded-[50px]">
                        <div className="text-[12px] leading-[100%] font-sans font-extrabold text-[#fff] whitespace-nowrap">
                          직장인
                        </div>
                      </div>
                      <div className="flex flex-row items-center justify-center py-[7px] px-[12px] bg-[#ffffff2e] rounded-[50px]">
                        <div className="text-[12px] leading-[100%] font-sans font-extrabold text-[#fff] whitespace-nowrap">
                          40대
                        </div>
                      </div>
                      <div className="flex flex-row items-center justify-center py-[7px] px-[12px] bg-[#ffffff2e] rounded-[50px]">
                        <div className="text-[12px] leading-[100%] font-sans font-extrabold text-[#fff] whitespace-nowrap">
                          연애중💖
                        </div>
                      </div>
                      <div className="flex flex-row items-center justify-center py-[7px] px-[12px] bg-[#ffffff2e] rounded-[50px]">
                        <div className="text-[12px] leading-[100%] font-sans font-extrabold text-[#fff] whitespace-nowrap">
                          자영업👍
                        </div>
                      </div>
                      <div className="flex flex-row items-center justify-center py-[7px] px-[12px] bg-[#ffffff2e] rounded-[50px]">
                        <div className="text-[12px] leading-[100%] font-sans font-extrabold text-[#fff] whitespace-nowrap">
                          학생🐰
                        </div>
                      </div>
                      <div className="flex flex-row items-center justify-center py-[7px] px-[12px] bg-[#ffffff2e] rounded-[50px]">
                        <div className="text-[12px] leading-[100%] font-sans font-extrabold text-[#fff] whitespace-nowrap">
                          수험
                        </div>
                      </div>
                      <div className="flex flex-row items-center justify-center py-[7px] px-[12px] bg-[#ffffff2e] rounded-[50px]">
                        <div className="text-[12px] leading-[100%] font-sans font-extrabold text-[#fff] whitespace-nowrap">
                          결혼👨‍👩‍👦
                        </div>
                      </div>
                      <div className="flex flex-row items-center justify-center py-[7px] px-[12px] bg-[#ffffff2e] rounded-[50px]">
                        <div className="text-[12px] leading-[100%] font-sans font-extrabold text-[#fff] whitespace-nowrap">
                          프리랜서🏃
                          {/* <span className="inline-block animate-run">🏃</span> */}
                        </div>
                      </div>
                      <div className="flex flex-row items-center justify-center py-[7px] px-[12px] bg-[#ffffff2e] rounded-[50px]">
                        <div className="text-[12px] leading-[100%] font-sans font-extrabold text-[#fff] whitespace-nowrap">
                          대
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="self-stretch flex flex-col items-start justify-start gap-[16px]">
                <div className="self-stretch text-[16px] leading-[100%] font-sans font-bold text-[#342f2b] dark:text-[#fff]">
                  {translations.STUDY3}
                </div>
                <div className="self-stretch flex flex-row items-center justify-between">
                  <div
                    className="relative w-[156px] h-[149px] shrink-0 bg-[#2240ac] rounded-[12px] overflow-hidden flex items-end justify-center"
                    onClick={goToSituation}
                  >
                    <img
                      className="absolute left-[-28px] top-[66px]"
                      width="210"
                      height="96"
                      src="Group 126115515012_119.png"
                    ></img>
                    <div className="top-[101px] text-[12px] font-sans text-[#fff] text-center mb-[18px] break-words z-50">
                      {translations.REAL_LIFE_SCENARIO_PRACTICE}
                    </div>
                    <img
                      className="absolute left-[38px] top-[11px]"
                      width="77"
                      height="84"
                      src="Group 126115515512_138.png"
                    ></img>
                  </div>
                  <div
                    className="relative w-[156px] h-[149px] shrink-0 bg-[#36c3a9] rounded-[12px] overflow-hidden flex items-end justify-center"
                    onClick={goToPsychology}
                  >
                    <div className="absolute -translate-x-1/2 left-[calc(50%+8px)] top-[63px] w-[228px] h-[110px] bg-[#0aaa8d] rounded-full"></div>
                    <img
                      className="absolute left-[-11px] top-[65px]"
                      width="179"
                      height="81"
                      src="Intersect12_150.png"
                    ></img>
                    <div className="top-[101px] text-[12px] font-sans text-[#fff] text-center mb-[18px] break-words z-50">
                      {translations.THERAPEUTIC_LEARNING_FOR_PEACE_OF_MIND}
                    </div>
                    <img
                      className="absolute left-[43px] top-[8px]"
                      width="70"
                      height="82"
                      src="Group 126115515212_334.png"
                    ></img>
                  </div>
                </div>
              </div>
              <div className="self-stretch flex flex-col items-start justify-start gap-[16px]">
                <div className="self-stretch text-[16px] leading-[100%] font-sans font-bold text-[#342f2b] dark:text-[#fff]">
                  {translations.STUDY4}
                </div>
                <div className="self-stretch flex flex-row items-center justify-start gap-[16px]">
                  <div
                    className="relative flex-1 h-[131px] bg-[#fff] dark:bg-[#22232a] rounded-[12px] overflow-hidden"
                    onClick={goToBookmark}
                  >
                    <img
                      className="absolute -translate-x-1/2 left-1/2 top-[20px]"
                      width="38"
                      height="68"
                      src="Frame126115530112_348.png"
                    ></img>
                    <div className="absolute -translate-x-1/2 left-1/2 top-[103px] text-[14px] leading-[100%] font-sans font-medium text-[#342f2b] dark:text-[#666b81] whitespace-nowrap">
                      {translations.BOOKMARK}
                    </div>
                  </div>
                  <div
                    className="relative flex-1 h-[131px] bg-[#fff] dark:bg-[#22232a] rounded-[12px] overflow-hidden"
                    onClick={handleGoClick}
                  >
                    <div className="absolute -translate-x-1/2 left-1/2 top-[103px] text-[14px] leading-[100%] font-sans font-medium text-[#342f2b] dark:text-[#666b81] whitespace-nowrap">
                      {translations.STORYBOARD}
                    </div>
                    <img
                      className="absolute left-[24px] top-[24px]"
                      width="51"
                      height="60"
                      src="Group 126115513812_359.png"
                    ></img>
                  </div>
                  <div
                    className="relative flex-1 h-[131px] bg-[#fff] dark:bg-[#22232a] rounded-[12px] overflow-hidden"
                    onClick={goToNote}
                  >
                    <div className="absolute -translate-x-1/2 left-1/2 top-[103px] text-[12px] leading-[100%] font-sans font-medium text-[#342f2b] dark:text-[#666b81] text-center">
                      {translations.INCORRECT_ANSWER_NOTES}
                    </div>
                    <img
                      className="absolute left-[27px] top-[26px]"
                      width="45"
                      height="57"
                      src="Group 126115505912_375.png"
                    ></img>
                  </div>
                </div>
              </div>
            </div>
            <div className="absolute left-0 top-[2vh] w-[100%] h-[50px]">
              <div className="absolute left-[16px] top-[7px] w-[328px] flex flex-row items-center justify-between">
                <div className="text-[16px] leading-[100%] font-sans font-bold text-[#000] text-center whitespace-nowrap dark:text-[#fff]">
                  {translations.STUDY}
                </div>
                <div className="flex flex-row items-center justify-end gap-[8px]">
                  <div
                    className="h-[36px] flex flex-row items-center justify-center"
                    onClick={() => navigate("/error-action")}
                  >
                    <div className="flex flex-row items-center justify-center py-[8px] px-[14px] border-[2px] border-solid border-[#000] dark:border-[#fff] rounded-[59px]">
                      <div className="text-[14px] leading-[100%] font-sans font-medium text-[#000] whitespace-nowrap dark:text-[#fff]">
                        {translations.STUDY2}
                      </div>
                    </div>
                  </div>
                  {darkMode ? (
                    <LanguageSwitcher2></LanguageSwitcher2>
                  ) : (
                    <LanguageSwitcher></LanguageSwitcher>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Modal */}
        {isModalVisible2 && (
          <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-20">
            <div className="w-[60%] h-[20%] flex flex-col items-center justify-center gap-[10px] py-[20px] px-[34px] bg-[#e3edff] rounded-[8px] overflow-hidden animate-slideIn">
              <div className="text-[26px] font-sans font-black text-[#3677f6] whitespace-nowrap">
                🚧 준비중
              </div>
              <div className="text-[14px] leading-[100%] font-sans font-medium text-[#3677f6] whitespace-nowrap">
                조금만 기다려주세요!
              </div>
              <button
                className="mt-4 px-4 py-2 bg-[#3677f6] text-white rounded"
                onClick={handleCloseModal}
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Study;
