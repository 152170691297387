import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Navigation from '../../components/admin/Navigation';
import TermsAndConditions from '../../components/admin/TermsAndConditions';
import PersonalInformation from '../../components/admin/PersonalInformation';
import axios from 'axios';
import API_BASE_URL from '../../config';

const AppEditor = () => {
  const navigate = useNavigate();

  const [appData, setAppData] = useState({
    appName: '',
    appNameEn: '',
    version: '',
    phoneNumber: '',
    email: '',
    companyName: '',
    representativeName: '',
    location: '',
    businessNumber: '',
    salesNumber: '',
  });

  const [banners, setBanners] = useState([
    { id: 1, name: '', url: '' },
    { id: 2, name: '', url: '' },
    { id: 3, name: '', url: '' },
    { id: 4, name: '', url: '' },
    { id: 5, name: '', url: '' },
  ]);

  useEffect(() => {
    const fetchAppSettings = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/appsettings/?id=1`);
        const data = response.data[0];
        setAppData({
          appName: data.appName || '',
          appNameEn: data.appNameEn || '',
          version: data.version || '',
          phoneNumber: data.phoneNumber || '',
          email: data.email || '',
          companyName: data.companyName || '',
          representativeName: data.representativeName || '',
          location: data.location || '',
          businessNumber: data.businessNumber || '',
          salesNumber: data.salesNumber || '',
        });
        
        // 배너 데이터 설정
        const fetchedBanners = data.bannerImages.map((image, index) => ({
          id: index + 1,
          name: image,
          url: data.bannerImageLinks[index] || '',
        }));
        
        // 기존 배너 데이터와 합치기
        setBanners(prevBanners => {
          const newBanners = [...fetchedBanners];
          while (newBanners.length < 5) {
            newBanners.push({ id: newBanners.length + 1, name: '', url: '' });
          }
          return newBanners;
        });
      } catch (error) {
        console.error('앱 설정을 불러오는 데 실패했습니다:', error);
      }
    };

    fetchAppSettings();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAppData(prev => ({ ...prev, [name]: value }));
  };

  const handleBannerChange = (id, field, value) => {
    setBanners(prev => prev.map(banner => 
      banner.id === id ? { ...banner, [field]: value } : banner
    ));
  };

  const handleFileChange = (id, e) => {
    const file = e.target.files[0];
    if (file) {
      setBanners(prev => prev.map(banner =>
        banner.id === id ? { ...banner, name: file.name, file: file } : banner
      ));
    }
  };

  const handleSave = async () => {
    try {
      const formData = new FormData();

      // 기본 정보 추가
      Object.entries(appData).forEach(([key, value]) => {
        formData.append(key, value);
      });

      // 배너 이미지와 링크 추가
      const bannerImageLinks = [];

      banners.forEach((banner, index) => {
        if (banner.file) {
          formData.append(`files`, banner.file);
        }
        bannerImageLinks.push(banner.url || '');
      });

      // 배열을 JSON 문자열로 변환하여 추가
      formData.append('bannerImageLinks', JSON.stringify(bannerImageLinks));

      const response = await axios.patch(`${API_BASE_URL}/appsettings/1`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        alert('설정이 저장되었습니다.');
      } else {
        throw new Error('서버 응답이 올바르지 않습니다.');
      }
    } catch (error) {
      console.error('설정 저장에 실패했습니다:', error);
      alert('설정 저장에 실패했습니다.');
    }
  };

  return (
    <div className="min-h-screen bg-[#FAF8F6] p-6">
      <Navigation />
      <div className="flex justify-between items-center mb-5">
        <h1 
          className="text-2xl font-bold text-left"
          style={{
            fontFamily: 'Pretendard',
            fontSize: '24px',
            fontWeight: 700,
          }}
        >
          앱 설정
        </h1>
        <button 
          className="w-[100px] h-[48px] bg-[#3677F6] text-white"
          style={{
            fontFamily: 'Pretendard',
            fontSize: '14px',
            fontWeight: 700,
            borderRadius: '12px'
          }}
          onClick={handleSave}
        >
          저장하기
        </button>
      </div>

      <div className="bg-white rounded-lg p-6 shadow-md mb-10">
        <h2 
          className="text-xl mb-4"
          style={{
            fontFamily: 'Pretendard',
            fontSize: '18px',
            fontWeight: 700,
            color: '#0E111F',
          }}
        >
          기본정보
        </h2>
        <div className="grid grid-cols-5 gap-4">
          {Object.entries(appData).map(([key, value]) => (
            <div key={key} className="flex-1">
              <label 
                className="block text-sm font-bold mb-2"
                style={{
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontWeight: 700,
                  color: '#0E111F',
                }}
              >
                {key === 'appName' ? '앱 이름' :
                 key === 'appNameEn' ? '영문' :
                 key === 'version' ? '버전' :
                 key === 'phoneNumber' ? '전화번호' :
                 key === 'email' ? '전자우편' :
                 key === 'companyName' ? '회사명' :
                 key === 'representativeName' ? '대표' :
                 key === 'location' ? '소재지' :
                 key === 'businessNumber' ? '사업자번호' :
                 key === 'salesNumber' ? '통신판매업' : ''}
              </label>
              <input
                type="text"
                name={key}
                value={value}
                onChange={handleInputChange}
                className="w-full bg-white border border-[#E2E8EF]"
                style={{
                  height: '48px',
                  padding: '18px 14px',
                  borderRadius: '12px',
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontWeight: 500,
                }}
              />
            </div>
          ))}
        </div>
      </div>

      <div className="bg-white rounded-lg p-6 shadow-md mb-10">
        <h2 
          className="text-xl mb-4"
          style={{
            fontFamily: 'Pretendard',
            fontSize: '18px',
            fontWeight: 700,
            color: '#0E111F',
          }}
        >
          약관
        </h2>
        <div className="space-y-4">
          <div>
            <h3 className="font-bold mb-2">이용약관</h3>
            <div className="border border-[#E2E8EF] rounded-lg p-4 max-h-[300px] overflow-y-auto">
              <TermsAndConditions />
            </div>
          </div>
          <div>
            <h3 className="font-bold mb-2">개인정보 처리방침</h3>
            <div className="border border-[#E2E8EF] rounded-lg p-4 max-h-[300px] overflow-y-auto">
              <PersonalInformation />
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white rounded-lg p-6 shadow-md mb-10">
        <h2 
          className="text-xl mb-4"
          style={{
            fontFamily: 'Pretendard',
            fontSize: '18px',
            fontWeight: 700,
            color: '#0E111F',
          }}
        >
          배너 관리
        </h2>
        <div className="space-y-4">
          {banners.map((banner, index) => (
            <div key={banner.id} className="flex gap-4 items-center">
              <span className="w-16 text-center">{`배너 ${index + 1}`}</span>
              <div className="flex-1 relative">
                <input
                  type="text"
                  value={banner.name}
                  readOnly
                  className="w-full bg-white border border-[#E2E8EF] pr-24"
                  style={{
                    height: '48px',
                    padding: '18px 14px',
                    borderRadius: '12px',
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontWeight: 500,
                  }}
                />
                <input
                  type="file"
                  id={`file-${banner.id}`}
                  className="hidden"
                  onChange={(e) => handleFileChange(banner.id, e)}
                />
                <label
                  htmlFor={`file-${banner.id}`}
                  className="absolute right-2 top-1/2 transform -translate-y-1/2 w-[80px] h-[36px] bg-[#3677F6] text-white flex items-center justify-center"
                  style={{
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontWeight: 700,
                    borderRadius: '8px',
                    cursor: 'pointer',
                  }}
                >
                  첨부
                </label>
              </div>
              <input
                type="text"
                value={banner.url}
                onChange={(e) => handleBannerChange(banner.id, 'url', e.target.value)}
                placeholder="링크를 입력해주세요"
                className="flex-1 bg-white border border-[#E2E8EF]"
                style={{
                  height: '48px',
                  padding: '18px 14px',
                  borderRadius: '12px',
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontWeight: 500,
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AppEditor;
