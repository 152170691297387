import React, {
  useState,
  useRef,
  useEffect,
  useContext,
  useCallback,
} from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LanguageContext } from "./LanguageContext";
import LanguageSwitcher from "./LanguageSwitcher";
import { setManageID, setType, setImageUrl, setWhere } from "./actions";
import { IoIosArrowBack } from "react-icons/io";
import { CgSortAz } from "react-icons/cg";

const BookMark = () => {
  const dispatch = useDispatch();
  const [selectedGender, setSelectedGender] = useState(null);
  const [isOverlayVisible, setOverlayVisible] = useState(false);
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]); // Initialize state for storing results
  const [data3, setData3] = useState({}); // Initialize state for storing results
  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("theme") === "dark"
  );

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add("dark");
      localStorage.setItem("theme", "dark");
    } else {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("theme", "light");
    }
  }, [darkMode]);
  const [sortConfig, setSortConfig] = useState({
    key: "latest",
    direction: "ascending",
  });
  const [sortedData, setSortedData] = useState([]);
  const { translations, language } = useContext(LanguageContext);

  const [visibleItems, setVisibleItems] = useState(10); // Start by showing 10 items
  const loadMoreItems = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 10); // Load 10 more items on each click
  };

  const overlayRef = useRef(null); // Ref for the overlay
  const typeList = ["MBTI", "Persona", "Situation", "Psychology", "Expression"];
  const apiUrl = process.env.REACT_APP_API_URL;
  const accessToken = sessionStorage.getItem("accessToken");
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate("/home");
  };

  const toggleOverlay = () => {
    setOverlayVisible(!isOverlayVisible);
  };

  const handleSelect = (category, value) => {
    if (category === "gender") {
      setSelectedGender((prevSelectedGender) =>
        prevSelectedGender === value ? null : value
      );
    }
  };

  const handleComplete = () => {
    toggleOverlay(); // Optionally close the overlay
  };

  const handleClickOutside = (event) => {
    if (overlayRef.current && !overlayRef.current.contains(event.target)) {
      setOverlayVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(`${apiUrl}/bookmarks?page=1&limit=1000`, {
        method: "GET", // or 'POST' depending on your API
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json", // Include other headers if needed
        },
      });

      if (response.ok) {
        const result = await response.json();
        setData(result.results);
      } else {
        console.error("Failed to fetch data", response.status);
        // Handle the error as needed
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures this runs only once when the component mounts

  useEffect(() => {
    const fetchItemTitles = async () => {
      const results = []; // Temporary array to accumulate results

      // Step 1: Iterate over the results array and extract both itemId and itemType
      for (const item of data) {
        const itemId = item.itemId;
        const type = item.itemType;
        let url;

        if (type === "mbti" || type === "persona") {
          url = `${apiUrl}/customize/manage/${itemId}`;
        } else if (type === "expression") {
          url = `${apiUrl}/expressions/manage/${itemId}`;
        } else {
          url = `${apiUrl}/${type}/manage/${itemId}`;
        }

        try {
          // Step 2: Use both itemType and itemId in the API URL
          const response = await fetch(url);

          if (response.ok) {
            const resultData = await response.json();
            // Step 3: Accumulate the resultData in the results array
            results.push(resultData);
          } else {
            console.error(
              `Error fetching item with ID ${itemId}:`,
              response.status
            );
          }
        } catch (error) {
          console.error(`Error fetching item with ID ${itemId}:`, error);
        }
      }

      // Step 4: Once all items are fetched, update the data2 state
      setData2(results);
    };

    if (data.length > 0) {
      fetchItemTitles();
    }
  }, [data, apiUrl]); // Dep

  // Fetch data when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiUrl}/tree-nodes/1`);
        const fetchedData = await response.json();
        setData3(fetchedData); // Store fetched data in state
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [apiUrl]);

  const findItemById = (id, node) => {
    if (!node) return null;

    // Check if translations have the item with the given id
    if (node.translations) {
      const foundTranslation = node.translations.find(
        (translation) => translation.id === id
      );
      if (foundTranslation) return foundTranslation;
    }

    // Recursively search in children
    if (node.children) {
      for (const child of node.children) {
        const result = findItemById(id, child);
        if (result) return result;
      }
    }

    return null;
  };

  const handleGoStudy = (item) => {
    dispatch(setManageID(item.id));
    dispatch(setImageUrl(item.imageurl));
    dispatch(setWhere("bookmark"));
    const fetchData = async () => {
      try {
        if (item.customizes) {
          const languages = ["kor", "eng"];
          const difficulties = ["easyId", "normalId", "difficultId"];
          const fetchedLis = {};

          for (const language of languages) {
            fetchedLis[language] = {};
            const languageItem = item.customizes.find(
              (cust) => cust.language === language
            );

            if (languageItem) {
              for (const difficulty of difficulties) {
                const id = languageItem[difficulty];

                if (id) {
                  const response = await fetch(
                    `${apiUrl}/customize_script/${id}`
                  );
                  const data = await response.json();

                  if (response.ok) {
                    console.log(
                      `Fetched data for ${language} - ${difficulty}:`,
                      data
                    );
                    fetchedLis[language][difficulty] = data;
                  }
                }
              }
            }
          }

          dispatch(setType("mbti"));
          navigate("/studyonboard", {
            state: { script: fetchedLis, item: item },
          });
        }
        if (item.situations) {
          const languages = ["kor", "eng"];
          const difficulties = ["easyId", "normalId", "difficultId"];
          const fetchedLis = {};

          for (const language of languages) {
            fetchedLis[language] = {};
            const languageItem = item.situations.find(
              (cust) => cust.language === language
            );

            if (languageItem) {
              for (const difficulty of difficulties) {
                const id = languageItem[difficulty];

                if (id) {
                  const response = await fetch(
                    `${apiUrl}/situation_script/${id}`
                  );
                  const data = await response.json();

                  if (response.ok) {
                    console.log(
                      `Fetched data for ${language} - ${difficulty}:`,
                      data
                    );
                    fetchedLis[language][difficulty] = data;
                  }
                }
              }
            }
          }

          dispatch(setType("situation"));
          navigate("/studyonboard", {
            state: { script: fetchedLis, item: item },
          });
        } else if (item.expressions) {
          navigate(`/vocablist/${item.id}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  };

  const sortData = useCallback(
    (dataToSort) => {
      console.log("Sorting data:", dataToSort);
      const sortedData = [...dataToSort];
      if (sortConfig.key === "latest") {
        sortedData.sort((a, b) => {
          return sortConfig.direction === "ascending"
            ? new Date(b.createdAt) - new Date(a.createdAt)
            : new Date(a.createdAt) - new Date(b.createdAt);
        });
      } else if (sortConfig.key === "alphabetical") {
        sortedData.sort((a, b) => {
          const aTitle = a.title || "";
          const bTitle = b.title || "";
          return sortConfig.direction === "ascending"
            ? aTitle.localeCompare(bTitle)
            : bTitle.localeCompare(aTitle);
        });
      } else if (sortConfig.key === "rating") {
        sortedData.sort((a, b) => {
          return sortConfig.direction === "ascending"
            ? a.averageRating - b.averageRating
            : b.averageRating - a.averageRating;
        });
      }
      console.log("Sorted data:", sortedData);
      return sortedData;
    },
    [sortConfig]
  );

  const handleSort = (key) => {
    console.log("Sorting by:", key);
    setSortConfig((prevConfig) => ({
      key,
      direction:
        prevConfig.key === key && prevConfig.direction === "ascending"
          ? "descending"
          : "ascending",
    }));
  };

  useEffect(() => {
    console.log("Current data:", data2);
    const sortedData = sortData(data2);
    console.log("New sorted data:", sortedData);
    setSortedData(sortedData);
  }, [data2, sortConfig, sortData]);

  if (data2) {
  } else {
    return <div>북마크 불러오는중...</div>;
  }

  return (
    <div className="flex min-h-screen justify-center w-full bg-[#faf8f6] dark:bg-[#121418]">
      {isOverlayVisible && (
        <div className="absolute w-full h-full bg-black opacity-50 z-10"></div>
      )}
      <div className="relative min-x-[360px] w-full bg-[#faf8f6] dark:bg-[#121418]">
        {/* Overlay */}
        <div
          ref={overlayRef}
          className={`fixed bottom-0 left-0 w-full bg-white transition-transform duration-300 ease-in-out ${
            isOverlayVisible
              ? "transform translate-y-0"
              : "transform translate-y-full"
          }`}
          style={{
            height: "80%",
            zIndex: 100,
            borderTopLeftRadius: "25px",
            borderTopRightRadius: "25px",
          }}
        >
          {/* Title of the overlay */}
          <div className="p-4">
            <h2 className="text-lg font-bold">필터 설정</h2>
          </div>
          {/* Content of the overlay */}
          <div className="flex flex-wrap gap-[6px] justify-center">
            {typeList.map((gender) => (
              <div
                key={gender}
                className={`flex items-center justify-center py-[8px] px-[14px] bg-[#fff] border-[1px] border-solid rounded-[59px] ${
                  selectedGender === gender
                    ? "border-[#3677f6] text-[#3677f6]"
                    : "bg-[#e9ecef] border-[#e9ecef] text-[#a9b6ca]"
                }`}
                onClick={() => handleSelect("gender", gender)}
                style={{ flexBasis: "calc(33.333% - 6px)" }}
              >
                <div className="text-[14px] leading-[100%] font-sans font-medium whitespace-nowrap">
                  {gender}
                </div>
              </div>
            ))}
          </div>

          {/* Footer */}
          <div className="fixed bottom-0 w-full">
            <div className="flex-none pb-[20px] px-[16px]">
              <button
                className="w-full h-[54px] flex items-center justify-center py-[10px] px-[20px] bg-[linear-gradient(64.95deg,#61e3eb_0%,#3677f6_100%)] rounded-[12px] text-[#fff] text-[14px] leading-[100%] font-sans font-bold"
                onClick={handleComplete}
              >
                설정 완료
              </button>
            </div>
          </div>
        </div>

        {/* Updated Item List Section */}
        <div className="relative left-0 top-0 w-full flex flex-col items-start justify-start px-[16px]">
          <div className="relative self-stretch h-[50px] shrink-0">
            <div className="absolute left-0 top-[7px] w-full flex flex-row items-center justify-between">
              <div
                className="w-[36px] h-[36px] shrink-0 flex flex-row items-center justify-start"
                onClick={handleGoBack}
              >
                {/* <img width="8" height="16" src="back.png"></img> */}
                <IoIosArrowBack
                  color={darkMode ? "white" : "black"}
                  size={30}
                />
              </div>
              <div className="text-[16px] leading-[100%] font-sans font-bold text-[#000] dark:text-[#fff] text-center whitespace-nowrap">
                북마크
              </div>
              <div className="w-[36px] h-[36px] shrink-0 flex flex-row items-center justify-center">
                <div className="relative w-[30px] h-[30px] shrink-0">
                  <div className="absolute left-0 right-0 top-0 bottom-0">
                    {/* <img
                      className="absolute left-[3px] top-[3px]"
                      width="24"
                      height="24"
                      src="filter.png"
                      onClick={toggleOverlay}
                    ></img> */}
                    <CgSortAz
                      color={darkMode ? "white" : "black"}
                      size={30}
                    ></CgSortAz>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full h-[30px]"></div>
        </div>
        <div className="self-stretch flex flex-col items-end justify-center gap-[4px]">
          {/* Sorting Options */}
          <div className="w-[95%] mx-auto">
            <div className="flex items-center justify-end gap-[5px] mb-[10px] mt-[10px]">
              <div
                className={`text-[10px] leading-[100%] font-sans font-medium ${
                  sortConfig.key === "latest"
                    ? "text-[#3677f6]"
                    : "text-[#666b81]"
                } whitespace-nowrap cursor-pointer`}
                onClick={() => handleSort("latest")}
              >
                {translations.ORDER_DATE}{" "}
                {sortConfig.key === "latest" &&
                  (sortConfig.direction === "ascending" ? "↑" : "↓")}
              </div>
              <div className="w-[2px] h-[2px] bg-[#666b81] rounded-full"></div>
              <div
                className={`text-[10px] leading-[100%] font-sans font-medium ${
                  sortConfig.key === "alphabetical"
                    ? "text-[#3677f6]"
                    : "text-[#666b81]"
                } whitespace-nowrap cursor-pointer`}
                onClick={() => handleSort("alphabetical")}
              >
                {translations.ORDER_AL}{" "}
                {sortConfig.key === "alphabetical" &&
                  (sortConfig.direction === "ascending" ? "↑" : "↓")}
              </div>
              <div className="w-[2px] h-[2px] bg-[#666b81] rounded-full"></div>
              <div
                className={`text-[10px] leading-[100%] font-sans font-medium ${
                  sortConfig.key === "rating"
                    ? "text-[#3677f6]"
                    : "text-[#666b81]"
                } whitespace-nowrap cursor-pointer`}
                onClick={() => handleSort("rating")}
              >
                {translations.ORDER_RA}{" "}
                {sortConfig.key === "rating" &&
                  (sortConfig.direction === "ascending" ? "↑" : "↓")}
              </div>
            </div>
          </div>

          {/* Content */}
          <div className="w-full flex flex-col items-start bg-[#fff] dark:bg-[#31333e] overflow-y-auto">
            {/* 카드 */}
            {sortedData.slice(0, visibleItems).map((item, index) => {
              // Check if `customizes` is defined and has at least 2 elements
              if (
                (item.customizes && item.customizes.length > 1) ||
                item.situations
              ) {
                return (
                  <div
                    key={index}
                    className="w-full flex flex-row items-start justify-between py-[14px] px-[16px] bg-[#fff] dark:bg-[#31333e] border border-[#e9ecef] dark:border-[#000] border-b-0"
                    onClick={() => handleGoStudy(item)}
                  >
                    {/* Titles */}
                    <div className="flex flex-col gap-[17px]">
                      <div className="flex flex-col gap-[12px]">
                        <div className="flex items-center">
                          {/* English title */}
                          <div className="text-[14px] leading-[100%] font-sans font-bold text-[#333] dark:text-[#fff] break-all">
                            {item.customizes
                              ? item.customizes.find(
                                  (customize) => customize.language === "kor"
                                )?.title
                              : item.situations.find(
                                  (customize) => customize.language === "kor"
                                )?.title}{" "}
                            {/* English title */}
                          </div>
                        </div>
                        {/* Korean title */}
                        <div className="text-[14px] leading-[100%] font-sans font-medium text-[#333] dark:text-[#fff] break-all">
                          {item.customizes
                            ? item.customizes.find(
                                (customize) => customize.language === "eng"
                              )?.title
                            : item.situations.find(
                                (customize) => customize.language === "eng"
                              )?.title}{" "}
                          {/* Korean title */}
                        </div>
                      </div>

                      {/* Tags */}
                      <div className="flex items-center gap-[3px]">
                        {item.tag &&
                          item.tag.map((tag, tagIndex) => (
                            <div
                              key={tagIndex}
                              className="flex items-center gap-[3px]"
                            >
                              <div className="text-[12px] leading-[100%] font-sans font-medium text-[#a9b6ca] whitespace-nowrap">
                                {data
                                  ? findItemById(tag.id, data3)?.name
                                  : "Loading..."}
                                {/* Display the tag id */}
                              </div>
                              {tagIndex < item.tag.length - 1 && (
                                <div className="w-[2px] h-[2px] bg-[#a9b6ca] rounded-full"></div>
                              )}
                            </div>
                          ))}
                      </div>
                    </div>

                    {/* Rating */}
                    <div className="flex items-center gap-[4px]">
                      <img
                        className={darkMode ? "filter: invert" : ""}
                        width="15"
                        height="15"
                        src={`star.png`}
                        alt="rating star"
                      />
                      <div className="text-[14px] leading-[100%] font-sans font-medium text-[#333] dark:text-[#fff] whitespace-nowrap">
                        {item.averageRating.toFixed(1)}{" "}
                        {/* Display the rating */}
                      </div>
                    </div>
                  </div>
                );
              }
              if (item.expressions && item.expressions.length > 1) {
                return (
                  <div
                    key={index}
                    className="w-full flex flex-row items-start justify-between py-[14px] px-[16px] bg-[#fff] dark:bg-[#31333e] border border-[#e9ecef] dark:border-[#000] border-b-0"
                    onClick={() => handleGoStudy(item)}
                  >
                    {/* Titles */}
                    <div className="flex flex-col gap-[17px]">
                      <div className="flex flex-col gap-[12px]">
                        <div className="flex items-center">
                          {/* English title */}
                          <div className="text-[14px] leading-[100%] font-sans font-bold text-[#333] dark:text-[#fff] break-all">
                            {
                              item.expressions.find(
                                (customize) => customize.language === "kor"
                              )?.expressive_language
                            }{" "}
                            {/* English title */}
                          </div>
                        </div>
                        {/* Korean title */}
                        <div className="text-[14px] leading-[100%] font-sans font-medium text-[#333] dark:text-[#fff] break-all">
                          {
                            item.expressions.find(
                              (customize) => customize.language === "eng"
                            )?.expressive_language
                          }{" "}
                          {/* Korean title */}
                        </div>
                      </div>

                      {/* <div className="flex items-center gap-[3px]">
                        {item.tag &&
                          item.tag.map((tag, tagIndex) => (
                            <div
                              key={tagIndex}
                              className="flex items-center gap-[3px]"
                            >
                              <div className="text-[12px] leading-[100%] font-sans font-medium text-[#a9b6ca] whitespace-nowrap">
                                {data
                                  ? findItemById(tag.id, data3)?.name
                                  : "Loading..."}
                              </div>
                              {tagIndex < item.tag.length - 1 && (
                                <div className="w-[2px] h-[2px] bg-[#a9b6ca] rounded-full"></div>
                              )}
                            </div>
                          ))}
                      </div> */}
                    </div>
                  </div>
                );
              }
            })}

            {/* Load More Button */}
            {visibleItems < data2.length && (
              <div
                className="flex justify-center text-center py-[14px] bg-[#fff] dark:bg-[#31333e]
 border border-[#e9ecef] dark:border-[#000] w-[100%]"
              >
                <button
                  onClick={loadMoreItems}
                  className="px-4 py-2 bg-[#007bff] text-white rounded"
                >
                  더보기
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookMark;
