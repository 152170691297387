import React from "react";
import { useNavigate } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi"; // Example for a modern icon

const RefundPolicy = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className="relative min-h-screen flex flex-col bg-gradient-to-b from-[#faf8f6] to-[#f0ece6] overflow-hidden">
      {/* Header */}
      <div className="flex items-center justify-between h-[60px] bg-[#faf8f6] shadow-md px-4">
        <div
          className="w-[36px] h-[36px] flex items-center justify-center cursor-pointer"
          onClick={handleGoBack}
        >
          <FiArrowLeft size={20} />
        </div>
        <div className="text-xl font-bold tracking-wide text-gray-700">
          환불 정책
        </div>
        <div className="w-[36px] h-[36px] flex items-center justify-center opacity-0">
          {/* Empty to balance layout */}
        </div>
      </div>

      {/* Content */}
      <div className="space-y-4 w-[90%] p-6">
        <p className="font-semibold">유의사항</p>
        <ul className="list-disc list-inside space-y-2">
          <li>구매 관련 자사 약관과 PG결제(나이스페이먼츠) 약관에 따릅니다.</li>
          <li>해당 이용권 결제와 동시에 서비스가 적용됩니다.</li>
          <li>최종 환불 금액이 1,000원 이하일 경우 환불하지 않습니다.</li>
          <li>
            이용권을 타인에게 양도하거나 이용기간 도중 불법행위를 하는 경우에는
            이용 제한이 될 수 있습니다.
          </li>
          <li>안내사항 중 일부는 회사 사정에 따라 변경될 수 있습니다.</li>
          <li>
            개인 귀책 사유로 이용 요금을 환불하는 경우 최초 결제 승인일로부터
            7일 이내에 환불/취소 신청이 가능합니다.
          </li>
          <li>동일 상품 전액 환불은 1회에 한합니다.</li>
        </ul>
        <p className="font-semibold">환불 관련 문의사항</p>
        <p>
          앱 내 1:1문의 또는{" "}
          <a
            href="mailto:ezbrainsoft@gmail.com"
            className="text-blue-500 underline"
          >
            ezbrainsoft@gmail.com
          </a>
        </p>
      </div>
    </div>
  );
};

export default RefundPolicy;
