import { useContext, useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LanguageContext } from "./LanguageContext";
import LanguageSwitcher from "./LanguageSwitcher";
import { useDispatch, useSelector } from "react-redux";
import BookmarkModal from "./BookmarkModal";
import LanguageSwitcher2 from "./LanguageSwitcher2";

const VocabList = () => {
  const { translations, language } = useContext(LanguageContext);
  const { expressionId } = useParams(); // useParams를 사용하여 URL 파라미터에서 expressionId를 가져옴
  const navigate = useNavigate();
  const [expressionData, setExpressionData] = useState(null);

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleLink = () => {
    window.location.href =
      "https://www.google.com/search?q=%EC%9D%B4%EC%A7%80%EB%B8%8C%EB%A0%88%EC%9D%B8%EC%86%8C%ED%94%84%ED%8A%B8&oq=%EC%9D%B4%EC%A7%80%EB%B8%8C%EB%A0%88%EC%9D%B8%EC%86%8C%ED%94%84%ED%8A%B8&gs_lcrp=EgZjaHJvbWUqCggAEAAY4wIYgAQyCggAEAAY4wIYgAQyDQgBEC4YrwEYxwEYgAQyCggCEAAYgAQYogQyCggDEAAYgAQYogQyBggFEEUYPDIGCAYQRRg8MgYIBxBFGDzSAQgzMDAzajBqN6gCALACAA&sourceid=chrome&ie=UTF-8";
  };

  const handleLanguageClick = () => {
    navigate("/langsetting");
  };

  const handleGoAssociated = () => {
    navigate(`/associated/${expressionData.category}`);
  };

  const handleErrorReport = () => {
    navigate("/error-action");
  };

  useEffect(() => {
    console.log("현재 언어:", language);
  }, [language]);

  useEffect(() => {
    const fetchExpressionData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/expressions/manage/${expressionId}`
        );
        const result = await response.json();
        setExpressionData(result);
      } catch (error) {
        console.error("데이터를 가져오는 중 오류가 발생했습니다.", error);
      }
    };

    if (expressionId) {
      fetchExpressionData();
    }
  }, [expressionId]);

  //overlay
  const [isOverlayVisible, setOverlayVisible] = useState(false);
  const overlayRef = useRef(null); // Ref for the overlay

  const toggleOverlay = () => {
    if (isOverlayVisible === false) {
      setOverlayVisible(!isOverlayVisible);
    } else {
      setOverlayVisible(!isOverlayVisible);
    }
  };

  const handleClickOutside = (event) => {
    if (overlayRef.current && !overlayRef.current.contains(event.target)) {
      setOverlayVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  //북마크
  const [selectedMark, setSelectedMark] = useState(false); // Default to 'E'
  const [showModal, setShowModal] = useState(false);
  const accessToken = sessionStorage.getItem("accessToken");
  useEffect(() => {
    console.log("매니지 id ", expressionId);
    const fetchData = async () => {
      const response = await fetch("https://pastellapp.com/api/bookmarks/", {
        method: "GET", // or 'POST' depending on your API
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json", // Include other headers if needed
        },
      });

      if (response.ok) {
        const data = await response.json();

        // Check if itemId 5 is in the results array
        const itemFound = data.results.some(
          (item) =>
            item.itemId === parseInt(expressionId, 10) &&
            item.itemType === "expression"
        );

        // Update the selectedMark state based on whether the item is found
        setSelectedMark(itemFound);
      } else {
        console.error("Failed to fetch data", response.status);
        // Handle the error as needed
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures this runs only once when the component mounts

  const updateBookmark = async () => {
    const postData = {
      itemId: parseInt(expressionId, 10),
      itemType: "expression",
    };

    const response = await fetch("https://pastellapp.com/api/bookmarks/", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json", // Ensure the server knows you're sending JSON
      },
      body: JSON.stringify(postData), // Convert the data to JSON string
    });

    if (response.ok) {
      const data = await response.json();
      console.log("Response data:", data);
    } else {
      console.error("Failed to post data", response.status);
      // Handle the error as needed
    }
  };

  const findId = async () => {
    const response = await fetch("https://pastellapp.com/api/bookmarks/", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      const data = await response.json();

      const itemFound = data.results.find(
        (item) =>
          item.itemId === parseInt(expressionId, 10) &&
          item.itemType === "expression"
      );

      if (itemFound) {
        console.log("found id ", itemFound.id);
        return new Promise((resolve) => {
          resolve(itemFound.id); // Resolve with the found id
        });
      } else {
        console.error("Item not found");
        return Promise.reject("Item not found"); // Reject the promise if not found
      }
    } else {
      console.error("Failed to fetch data", response.status);
      return Promise.reject("Failed to fetch data"); // Reject the promise if fetch fails
    }
  };

  const deleteBookmark = async (id) => {
    const response = await fetch(`https://pastellapp.com/api/bookmarks/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json", // Ensure the server knows you're sending JSON
      },
    });

    if (response.ok) {
      const data = await response.json();
      console.log("Response data2:", data);
    } else {
      console.error("Failed to post data", response.status);
      // Handle the error as needed
    }
  };

  const handleMarkAction = async () => {
    if (!selectedMark) {
      await updateBookmark();
      setSelectedMark(true);
      setShowModal(true);
    } else {
      try {
        const id = await findId(); // Wait for findId to resolve with the bookmarkId
        await deleteBookmark(id); // Pass the bookmarkId to deleteBookmark
        setSelectedMark(false);
      } catch (error) {
        console.error("An error occurred:", error);
      }
    }
  };

  const getMarkImage = () => {
    switch (selectedMark) {
      case false:
        return "/bookmark.png";
      case true:
        return "/bookmark2.png";
      default:
        return "/bookmark.png"; // Default image
    }
  };

  //북마크 end

  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("theme") === "dark"
  );

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add("dark");
      localStorage.setItem("theme", "dark");
    } else {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("theme", "light");
    }
  }, [darkMode]);

  if (!expressionData) {
    return <div>로딩 중...</div>;
  }

  const korExpression = expressionData.expressions.find(
    (exp) => exp.language === "kor"
  );
  const engExpression = expressionData.expressions.find(
    (exp) => exp.language === "eng"
  );

  if (!korExpression || !engExpression) {
    return <div>해당 언어의 표현을 찾을 수 없습니다.</div>;
  }

  return (
    <div className="flex min-h-screen justify-center w-full bg-[#faf8f6] dark:bg-[#121418] overflow-x-hidden">
      {isOverlayVisible && (
        <div className="absolute w-full h-full bg-black opacity-50 z-50"></div>
      )}
      <div className="flex flex-col w-[360px] bg-[#faf8f6] dark:bg-[#121418]">
        {/* Header */}
        <div className="flex items-center h-[50px] top-[42px] pl-[10px] pr-[4px] bg-[#faf8f6] dark:bg-[#121418]">
          {/* 뒤로가기 버튼 */}
          <img
            className={`mr-auto ${darkMode ? "filter invert" : ""}`}
            width="8"
            height="16"
            src="/back.png"
            alt="뒤로가기"
            onClick={handleGoBack}
          />
          {/* 제목 */}
          <div className="absolute left-1/2 transform -translate-x-1/2 font-sans text-[16px] dark:text-[#fff] font-bold">
            단어장
          </div>
          <div className="flex items-center">
            {/* 링크 버튼 */}
            <img
              className={`w-[24px] h-[24px] ml-[8px] ${
                darkMode ? "filter invert" : ""
              }`}
              src="/link2.png"
              alt="링크"
              onClick={handleLink}
            />
            {/* 오류신고 팝업 버튼 */}
            <img
              className={`w-[28px] h-[28px] ml-[4px] ${
                darkMode ? "filter invert" : ""
              }`}
              src="/ddd2.png"
              alt="오류신고"
              onClick={toggleOverlay}
            />
          </div>
        </div>

        {/* 본문 */}
        <div className="flex flex-col mt-[18px] p-[16px] bg-white dark:bg-[#22232a] rounded-lg shadow-lg">
          <div className="flex justify-between items-center mb-[8px]">
            <div className="flex flex-wrap gap-[6px]">
              {expressionData.academic_english && (
                <div className="bg-[#FFEEE7] rounded-[11px] h-[18px] px-[6px] py-[4px] items-center justify-center flex">
                  <span className="text-[10px] font-medium text-[#F67239]">
                    Academic English
                  </span>
                </div>
              )}
              <div className="bg-[#E2E8EF] rounded-[11px] h-[18px] px-[6px] py-[4px] items-center justify-center flex">
                <span className="text-[10px] font-medium text-[#383C4B] ">
                  {translations[expressionData.category] ||
                    expressionData.category}
                </span>
              </div>
            </div>
            <div className="absolute top-[60px] right-[0px] bg-[#faf8f6] dark:bg-[#121418] w-[60px] h-[60px] flex items-center justify-center rounded-bl-[24px]">
              {darkMode ? (
                <LanguageSwitcher2></LanguageSwitcher2>
              ) : (
                <LanguageSwitcher></LanguageSwitcher>
              )}
            </div>
          </div>
          <h2 className="text-[32px] font-bold text-[#25272F] dark:text-[#fff] mb-[8px]">
            {language === "eng"
              ? korExpression.expressive_language
              : engExpression.expressive_language}
          </h2>
          <p className="text-[14px] font-medium text-[#A9B6CA] mb-[16px]">
            {language === "kor"
              ? korExpression.expressive_language_mean
              : engExpression.expressive_language_mean}
          </p>
          <div className="border-t border-[#E9ECEF] pt-[16px]">
            <p className="text-[14px] font-medium mb-[4px] dark:text-[#fff]">
              {language === "eng"
                ? korExpression.example_sentence
                : engExpression.example_sentence}
            </p>
            <p className="text-[12px] font-medium text-[#A9B6CA]">
              {language === "kor"
                ? korExpression.example_sentence_mean
                : engExpression.example_sentence_mean}
            </p>
          </div>
        </div>

        {/* 유의어 */}
        <div className="mt-[16px] p-[16px] bg-white dark:bg-[#22232a] rounded-lg">
          <h3 className="text-[14px] font-bold mb-[8px] dark:text-[#fff]">
            유의어
          </h3>
          <p className="text-[14px] font-medium mb-[4px] dark:text-[#fff]">
            {language === "eng"
              ? korExpression.synonyms
              : engExpression.synonyms}
          </p>
          <p className="text-[14px] font-medium text-[#A9B6CA]">
            {language === "kor"
              ? korExpression.synonyms_mean
              : engExpression.synonyms_mean}
          </p>
        </div>

        {/* 반의어 */}
        <div className="mt-[16px] p-[16px] bg-white dark:bg-[#22232a] rounded-lg">
          <h3 className="text-[14px] font-bold mb-[8px] dark:text-[#fff]">
            반의어
          </h3>
          <p className="text-[14px] font-medium mb-[4px] dark:text-[#fff]">
            {language === "eng"
              ? korExpression.antonyms
              : engExpression.antonyms}
          </p>
          <p className="text-[14px] font-medium text-[#A9B6CA]">
            {language === "kor"
              ? korExpression.antonyms_mean
              : engExpression.antonyms_mean}
          </p>
        </div>

        {/* 연계학습하기 버튼 */}
        <button
          className="mt-[24px] w-full py-[14px] px-[20px] bg-[#faf8f6] dark:bg-[#121418] border border-[#383C4B] dark:border-[#faf8f6] rounded-[12px] text-[14px] font-medium text-[#383C4B] dark:text-[#faf8f6] mb-8"
          onClick={handleGoAssociated}
        >
          연계학습하기
        </button>

        <div
          className="relative w-[50px] h-[70px] shrink-0 mb-[30px]"
          onClick={handleMarkAction}
        >
          <div className="absolute left-[70vw] top-1/2 w-[70px] h-[70px] bg-[#fff] dark:bg-[#22232a] rounded-full transform -translate-y-1/2 flex justify-center items-center">
            <img
              className="relative"
              width="20"
              height="40"
              src={getMarkImage()}
            />
          </div>
        </div>

        {/* Overlay */}
        <div
          ref={overlayRef}
          className={`fixed bottom-0 left-0 w-full bg-white transition-transform duration-300 ease-in-out ${
            isOverlayVisible
              ? "transform translate-y-0"
              : "transform translate-y-full"
          }`}
          style={{
            height: "20%",
            zIndex: 100,
            borderTopLeftRadius: "30px", // Adjust the radius value as needed
            borderTopRightRadius: "30px", // Adjust the radius value as needed
          }}
        >
          {/* Content of the overlay */}
          <div className="flex flex-col h-full">
            {/* Text Container 1 */}
            <div
              className="flex flex-wrap justify-center items-center flex-grow font-sans font-bold"
              onClick={handleLanguageClick}
            >
              언어 설정
            </div>

            {/* Divider Line */}
            <div className="w-full border-t border-[#e9ecef]"></div>

            {/* Text Container 2 */}
            <div
              className="flex flex-wrap justify-center items-center flex-grow font-sans font-bold"
              onClick={handleErrorReport}
            >
              오류 신고
            </div>
          </div>
        </div>
        <BookmarkModal show={showModal} onClose={() => setShowModal(false)} />
      </div>
    </div>
  );
};

export default VocabList;
