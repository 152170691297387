import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import GnbExpress from "./components/GnbExpress";
import LanguageSwitcher from "./LanguageSwitcher";
import { LanguageContext } from "./LanguageContext";
import LanguageSwitcher2 from "./LanguageSwitcher2";
import { IoIosArrowBack } from "react-icons/io";

const Associated = () => {
  const { language } = useContext(LanguageContext);
  const { category } = useParams();
  const displayLanguage = language === "kor" ? "eng" : "kor";
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [selectKorEng, setSelectKorEng] = useState({});
  const observer = useRef();
  const navigate = useNavigate();
  const [sortConfig, setSortConfig] = useState({
    key: "latest",
    direction: "ascending",
  });
  const [sortedData, setSortedData] = useState([]);

  const lastElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const fetchData = useCallback(async () => {
    if (loading || !hasMore) return;
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/expressions/managerFilter?category=${category}&page=${page}&limit=10`
      );
      const result = await response.json();
      if (result.data.length > 0) {
        setData((prevData) => {
          // 중복 제거 로직
          const newData = [...prevData, ...result.data];
          const uniqueData = newData.filter(
            (item, index, self) =>
              index === self.findIndex((t) => t.id === item.id)
          );
          return uniqueData;
        });
        setHasMore(result.data.length === 10);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      toast.error("데이터를 가져오는 중 오류가 발생했습니다.");
    } finally {
      setLoading(false);
    }
  }, [category, page]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const fetchSelectKorEng = async () => {
    try {
      const response = await fetch("/select_kor_eng.json");
      const result = await response.json();
      console.log(result);
      setSelectKorEng(result);
    } catch (error) {
      toast.error(
        "select_kor_eng.json 파일을 불러오는 중 오류가 발생했습니다."
      );
    }
  };

  useEffect(() => {
    fetchData();
    fetchSelectKorEng();
  }, [fetchData]);

  const formatEnglishCategory = (category) => {
    return category
      .toLowerCase()
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const getCategoryName = (category) => {
    if (selectKorEng.expression) {
      const entries = Object.entries(selectKorEng.expression);
      for (const [kor, eng] of entries) {
        if (eng === category) {
          return language === "kor" ? kor : formatEnglishCategory(eng);
        }
      }
    }
    return language === "kor" ? category : formatEnglishCategory(category);
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleItemClick = (expressionId) => {
    navigate(`/vocablist/${expressionId}`);
  };

  const getExpressionByLanguage = (expressions, lang) => {
    return (
      expressions?.find((exp) => exp.language === lang) || expressions?.[0]
    );
  };

  const sortData = useCallback(
    (dataToSort) => {
      const sorted = [...dataToSort];
      if (sortConfig.key === "latest") {
        sorted.sort((a, b) => {
          return sortConfig.direction === "ascending"
            ? a.id - b.id
            : b.id - a.id;
        });
      } else if (sortConfig.key === "alphabetical") {
        sorted.sort((a, b) => {
          const aTitle = getExpressionByLanguage(
            a.expressions,
            displayLanguage
          ).expressive_language;
          const bTitle = getExpressionByLanguage(
            b.expressions,
            displayLanguage
          ).expressive_language;
          return sortConfig.direction === "ascending"
            ? aTitle.localeCompare(bTitle)
            : bTitle.localeCompare(aTitle);
        });
      }
      return sorted;
    },
    [sortConfig, displayLanguage]
  );

  const handleSort = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction:
        prevConfig.key === key && prevConfig.direction === "ascending"
          ? "descending"
          : "ascending",
    }));
  };

  useEffect(() => {
    setSortedData(sortData(data));
  }, [data, sortData]);

  const translations = {
    ASSOCIATED_LEARNING: "연계학습",
    ORDER_DATE: "최신순",
    ORDER_AL: "알파벳순",
  };

  useEffect(() => {
    if (data.length > 0) {
      console.log("로드된 데이터:", data);
    }
  }, [data]);

  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("theme") === "dark"
  );

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add("dark");
      localStorage.setItem("theme", "dark");
    } else {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("theme", "light");
    }
  }, [darkMode]);

  return (
    <div className="flex min-h-screen justify-center w-full bg-[#faf8f6] dark:bg-[#121418]">
      <div className="relative w-[100%] bg-[#faf8f6] dark:bg-[#121418]">
        {/* Header */}
        <div className="fixed top-0 left-0 right-0 flex items-center h-[50px] px-[14px] py-[7px] bg-[#faf8f6] dark:bg-[#121418] z-10">
          <div
            className="w-[36px] h-[36px] shrink-0 flex flex-row items-center justify-start"
            onClick={handleGoBack}
          >
            <IoIosArrowBack
              color={darkMode ? "white" : "black"}
              size={22}
              onClick={handleGoBack}
            />
          </div>
          <div className="absolute left-1/2 transform -translate-x-1/2 font-sans text-[16px] dark:text-[#fff] font-bold">
            {translations.ASSOCIATED_LEARNING}
          </div>
        </div>

        {/* 정렬 옵션 */}
        <div className="mt-[60px] px-[16px] flex justify-end">
          <div className="flex flex-row items-center gap-[5px]">
            <div
              className={`text-[10px] leading-[100%] font-sans font-medium ${
                sortConfig.key === "latest"
                  ? "text-[#3677f6]"
                  : "text-[#666b81]"
              } text-right whitespace-nowrap cursor-pointer`}
              onClick={() => handleSort("latest")}
            >
              {translations.ORDER_DATE}{" "}
              {sortConfig.key === "latest" &&
                (sortConfig.direction === "ascending" ? "↑" : "↓")}
            </div>
            <div className="w-[2px] h-[2px] shrink-0 bg-[#666b81] rounded-full"></div>
            <div
              className={`text-[10px] leading-[100%] font-sans font-medium ${
                sortConfig.key === "alphabetical"
                  ? "text-[#3677f6]"
                  : "text-[#666b81]"
              } whitespace-nowrap cursor-pointer`}
              onClick={() => handleSort("alphabetical")}
            >
              {translations.ORDER_AL}{" "}
              {sortConfig.key === "alphabetical" &&
                (sortConfig.direction === "ascending" ? "↑" : "↓")}
            </div>
          </div>
        </div>

        {/* 본문 */}
        <div className="mt-[10px] pb-[20px] ">
          {sortedData.map((item, index) => (
            <div
              key={`${item.id}-${index}`} // 고유한 key 사용
              ref={index === sortedData.length - 1 ? lastElementRef : null}
              className="mb-[16px] mx-[16px] p-[16px] bg-white dark:bg-[#31333e] rounded-lg shadow-sm"
              onClick={() => handleItemClick(item.id)}
            >
              {/* Academic English 및 카테고리 */}
              <div className="flex flex-wrap gap-[6px] mb-[8px]">
                {item.academic_english && (
                  <div className="bg-[#FFEEE7] rounded-[11px] h-[18px] px-[6px] py-[4px] items-center justify-center flex">
                    <span className="text-[10px] font-medium text-[#F67239]">
                      Academic English
                    </span>
                  </div>
                )}
                <div className="bg-[#E2E8EF] rounded-[11px] h-[18px] px-[6px] py-[4px] items-center justify-center flex">
                  <span className="text-[10px] font-medium text-[#383C4B]">
                    {getCategoryName(item.category)}
                  </span>
                </div>
              </div>

              {/* 표현 및 의미 */}
              <div className="text-left">
                <h2 className="font-[Pretendard] font-[700] text-[14px] dark:text-[#fff]">
                  {
                    getExpressionByLanguage(item.expressions, displayLanguage)
                      .expressive_language
                  }
                </h2>
                <p className="font-[Pretendard] font-[500] text-[14px] dark:text-[#fff]">
                  {
                    getExpressionByLanguage(item.expressions, language)
                      .expressive_language_mean
                  }
                </p>
              </div>

              {/* 태그 */}
              {item.tags && item.tags.length > 0 && (
                <div className="font-[Pretendard] font-[500] text-[12px] text-[#A9B6CA] mt-[8px]">
                  {item.tags.join(", ")}
                </div>
              )}
            </div>
          ))}
          {loading && <div>로딩 중...</div>}
        </div>
      </div>
    </div>
  );
};

export default Associated;
