import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TextField from "../logincomponents/PhoneVerification";
import Title from "../logincomponents/Title";
import BBtn from "../logincomponents/BBtn";
import OptionalTextField from "../logincomponents/OptionalTextField";
import ReactiveOuter from "../logincomponents/ReactiveOuter";
import FormTop from "../logincomponents/FormTop";
import FormForm from "../logincomponents/FormForm";
import TBtn from "../logincomponents/TBtn";
import Btn from "../logincomponents/Btn";
import BBtn2 from "../logincomponents/BBtn2";

const ID1 = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const foundUserId = location.state?.userId || "";
  const phoneNumber =location.state?.phoneNumber || "";
  const handlePasswordReset = () => {
    navigate("/lost-pw1", { state: { userId: foundUserId, phoneNumber: phoneNumber } });
  };

  return (
    <ReactiveOuter>
      <FormTop>
        <Title
          titleGap="78px"
          frameDivWidth="unset"
          prop="아이디 찾기"
          bAlignSelf="unset"
          bHeight="unset"
          bOpacity="unset"
          bMinWidth="101px"
          bDisplay="inline-block"
        />
        <FormForm>
          <OptionalTextField 
            prop="아이디" 
            value={foundUserId} 
            readOnly={true}
            onChange={() => {}} // 빈 함수 추가
            style={{ backgroundColor: "#E2E8EF" }}
            disabled={true}
          />
        </FormForm>
      </FormTop>
      <div className="fixed bottom-[5vh] w-full">
        <BBtn2 prop={"비밀번호 찾기"} onClick={handlePasswordReset} />
        <div className="h-[10px]"></div>
        <BBtn prop={"로그인하기"} to="/login1" />
      </div>
    </ReactiveOuter>
  );
};

export default ID1;
