import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Navigation from '../../components/admin/Navigation';

const AdminEditor = () => {
  const navigate = useNavigate();

  const [adminData, setAdminData] = useState({
    id: '',
    pw: '',
    pwConfirm: '',
    creationDate: '',
    userCode: '',
  });

  const [permissions, setPermissions] = useState({
    work: {
      overview: false,
      customize: false,
      situation: false,
      psychology: false,
      expression: false,
    },
    express: {
      overview: false,
      expression: false,
    },
    memberManagement: false,
    productManagement: false,
    paymentManagement: false,
    cs: {
      notice: false,
      faq: false,
      inquiry: false,
    },
    appSetting: false,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAdminData(prev => ({ ...prev, [name]: value }));
  };

  const handlePermissionChange = (category, subcategory = null) => {
    setPermissions(prev => {
      if (subcategory) {
        return {
          ...prev,
          [category]: {
            ...prev[category],
            [subcategory]: !prev[category][subcategory]
          }
        };
      } else {
        return { ...prev, [category]: !prev[category] };
      }
    });
  };

  const renderPermissionButton = (category, label, subcategory = null) => {
    const isActive = subcategory ? permissions[category][subcategory] : permissions[category];
    return (
      <button
        onClick={() => handlePermissionChange(category, subcategory)}
        className={`mb-2 mr-2 ${isActive ? 'bg-[#E3EDFF] border-[#3677F6]' : 'bg-white border-[#E2E8EF]'}`}
        style={{
          height: '42px',
          padding: '14px 24px',
          borderRadius: '59px',
          border: '1px solid',
          fontSize: '14px',
          fontWeight: 500,
          color: isActive ? '#3677F6' : '#666B81',
          transition: 'all 0.3s ease',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          whiteSpace: 'nowrap',
        }}
      >
        {label}
      </button>
    );
  };

  return (
    <div className="min-h-screen bg-[#FAF8F6] p-6">
      <Navigation />
      <div className="flex justify-between items-center mb-5">
        <h1 
          className="text-2xl font-bold text-left"
          style={{
            fontFamily: 'Pretendard',
            fontSize: '24px',
            fontWeight: 700,
          }}
        >
          관리자 계정 생성
        </h1>
        <div className="flex space-x-4">
          <button
            onClick={() => navigate('/admin-list')}
            className="w-[100px] h-[48px] bg-[#666B81] text-white"
            style={{
              fontFamily: 'Pretendard',
              fontSize: '14px',
              fontWeight: 700,
              borderRadius: '12px'
            }}
          >
            목록가기
          </button>
          <button 
            className="w-[100px] h-[48px] bg-[#3677F6] text-white"
            style={{
              fontFamily: 'Pretendard',
              fontSize: '14px',
              fontWeight: 700,
              borderRadius: '12px'
            }}
          >
            저장하기
          </button>
        </div>
      </div>

      <div className="bg-white rounded-lg p-6 shadow-md mb-10">
        <h2 
          className="text-xl mb-4"
          style={{
            fontFamily: 'Pretendard',
            fontSize: '18px',
            fontWeight: 700,
            color: '0E111F',
          }}
        >
          구분
        </h2>
        <div className="space-y-4">
          <div className="flex gap-4">
            <div className="flex-1">
              <label 
                className="block text-sm font-bold mb-2"
                style={{
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontWeight: 700,
                  color: '0E111F',
                }}
              >
                ID
              </label>
              <input
                type="text"
                name="id"
                value={adminData.id}
                onChange={handleInputChange}
                className="w-full bg-white border border-[#E2E8EF]"
                style={{
                  height: '48px',
                  padding: '18px 14px',
                  borderRadius: '12px',
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontWeight: 500,
                }}
              />
            </div>
            <div className="flex-1">
              <label 
                className="block text-sm font-bold mb-2"
                style={{
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontWeight: 700,
                  color: '0E111F',
                }}
              >
                PW
              </label>
              <input
                type="password"
                name="pw"
                value={adminData.pw}
                onChange={handleInputChange}
                className="w-full bg-white border border-[#E2E8EF]"
                style={{
                  height: '48px',
                  padding: '18px 14px',
                  borderRadius: '12px',
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontWeight: 500,
                }}
              />
            </div>
            <div className="flex-1">
              <label 
                className="block text-sm font-bold mb-2"
                style={{
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontWeight: 700,
                  color: '0E111F',
                }}
              >
                PW 확인
              </label>
              <input
                type="password"
                name="pwConfirm"
                value={adminData.pwConfirm}
                onChange={handleInputChange}
                className="w-full bg-white border border-[#E2E8EF]"
                style={{
                  height: '48px',
                  padding: '18px 14px',
                  borderRadius: '12px',
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontWeight: 500,
                }}
              />
            </div>
          </div>
          <div className="flex gap-4">
            <div className="flex-1">
              <label 
                className="block text-sm font-bold mb-2"
                style={{
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontWeight: 700,
                  color: '0E111F',
                }}
              >
                생성일시
              </label>
              <input
                type="text"
                name="creationDate"
                value={adminData.creationDate}
                readOnly
                className="w-full bg-[#E2E8EF]"
                style={{
                  height: '48px',
                  padding: '18px 14px',
                  borderRadius: '12px',
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontWeight: 500,
                  border: 'none',
                }}
              />
            </div>
            <div className="flex-1">
              <label 
                className="block text-sm font-bold mb-2"
                style={{
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontWeight: 700,
                  color: '0E111F',
                }}
              >
                유저 코드
              </label>
              <input
                type="text"
                name="userCode"
                value={adminData.userCode}
                readOnly
                className="w-full bg-[#E2E8EF]"
                style={{
                  height: '48px',
                  padding: '18px 14px',
                  borderRadius: '12px',
                  fontFamily: 'Pretendard',
                  fontSize: '14px',
                  fontWeight: 500,
                  border: 'none',
                }}
              />
            </div>
          </div>
        </div>
      </div>

      {/* 권한 설정 섹션 */}
      <div className="bg-white rounded-lg p-6 shadow-md mb-10">
        <h2 
          className="text-xl mb-4"
          style={{
            fontFamily: 'Pretendard',
            fontSize: '18px',
            fontWeight: 700,
            color: '0E111F',
          }}
        >
          권한 설정
        </h2>
        <div className="space-y-6">
          <div className="flex gap-2">
            <div>
              <h3 className="font-bold mb-3.5">작업</h3>
              <div className="flex flex-wrap gap-1.5 border-r border-[#E2E8EF] pr-4">
                {renderPermissionButton('work', 'OVERVIEW', 'overview')}
                {renderPermissionButton('work', 'CUSTOMIZE', 'customize')}
                {renderPermissionButton('work', 'SITUATION', 'situation')}
                {renderPermissionButton('work', 'PSYCHOLOGY', 'psychology')}
              </div>
            </div>
            <div className="pl-4">
              <h3 className="font-bold mb-3.5">표현하기</h3>
              <div className="flex flex-wrap gap-1.5">
                {renderPermissionButton('express', 'OVERVIEW', 'overview')}
                {renderPermissionButton('express', 'EXPRESSION', 'expression')}
              </div>
            </div>
          </div>
          <div className="flex gap-2">
            <div>
              <h3 className="font-bold mb-3.5">회원관리</h3>
              <div className="flex flex-wrap gap-1.5 border-r border-[#E2E8EF] pr-4">
                {renderPermissionButton('memberManagement', '작업관리')}
              </div>
            </div>
            <div className="pl-4">
              <h3 className="font-bold mb-3.5">상품관리</h3>
              <div className="flex flex-wrap gap-1.5 border-r border-[#E2E8EF] pr-4">
                {renderPermissionButton('productManagement', '상품관리')}
              </div>
            </div>
            <div className="pl-4">
              <h3 className="font-bold mb-3.5">결제관리</h3>
              <div className="flex flex-wrap gap-1.5">
                {renderPermissionButton('paymentManagement', '결제관리')}
              </div>
            </div>
          </div>
          <div className="flex gap-2">
            <div>
              <h3 className="font-bold mb-3.5">CS관리</h3>
              <div className="flex flex-wrap gap-1.5 border-r border-[#E2E8EF] pr-4">
                {renderPermissionButton('cs', '공지사항', 'notice')}
                {renderPermissionButton('cs', 'FAQ', 'faq')}
                {renderPermissionButton('cs', '오류신고', 'inquiry')}
              </div>
            </div>
            <div className="pl-4">
              <h3 className="font-bold mb-3.5">앱설정</h3>
              <div className="flex flex-wrap gap-1.5">
                {renderPermissionButton('appSetting', '앱설정')}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminEditor;
