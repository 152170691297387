import React, { useState } from "react";
import OptionalTextField from "../logincomponents/OptionalTextField";
import BBtn from "../logincomponents/BBtn";
import PhoneVerification from "../logincomponents/PhoneVerification";
import ReactiveOuter from "../logincomponents/ReactiveOuter";
import FormTop from "../logincomponents/FormTop";
import Title from "../logincomponents/Title";
import FormForm from "../logincomponents/FormForm";
import MustTextField from "../logincomponents/MustTextField";
import styles from "./SocialLogin.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const SocialLogin = () => {
  const location = useLocation();
  const { email } = location.state || {};
  const navigate = useNavigate();

  // State for form fields
  const [userId, setUserId] = useState(email || ""); // 아이디
  const [name, setName] = useState(""); // 이름
  const [password, setPassword] = useState(""); // 비밀번호
  const [confirmPassword, setConfirmPassword] = useState(""); // 비밀번호 확인
  const [recommender, setRecommender] = useState(""); // 추천인 계정 입력
  const [phoneNumber, setPhoneNumber] = useState(""); // 전화번호 (set inside PhoneVerification)
  const [ageConsent, setAgeConsent] = useState(false);
  const [marketingConsent, setMarketingConsent] = useState(false); // 마케팅 수신 동의 (선택)

  const handleConditions = () => {
    navigate("/conditions");
  };
  const handleTerms = () => {
    navigate("/terms");
  };

  const policyStyle = {
    fontSize: "12px",
    lineHeight: "1.5",
    textAlign: "center",
    margin: "10px auto",
  };

  // Function to format the phone number
  const formatPhoneNumber = (value) => {
    const cleaned = value.replace(/\D/g, "");
    if (cleaned.length === 11) {
      return `+82${cleaned.substring(1)}`;
    }
    return `+82${cleaned}`;
  };

  const validatePassword = () => {
    const passwordRegex =
      /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,15}$/;

    if (!password) {
      const toastId = "pass-error"; // Unique ID for the toast
      if (!toast.isActive(toastId)) {
        // Check if the toast is already active
        toast.error("비밀번호를 입력해주세요.", {
          position: "top-center",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          toastId: toastId, // Assign the unique toastId
          progress: undefined,
        });
      }
      return false;
    }

    if (!passwordRegex.test(password)) {
      const toastId = "pass-error"; // Unique ID for the toast
      if (!toast.isActive(toastId)) {
        // Check if the toast is already active
        toast.error(
          "비밀번호는 영문, 숫자, 특수문자 조합으로 8자~15자 이내여야 합니다.",
          {
            position: "top-center",
            autoClose: 500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            toastId: toastId, // Assign the unique toastId
            progress: undefined,
          }
        );
      }
      return false;
    }

    if (password !== confirmPassword) {
      const toastId = "pass-error"; // Unique ID for the toast
      if (!toast.isActive(toastId)) {
        // Check if the toast is already active
        toast.error("비밀번호가 일치하지 않습니다.", {
          position: "top-center",
          autoClose: 500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          toastId: toastId, // Assign the unique toastId
          progress: undefined,
        });
      }
      return false;
    }

    return true;
  };

  // Function to update profile with HTTP POST
  const apiUrl = process.env.REACT_APP_API_URL;
  const updateProfile = async () => {
    if (!validatePassword()) {
      return; // Stop if validation fails
    }
    const formattedPhoneNumber = formatPhoneNumber(phoneNumber);
    console.log(userId);
    console.log(name);
    console.log(password);
    console.log(recommender);
    console.log(marketingConsent);
    console.log(formattedPhoneNumber);
    const data = {
      userid: userId,
      loginType: "local",
      username: name,
      password: password,
      recommender: recommender,
      marketingConsent: marketingConsent,
      phoneNumber: formattedPhoneNumber,
    };

    try {
      console.log("data", data);
      const response = await fetch(`${apiUrl}/auth/signup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        const result = await response.json();
        console.log("Profile updated successfully:", result);
        navigate("/nickname", {
          state: { userId },
        });
      } else {
        console.error("Failed to update profile:", response.statusText);
        alert("Failed to update profile.");
      }
    } catch (error) {
      console.error("Error during the request:", error);
      alert("An error occurred while updating the profile.");
    }
  };

  const [loading, setLoading] = useState(false);

  const handlePhoneAuth = async () => {
    setLoading(true);
    try {
      // 백엔드에서 암호화된 데이터를 받아오기 위한 요청
      const response = await fetch(`${apiUrl}/auth/nice`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();

      // 받은 데이터에서 필요한 값들 추출
      const { tokenVersionId, encData, integrityValue } = data;
      console.log("data  ~~~~~    ", data);
      console.log(tokenVersionId);
      console.log(encData);
      console.log(integrityValue);

      if (tokenVersionId && encData && integrityValue) {
        // 나이스 본인인증 팝업 창 열기
        window.open(
          "https://nice.checkplus.co.kr/CheckPlusSafeModel/service.cb",
          "popupChk",
          "width=500,height=550,top=100,left=100"
        );
        // form 태그를 통해 팝업 창에 인증 데이터를 전송
        document.getElementById("token_version_id").value = tokenVersionId;
        document.getElementById("enc_data").value = encData;
        document.getElementById("integrity_value").value = integrityValue;

        // form 제출하여 나이스 인증 팝업 창으로 데이터 전송
        document.getElementById("niceForm").submit();
      } else {
        alert("본인인증 요청에 실패했습니다.");
      }
    } catch (error) {
      console.error("본인인증 요청 중 오류 발생:", error);
      alert("본인인증 요청 중 오류가 발생했습니다.");
    } finally {
      setLoading(false);
    }
  };

  const openNiceWindow = async () => {
    const { form } = document;
    // const res = await API.get(`${a}`, { returnUrl });
    const res = await axios.get("https://pastellapp.com/api/auth/nice");

    if (form && res.data) {
      const { tokenVersionId, encData, integrityValue } = res.data;
      // window.open("", "nicePopup", option);
      // form.target = "nicePopup";
      form.enc_data.value = encData;
      form.token_version_id.value = tokenVersionId;
      form.integrity_value.value = integrityValue;
      form.submit();
    }
  };

  return (
    <div className="flex min-h-screen justify-center w-full bg-[#fff]">
      <div className="relative min-x-[360px] w-full bg-[#fff]">
        <ReactiveOuter>
          <FormTop>
            <Title
              titleGap="40%"
              frameDivWidth="unset"
              prop="회원가입"
              bAlignSelf="unset"
              bHeight="unset"
              bOpacity="unset"
              bMinWidth="101px"
              bDisplay="inline-block"
            />
            <FormForm>
              <OptionalTextField
                prop="아이디"
                prop1="아이디"
                value={userId}
                onChange={(e) => setUserId(e.target.value)} // Set 아이디 in state
              />
              <MustTextField
                prop="이름"
                prop1="이름을 입력하세요"
                value={name}
                onChange={(e) => setName(e.target.value)} // Set 이름 in state
              />
              <PhoneVerification
                phoneNumber={phoneNumber} // Pass phoneNumber state
                onPhoneNumberChange={setPhoneNumber}
                onClick={openNiceWindow}
              ></PhoneVerification>
              {/* Assuming this component sets phone number */}
              <MustTextField
                prop="비밀번호"
                prop1="영문, 숫자, 특수문자 조합 8자~15자 이내"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)} // Set 비밀번호 in state
              />
              <MustTextField
                prop="비밀번호 확인"
                prop1="비밀번호를 한번 더 입력해주세요"
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)} // Set 비밀번호 확인 in state
              />
              <OptionalTextField
                prop="추천인 계정 입력"
                prop1="이름"
                value={recommender}
                onChange={(e) => setRecommender(e.target.value)} // Set 추천인 계정 입력 in state
              />
              <div className="flex flex-col gap-4 w-full">
                <div className="flex items-center justify-between">
                  <b className="mr-2">
                    만 14세 이상입니다{" "}
                    <span style={{ color: "var(--color-tomato)" }}>*</span>
                  </b>
                  <input
                    type="checkbox"
                    checked={ageConsent}
                    onChange={(e) => setAgeConsent(e.target.checked)} // Set 만 14세 이상입니다 in state
                  />
                </div>
                <div className="flex items-center justify-between">
                  <b className="mr-2">마케팅 수신 동의(선택)</b>
                  <input
                    type="checkbox"
                    checked={marketingConsent}
                    onChange={(e) => setMarketingConsent(e.target.checked)} // Set 마케팅 수신 동의(선택) in state
                  />
                </div>
              </div>
            </FormForm>
            <div className="bottom-[5vh] w-full mt-[5vh]">
              <div style={policyStyle}>
                <a className="underline" onClick={handleConditions}>
                  <b>이용 약관</b>
                </a>
                과{" "}
                <a className="underline" onClick={handleTerms}>
                  <b>개인정보 처리방침</b>
                </a>
                에 동의시 회원가입을 진행해주세요
              </div>
              <BBtn prop={"회원가입"} onClick={updateProfile} />{" "}
              {/* Call updateProfile on button click */}
            </div>
          </FormTop>
        </ReactiveOuter>
        <div>
          <h1>테스트</h1>
          <button onClick={openNiceWindow} disabled={loading}>
            {loading ? "본인인증 진행 중..." : "휴대폰 본인인증"}
          </button>
        </div>
      </div>
      <ToastContainer />
      <form
        name="form"
        id="form"
        action="https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb"
      >
        <input type="hidden" id="m" name="m" value="service" />
        <input
          type="hidden"
          id="token_version_id"
          name="token_version_id"
          value=""
        />
        <input type="hidden" id="enc_data" name="enc_data" />
        <input type="hidden" id="integrity_value" name="integrity_value" />
      </form>
    </div>
  );
};

export default SocialLogin;
