import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import axios from 'axios';
import Navigation from '../../components/admin/Navigation';
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';
import API_BASE_URL from '../../config';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import calendarIcon from '../../assets/admin/icons/calendar.png';
import handleSortIcon from '../../assets/admin/icons/handleSortIcon.png';
import leftCursor from '../../assets/admin/icons/leftCursor.png';
import rightCursor from '../../assets/admin/icons/rightCursor.png';
import { getProductsByFilter, deleteProducts } from '../../api/admin/product';
import { ChevronDownIcon } from '@heroicons/react/solid';

const ProductList = () => {
  const navigate = useNavigate();
  const [title, setSearchTerm] = useState('');
  const [category, setCategory] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [data, setData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [isItemLimitDropdownOpen, setIsItemLimitDropdownOpen] = useState(false);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    fetchProductData();
  }, [currentPage, itemsPerPage, category, title, startDate, endDate]);

  const fetchProductData = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await getProductsByFilter(
        currentPage,
        itemsPerPage,
        title,
        category,
        startDate,
        endDate
      );
      if (response && response.products && Array.isArray(response.products)) {
        setData(response.products);
        setTotalItems(response.totalItems);
        setTotalPages(itemsPerPage > 0 ? Math.max(1, Math.ceil(response.totalItems / itemsPerPage)) : 1);
      } else {
        setData([]);
        setTotalItems(0);
        setTotalPages(1);
      }
    } catch (error) {
      console.error('상품 데이터 가져오기 실패:', error);
      setError('데이터를 불러오는 데 실패했습니다.');
      setData([]);
      setTotalItems(0);
      setTotalPages(1);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
    setCurrentPage(1);
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    setCurrentPage(1);
  };

  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };

  const handleSelectRow = (id) => {
    setSelectedRows((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((rowId) => rowId !== id)
        : [...prevSelected, id]
    );
  };

  const handleSelectAll = () => {
    if (selectedRows.length === data.length) {
      setSelectedRows([]);
    } else {
      setSelectedRows(data.map(item => item.id));
    }
  };

  const handleRowClick = (id) => {
    navigate(`/product-editor/${id}`);
  };

  const handleItemLimitChange = (limit) => {
    setItemsPerPage(limit);
    setCurrentPage(1);
  };

  const fetchAllData = async () => {
    try {
      let allData = [];
      let page = 1;
      const limit = 100; // 한 번에 가져올 데이터 수
      let hasMore = true;

      while (hasMore) {
        const response = await getProductsByFilter(page, limit, title, category, startDate, endDate);
        if (response && response.products && Array.isArray(response.products)) {
          allData = [...allData, ...response.products];
          if (allData.length >= response.totalItems) {
            hasMore = false;
          }
        } else {
          hasMore = false;
        }
        page++;
      }

      return allData;
    } catch (error) {
      console.error('전체 상품 데이터 불러오기 실패:', error);
      return [];
    }
  };

  const handleExcelDownload = async () => {
    try {
      const allData = await fetchAllData();
      
      if (allData.length === 0) {
        alert('다운로드할 데이터가 없습니다.');
        return;
      }

      // 엑셀에 저장할 데이터 형식 정리
      const excelData = allData.map(item => ({
        '상품구분': item.category || '-',
        '상품명': item.name || '-',
        '정가': item.regularPrice || '-',
        '판매가': item.salePrice || '-',
        '수량': item.quantity || '-',
        '출력 여부': item.isDisplayed ? 'O' : 'X',
        '판매여부': item.isForSale ? 'O' : 'X',
        '최초 등록일': new Date(item.createdAt).toLocaleString('ko-KR'),
        '마지막 수정일': new Date(item.updatedAt).toLocaleString('ko-KR')
      }));

      const ws = XLSX.utils.json_to_sheet(excelData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "상품목록");
      XLSX.writeFile(wb, "상품목록.xlsx");
    } catch (error) {
      console.error('엑셀 다운로드 실패:', error);
      alert('엑셀 다운로드 중 오류가 발생했습니다.');
    }
  };

  // 스크립트 생성 핸들러
  const handleCreateScript = async () => {

    try {
      navigate(`/product-editor`);
    } catch (error) {
      console.error('Failed to create manager:', error);
    }
  };

  // 삭제 핸들러
  const handleDelete = async () => {
    try {
      if (selectedRows.length === 0) {
        alert('삭제할 항목을 선택해주세요.');
        return;
      }
      
      // 데이터 형식 수정
      const deleteData = { ids: selectedRows };
      
      await deleteProducts(deleteData.ids);
      fetchProductData();
      setSelectedRows([]);
      alert('선택한 항목이 성공적으로 삭제되었습니다.');
    } catch (error) {
      console.error('항목 삭제 중 오류 발생:', error);
      let errorMessage = '항목 삭제 중 오류가 발생했습니다.';
      if (error.response) {
        errorMessage += ` 상태 코드: ${error.response.status}`;
        if (error.response.data) {
          errorMessage += ` 서버 메시지: ${JSON.stringify(error.response.data)}`;
        }
      } else if (error.request) {
        errorMessage += ' 서버로부터 응답을 받지 못했습니다.';
      } else {
        errorMessage += ` 오류 메시지: ${error.message}`;
      }
      alert(errorMessage);
    }
  };

  const selectedDropdownStyle = {
    height: '36px',
    padding: '4px 14px',
    borderRadius: '12px',
    border: '1px solid #E2E8EF',
    fontFamily: 'Pretendard',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '14px',
    textAlign: 'left',
    color: '#000000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
  };

  return (
    <div className='min-h-screen' style={{ backgroundColor: '#FAF8F6' }}>
      <Navigation />
      <div style={{ padding: '26px 30px' }}>
        
        <h1 className="font-bold uppercase" style={{ width: '140px', height: '28px', fontFamily: 'Pretendard', fontSize: '24px', fontWeight: 700, textAlign: 'left' }}>
          상품목록
        </h1>

        {/* 검색 및 필터 영역 */}
        <div className="" style={{ margin: '26px 0px 20px 0' }}>
          <div className="flex items-center space-x-3">
            {/* 분류 필터 */}
            <select value={category} onChange={handleCategoryChange} className="w-[100px] h-[36px]" style={{ fontFamily: 'Pretendard', fontSize: '14px', fontWeight: 500, padding: '4px 14px', borderRadius: '12px', color: '#A9B6CA'}}>
              <option value="">분류</option>
              <option value="멤버십 상품">멤버십 상품</option>
              <option value="일반 상품">일반 상품</option>
            </select>

            {/* 검색어 입력 */}
            <div className="flex">
              <input
                type="text"
                placeholder="상품명 검색"
                value={title}
                onChange={handleSearch}
                className="h-[36px] w-[250px]"
                style={{ fontFamily: 'Pretendard', fontSize: '14px', fontWeight: 500, padding: '10px 14px', borderRadius: '12px', color: '#A9B6CA' }}
              />
            </div>

            {/* 기간 필터 */}
            <div className="relative flex items-center h-[36px] w-[250px] bg-white" style={{ padding: '0px 8px', borderRadius: '12px' }}>
              <DatePicker
                selected={startDate}
                onChange={(dates) => {
                  const [start, end] = dates;
                  setStartDate(start);
                  setEndDate(end);
                }}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                dateFormat="yyyy-MM-dd"
                placeholderText="기간 입력"
                className="w-full h-full"
                style={{ fontFamily: 'Pretendard', fontSize: '14px', fontWeight: 500, paddingLeft: '14px', border: 'none', color: '#A9B6CA' }}
              />
              <img src={calendarIcon} alt="Calendar Icon" className="absolute right-2 w-7 h-6" />
            </div>
          </div>
        </div>

        {/* 목록 테이블 섹션 */}
        <div className="bg-white rounded-lg shadow-md">
          {isLoading ? (
            <p>데이터를 불러오는 중...</p>
          ) : error ? (
            <p>{error}</p>
          ) : (
            <>
              <div className="flex justify-between items-center">
                <div className="text-lg mt-5 mb-7 ml-5">
                  <label className="text-xl font-bold text-[#0E111F] mr-5" style={{ fontFamily: 'Pretendard', fontSize: '18px', fontWeight: 700 }}>
                    목록
                  </label>
                  <label 
                    className="text-sm font-medium text-[#0E111F]"
                    style={{
                      fontFamily: 'Pretendard',
                      fontSize: '14px',
                      fontWeight: 500,
                      textAlign: 'left',
                    }}
                  >
                    검색결과 {totalItems}개 | 선택 {selectedRows.length}개 | 현재 페이지 {currentPage}
                  </label>
                </div>

                {/* 액션 버튼들 */}
                <div className="flex justify-end m-5">
                  <div className="relative mr-2.5">
                    <div
                      style={selectedDropdownStyle}
                      onClick={() => setIsItemLimitDropdownOpen(!isItemLimitDropdownOpen)}
                    >
                      <span>{`${itemsPerPage}개씩 보기`}</span>
                      <ChevronDownIcon className="h-5 w-5 text-gray-400" />
                    </div>
                    {isItemLimitDropdownOpen && (
                      <div className="absolute z-10 w-full mt-1 bg-white border border-gray-200 rounded-lg shadow-lg">
                        {[5, 10, 20].map((limit) => (
                          <div
                            key={limit}
                            className="p-2 hover:bg-gray-100 cursor-pointer"
                            onClick={() => {
                              handleItemLimitChange(limit);
                              setIsItemLimitDropdownOpen(false);
                            }}
                          >
                            {`${limit}개씩 보기`}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <button 
                    onClick={handleExcelDownload}
                    className="flex items-center justify-center mr-2.5"
                    style={{
                      width: '110px',
                      height: '36px',
                      padding: '4px 14px',
                      borderRadius: '12px',
                      backgroundColor: '#666B81',
                      color: '#FFFFFF',
                      fontFamily: 'Pretendard',
                      fontSize: '14px',
                      fontWeight: 700,
                    }}
                  >
                    엑셀 다운로드
                  </button>
                  <button 
                    onClick={handleDelete} 
                    className="p-2 rounded-md text-[#383C4B] mr-2.5"
                    style={{
                      width: '100px',
                      height: '36px',
                      borderRadius: '12px',
                      background: '#C5D0DD',
                      fontFamily: 'Pretendard',
                      fontSize: '14px',
                      fontWeight: 700,
                    }}
                  >
                    선택 삭제
                  </button>
                  <button 
                    onClick={handleCreateScript} 
                    className="p-2 rounded-md text-[#3677F6]"
                    style={{
                      width: '100px',
                      height: '36px',
                      borderRadius: '12px',
                      background: '#E3EDFF',
                      fontFamily: 'Pretendard',
                      fontSize: '14px',
                      fontWeight: 700,
                    }}
                  >
                    상품 추가
                  </button>
                </div>
              </div>

              <table className="min-w-full divide-y divide-gray-200 shadow-md">
                <thead className="bg-[#E2E8EF]" style={{ height: '40px', fontFamily: 'Pretendard', fontSize: '14px', fontWeight: 500, color: '#666B81' }}>
                  <tr>
                    <th className='text-center w-16'>
                      {/* 모든 항목 선택/해제 체크박스 */}
                      <input 
                        type="checkbox" 
                        onChange={handleSelectAll} 
                        checked={selectedRows.length === data.length} // 모든 항목이 선택되었는지 확인
                        className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                      />
                    </th>
                    <th className="text-center font-medium text-gray-500 tracking-wider w-16">No.</th>
                    <th className="text-center font-medium text-gray-500 tracking-wider cursor-pointer w-28">
                      상품구분<img src={handleSortIcon} alt="Sort Icon" className="inline-block ml-1" style={{ width: '22px', height: '22px' }} />
                    </th>
                    <th className="text-center font-medium text-gray-500 tracking-wider cursor-pointer w-40">
                      상품명<img src={handleSortIcon} alt="Sort Icon" className="inline-block ml-1" style={{ width: '22px', height: '22px' }} />
                    </th>
                    <th className="text-center font-medium text-gray-500 tracking-wider cursor-pointer w-24">
                      정가<img src={handleSortIcon} alt="Sort Icon" className="inline-block ml-1" style={{ width: '22px', height: '22px' }} />
                    </th>
                    <th className="text-center font-medium text-gray-500 tracking-wider cursor-pointer w-24">
                      판매가<img src={handleSortIcon} alt="Sort Icon" className="inline-block ml-1" style={{ width: '22px', height: '22px' }} />
                    </th>
                    <th className="text-center font-medium text-gray-500 tracking-wider cursor-pointer w-24">
                      수량<img src={handleSortIcon} alt="Sort Icon" className="inline-block ml-1" style={{ width: '22px', height: '22px' }} />
                    </th>
                    <th className="text-center font-medium text-gray-500 tracking-wider cursor-pointer w-24">
                      출력 여부<img src={handleSortIcon} alt="Sort Icon" className="inline-block ml-1" style={{ width: '22px', height: '22px' }} />
                    </th>
                    <th className="text-center font-medium text-gray-500 tracking-wider cursor-pointer w-24">
                      판매여부<img src={handleSortIcon} alt="Sort Icon" className="inline-block ml-1" style={{ width: '22px', height: '22px' }} />
                    </th>
                    <th className="text-center font-medium text-gray-500 tracking-wider cursor-pointer w-44">
                      최초 등록일<img src={handleSortIcon} alt="Sort Icon" className="inline-block ml-1" style={{ width: '22px', height: '22px' }} />
                    </th>
                    <th className="text-center font-medium text-gray-500 tracking-wider cursor-pointer w-44">
                      마지막 수정일<img src={handleSortIcon} alt="Sort Icon" className="inline-block ml-1" style={{ width: '22px', height: '22px' }} />
                    </th>
                  </tr>
                </thead>
                <tbody 
                  className="bg-white divide-y divide-gray-200"
                  style={{
                    fontFamily: 'Pretendard',
                    fontSize: '14px',
                    fontWeight: 500,
                    color: '#000000',
                  }}
                >
                  {data.length > 0 ? (
                    data.map((item, index) => (
                      <tr key={item.id} className='hover:bg-gray-100 transition duration-150 ease-in-out h-[40px]'>
                        <td className="whitespace-no-wrap text-center w-16">
                          {/* 각 항목 선택 체크박스 */}
                          <input
                            type="checkbox"
                            checked={selectedRows.includes(item.id)}
                            onChange={(e) => {
                              e.stopPropagation();
                              handleSelectRow(item.id);
                            }}
                            className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                          />
                        </td>
                        <td className="whitespace-no-wrap text-center" onClick={() => handleRowClick(item.id)}>{index + 1}</td>
                        <td className="whitespace-no-wrap text-center cursor-pointer" onClick={() => handleRowClick(item.id)}>{item.category}</td>
                        <td className="whitespace-no-wrap text-center cursor-pointer" onClick={() => handleRowClick(item.id)}>{item.name}</td>
                        <td className="whitespace-no-wrap text-center cursor-pointer" onClick={() => handleRowClick(item.id)}>{item.regularPrice}원</td>
                        <td className="whitespace-no-wrap text-center cursor-pointer" onClick={() => handleRowClick(item.id)}>{item.salePrice}원</td>
                        <td className="whitespace-no-wrap text-center cursor-pointer" onClick={() => handleRowClick(item.id)}>{item.quantity}</td>
                        <td className="whitespace-no-wrap text-center cursor-pointer" onClick={() => handleRowClick(item.id)}>{item.isDisplayed ? '예' : '아니오'}</td>
                        <td className="whitespace-no-wrap text-center cursor-pointer" onClick={() => handleRowClick(item.id)}>{item.isForSale ? '예' : '아니오'}</td>
                        <td className="whitespace-no-wrap text-center cursor-pointer" onClick={() => handleRowClick(item.id)}>{new Date(item.createdAt).toLocaleString()}</td>
                        <td className="whitespace-no-wrap text-center cursor-pointer" onClick={() => handleRowClick(item.id)}>{new Date(item.updatedAt).toLocaleString()}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="11" className="text-center py-4">
                        데이터가 없습니다.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </>
          )}
        </div>

        {/* 페이지네이션 컴포넌트 */}
        <div className="flex justify-center mt-4">
          <ReactPaginate
            previousLabel={
              <img src={leftCursor} alt="Left Cursor" className="w-4 h-4" />
            }
            nextLabel={
              <img src={rightCursor} alt="Right Cursor" className="w-4 h-4" />
            }
            breakLabel={'...'}
            pageCount={totalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={'pagination flex space-x-2 items-center'}
            activeClassName={'text-[#3677F6] font-bold'}
            breakClassName={'text-[#A9B6CA]'}
            disabledClassName={'text-[#C5D0DD] cursor-not-allowed'}
          />
        </div>
      </div>
    </div>
  );
};

export default ProductList;