import React, { createContext, useState, useEffect } from "react";
import en from "./en.json";
import ko from "./ko.json";

export const LanguageContext = createContext();

export const LanguageProvider = ({ children, userLanguage }) => {
  const [language, setLanguage] = useState("kor"); // Default language setting
  const [translations, setTranslations] = useState(ko); // Default system language

  useEffect(() => {
    // When `userLanguage` is passed in, update the language context accordingly
    if (userLanguage) {
      switchLanguage(userLanguage);
    }
  }, [userLanguage]);

  const switchLanguage = (lang) => {
    if (lang === "eng") {
      setLanguage("eng");
      sessionStorage.setItem("lang", "eng");
      setTranslations(en);
    } else if (lang === "kor") {
      setLanguage("kor");
      sessionStorage.setItem("lang", "kor");
      setTranslations(ko);
    }
    // Example: You can add more languages here
    // else if(lang === "jpn") {
    //   setLanguage("jpn");
    //   sessionStorage.setItem("lang", "jpn");
    //   setTranslations(jp);
    // }
  };

  return (
    <LanguageContext.Provider
      value={{ language, translations, switchLanguage }}
    >
      {children}
    </LanguageContext.Provider>
  );
};
