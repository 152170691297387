import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import FrameComponent3 from "../logincomponents/FrameComponent3";
import styles from "./PW1.module.css";
import Field from "../logincomponents/Field";
import Title from "../logincomponents/Title";
import PropTypes from "prop-types";
import BBtn from "../logincomponents/BBtn";
import ReactiveOuter from "../logincomponents/ReactiveOuter";
import FormTop from "../logincomponents/FormTop";
import FormForm from "../logincomponents/FormForm";

const PW1 = ({ className = "" }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { userId } = useParams();
  const [foundUserId, setFoundUserId] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  useEffect(() => {
    if (userId) {
      setFoundUserId(decodeURIComponent(userId));
      console.log("받은 userId:", foundUserId);
    } else {
      console.error("userId가 전달되지 않았습니다.");
    }
  }, [userId]);

  const handlePasswordChange = (value) => {
    const syntheticEvent = { target: { value } };
    console.log("Password input value: ", syntheticEvent.target.value);
    setPassword(syntheticEvent.target.value);
  };

  const handleConfirmPasswordChange = (value) => {
    const syntheticEvent = { target: { value } };
    setConfirmPassword(syntheticEvent.target.value);
  };

  const handleSubmit = () => {
    if (password === confirmPassword) {
      // 비밀번호 재설정 로직 구현
      console.log("비밀번호 재설정 완료:", foundUserId);
      navigate("/login1");
    } else {
      alert("비밀번호가 일치하지 않습니다.");
    }
  };

  return (
    <ReactiveOuter>
      <FormTop>
        <Title
          titleGap="78px"
          frameDivWidth="unset"
          prop="비밀번호 재설정"
          bAlignSelf="unset"
          bHeight="unset"
          bOpacity="unset"
          bMinWidth="101px"
          bDisplay="inline-block"
        />
        {/* <FrameComponent3 prop="비밀번호 재설정" prop1="1213213@fmail.com" /> */}
        <section className={styles.pwInner}>
          <div className={[styles.textFieldParent, className].join(" ")}>
            <Field
              fieldBorder="1px solid #e9ecef"
              fieldBackgroundColor="#E2E8EF"
              prop=""
              prop1="아이디"
              value={foundUserId}
              divColor="#a9b6ca"
              divMinWidth="unset"
              divDisplay="unset"
              readOnly={true}
              disabled={true}
            />
            <Field
              fieldBorder="1px solid #e9ecef"
              fieldBackgroundColor="unset"
              prop="영문, 숫자, 특수문자 조합 8자-15자 이내"
              prop1="비밀번호 재설정"
              divColor="#a9b6ca"
              divMinWidth="unset"
              divDisplay="unset"
              value={password}
              onChange={handlePasswordChange}
              type="password"
            />
            <Field
              fieldBorder="1px solid #e9ecef"
              fieldBackgroundColor="unset"
              prop="비밀번호를 한번 더 입력해주세요"
              prop1="비밀번호 확인"
              divColor="#a9b6ca"
              divMinWidth="unset"
              divDisplay="unset"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              type="password"
            />
          </div>
        </section>
      </FormTop>
      <div className="fixed bottom-[5vh] w-full">
        <BBtn prop={"비밀번호 재설정"} onClick={handleSubmit} />
      </div>
    </ReactiveOuter>
  );
};

PW1.propTypes = {
  className: PropTypes.string,
};

export default PW1;
