import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Quill from 'quill';
import 'quill/dist/quill.snow.css'; // Quill 기본 스타일
import Navigation from '../../components/admin/Navigation';
import { saveNotice, getNoticeById } from '../../api/admin/notice';

const NoticeEditor = ({ mode = 'create' }) => {
  const navigate = useNavigate();
  const { noticeId } = useParams();
  
  // 드롭다운 상태 관리
  const [publishStatus, setPublishStatus] = useState('출력');
  const [pinStatus, setPinStatus] = useState('고정');
  const [mainExpose, setMainExpose] = useState('노출');
  
  // Quill 에디터 내용 관리
  const quillRef = useRef(null);
  const [content, setContent] = useState('');
  const quillInstance = useRef(null); // Quill 인스턴스 관리

  // 제목 상태 관리
  const [title, setTitle] = useState('');

  useEffect(() => {
    if (!quillInstance.current) {
      quillInstance.current = new Quill(quillRef.current, {
        theme: 'snow',
        modules: {
          toolbar: [
            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            ['bold', 'italic', 'underline'],
            ['link', 'image'], // 이미지와 링크 추가
            ['clean'], // 포맷 초기화
          ],
        },
      });

      // Quill 에디터의 내용이 변경될 때마다 content 상태 업데이트
      quillInstance.current.on('text-change', () => {
        setContent(quillInstance.current.root.innerHTML);
      });

      // 이미지 업로드 핸들러
      quillInstance.current.getModule('toolbar').addHandler('image', imageHandler);
    }
  }, []);

  useEffect(() => {
    const loadNoticeData = async () => {
      try {
        if (mode === 'edit' && noticeId) {
          const notice = await getNoticeById(noticeId);
          setTitle(notice.title);
          setPublishStatus(notice.output ? '출력' : '미출력');
          setPinStatus(notice.isPinned ? '고정' : '비고정');
          setMainExpose(notice.mainUsed ? '노출' : '미노출');
          setContent(notice.content);
          quillInstance.current.root.innerHTML = notice.content;
        }
      } catch (error) {
        console.error('공지사항 데이터 로딩 실패:', error);
        alert('공지사항 데이터를 불러오는 데 실패했습니다.');
      }
    };

    loadNoticeData();
  }, [mode, noticeId]);

  // 이미지 업로드 핸들러 함수
  const imageHandler = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        const range = quillInstance.current.getSelection();
        quillInstance.current.insertEmbed(range.index, 'image', reader.result); // Base64 이미지 삽입
      };
      reader.readAsDataURL(file);
    };
  };

  const handleSave = async () => {
    try {
      const noticeData = {
        title,
        content,
        authorId: 1, // 작성자 ID는 실제 데이터로 대체해야 함
        isPinned: pinStatus === '고정',
        output: publishStatus === '출력',
        mainUsed: mainExpose === '노출',
        images: [], // 이미지 업로드 기능 구현 필요
        files: [] // 파일 업로드 기능 구현 필요
      };

      await saveNotice(noticeData, mode, noticeId);
      alert(`공지사항이 성공적으로 ${mode === 'create' ? '추가' : '수정'}되었습니다.`);
      navigate('/notice');
    } catch (error) {
      console.error(`공지사항 ${mode === 'create' ? '추가' : '수정'} 중 오류 발생:`, error);
      alert(`공지사항 ${mode === 'create' ? '추가' : '수정'} 중 오류가 발생했습니다.`);
    }
  };

  return (
    <div className="min-h-screen bg-[#FAF8F6] p-6">
      <Navigation />
      {/* 공지사항 상세 상단 */}
      <div className="flex justify-between items-center mb-5">
        <h1 className="text-2xl font-bold text-left">{mode === 'create' ? '공지사항 추가' : '공지사항 수정'}</h1>
        <div className="flex space-x-4">
          <button
            onClick={() => navigate('/notice')}
            className="w-[100px] h-[48px] bg-gray-400 text-white font-bold px-4 py-3"
            style={{borderRadius: '12px'}}
          >
            목록가기
          </button>
          <button 
            onClick={handleSave}
            className="w-[100px] h-[48px] bg-blue-500 text-white font-bold px-4 py-3"
            style={{borderRadius: '12px'}}
          >
            저장하기
          </button>
        </div>
      </div>

      {/* 상태값 섹션 */}
      <div className="bg-white rounded-lg p-6 shadow-md mb-10">
        <h2 className="text-xl font-bold mb-4">상태값</h2>
        <div className="grid grid-cols-3 gap-8">
          {/* 출력 여부 */}
          <div>
            <label className="block text-sm font-bold mb-2">출력여부</label>
            <select
              value={publishStatus}
              onChange={(e) => setPublishStatus(e.target.value)}
              className="w-full h-10 border border-gray-300 rounded-lg px-3"
            >
              <option value="출력">출력</option>
              <option value="미출력">미출력</option>
            </select>
          </div>

          {/* 상단 고정 여부 */}
          <div>
            <label className="block text-sm font-bold mb-2">상단 고정 여부</label>
            <select
              value={pinStatus}
              onChange={(e) => setPinStatus(e.target.value)}
              className="w-full h-10 border border-gray-300 rounded-lg px-3"
            >
              <option value="고정">고정</option>
              <option value="비고정">비고정</option>
            </select>
          </div>

          {/* 메인 노출 여부 */}
          <div>
            <label className="block text-sm font-bold mb-2">메인 노출 여부</label>
            <select
              value={mainExpose}
              onChange={(e) => setMainExpose(e.target.value)}
              className="w-full h-10 border border-gray-300 rounded-lg px-3"
            >
              <option value="노출">노출</option>
              <option value="미노출">미노출</option>
            </select>
          </div>
        </div>
      </div>

      {/* 내용 섹션 */}
      <div className="bg-white rounded-lg p-6 shadow-md mb-10">
        <h2 className="text-xl font-bold mb-4">내용</h2>

        {/* 제목 */}
        <div className="mb-6">
          <label className="block text-sm font-bold mb-2">제목</label>
          <input
            type="text"
            placeholder="제목을 입력하세요"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="w-full h-10 border border-gray-300 rounded-lg px-3"
          />
        </div>

        {/* 내용 입력 */}
        <div>
          <label className="block text-sm font-bold mb-2">내용</label>
          <div className="h-[400px] pb-12">
            <div ref={quillRef}></div> {/* Quill 에디터가 삽입될 DOM 요소 */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoticeEditor;